<template>
  <div>
    <div class="featuredNfts">
      <div class="heading">
        <h4>Auction NFTs</h4>
        <b-button
          @click="
            $router.push({
              name: 'Marketplace',
              params: {
                category: 'auctions',
              },
            })
          "
          >View All <i class="fas fa-arrow-right ml-1"></i
        ></b-button>
      </div>
      <div class="notableNfts mt-2">
        <b-list-group>
          <b-list-group-item
            style="width: 100%; height: auto; box-shadow: none; border: none"
            v-if="featuredNfts.length == 0 && !noNftsFound"
            class="loader"
          >
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
          </b-list-group-item>
          <b-list-group-item
            v-else-if="featuredNfts.length == 0 && noNftsFound"
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="noNft">No Data Found!</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(art, artIndex) in featuredNfts"
            :key="artIndex"
            v-else
          >
            <div data-aos="zoom-in" data-aos-duration="2000">
              <img :src="art.image" alt="" style="position: relative" />

              <span
                style="
                  position: absolute;
                  left: 10px;
                  top: 10px;
                  background-color: #d8b871;
                  color: #ffffff;
                  padding: 2px;
                  border-radius: 50%;
                  fontfamily: Bold;
                  fontsize: 14px;
                  width: 25px;
                  height: 25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                v-if="
                  account != null &&
                  account.toLowerCase() != art.address.toLowerCase() &&
                  $store.getters.getAsset.assetLinks[art.ipfs_link]
                "
                >{{
                  $store.getters.getAsset.assetLinks[art.ipfs_link].count
                }}</span
              >

              <div class="info">
                <div class="d-flex justify-content-between">
                  <span>{{ art.name }}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span
                    >Owned By:
                    <span class="font-weight-bolder">{{
                      art.username
                    }}</span></span
                  >
                  <span
                    >{{ art.price }}
                    <span>{{ checkCurrency(art.currency) }}</span></span
                  >
                </div>
                <b-button
                  v-if="
                    account != null &&
                    account.toLowerCase() != art.address.toLowerCase() &&
                    !$store.getters.getAsset.assetLinks[art.ipfs_link]
                  "
                  block
                  @click="goToNftPage(art.id, art.auction)"
                  >{{ art.auction ? "Place Bid" : "Buy Asset" }}</b-button
                >
                <b-button
                  v-else-if="
                    account != null &&
                    account.toLowerCase() != art.address.toLowerCase() &&
                    $store.getters.getAsset.assetLinks[art.ipfs_link]
                  "
                  block
                  @click="getNftCopies(art)"
                >
                  Buy Asset
                </b-button>
                <b-button v-else-if="account == null" block disabled
                  >Connect Wallet</b-button
                >
                <b-button v-else disabled block>Already Owned</b-button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
export default {
  props: ["nfts", "account", "nftsObject"],
  async created() {
    await this.getAuctionNfts();
  },
  data() {
    return {
      featuredNfts: [],
      auctionNftsObeject: {},
      noNftsFound: false,
    };
  },
  methods: {
    async getAuctionNfts() {
      web3;
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-auctions`;
      await this.$axios.get(url).then((res) => {
        const nftsData = res.data;

        nftsData.forEach((nft) => {
          this.$set(this.auctionNftsObeject, nft.ipfs_link, {
            id: nft.id,
            name: nft.name,
            description: nft.description,
            ipfs_link: nft.ipfs_link,
            address: nft.address,
            price: (nft.price / 10 ** 18).toString(),
            created_at: nft.created_at,
            image: nft.image,
            auction: nft.auction == 1 ? true : false,
            selling: nft.selling == 1 ? true : false,
            end_time: nft.end_time,
            bidder: nft.bidder,
            creator: nft.creator,
            currency: nft.currency,
            photo: nft.photo,
            username: nft.username,
            featured: nft.featured == 1 ? true : false,
          });
        });

        for (let featured in this.auctionNftsObeject) {
          if (this.featuredNfts.length < 4) {
            this.featuredNfts.push(this.auctionNftsObeject[featured]);
          }
        }

        if (this.featuredNfts.length == 0) {
          this.noNftsFound = true;
        } else {
          this.noNftsFound = false;
        }
      });
    },
    goToNftPage(nftId, auction) {
      if (!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({ name: "Profile" });
      }
      this.$router.push({
        name: auction ? "AuctionNft" : "BuyNft",
        params: {
          nftId: btoa(nftId),
        },
      });
    },
    getNftCopies(asset) {
      if (!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({ name: "Profile" });
      }
      this.$router.push({
        name: "NftCopies",
        params: {
          nftName: asset.name,
          nftId: btoa(asset.ipfs_link),
        },
      });
    },
  },
};
</script>
