<template>
  <div>
    <div class="profileWithAssets">
      <div class="profileWithAssetsBg d-flex align-items-center">
        <b-container>
          <b-row >
            <b-col md="12">
              <h4 class="text-center">Profile</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="userDetails mt-3 p-2 d-flex justify-content-center" v-if="!user.exist">
       <CollectUserDetails :user="user" />
      </div>
      <div class="profile mt-3" v-else>
        <b-row>
          <b-col md="3">
            <ProfileBar />
          </b-col>
          <b-col md="9">
            <div
              class="profileOptions"
            >
              <b-nav tabs fill small>
                <b-nav-item
                  :active="activeOption == optionIndex ? true : false"
                  v-for="(option, optionIndex) in profileOptions"
                  :key="optionIndex"
                  @click="getProfileOption(option.component, optionIndex)"
                >
                  {{ option.name }}
                </b-nav-item>
              </b-nav>

              <div class="option">
                <component v-bind:is="currentComponent"></component>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBar from "./ProfileBar.vue";
import All from "./Category/All.vue";
// import Collection from "./Category/Collection.vue";
// import Nfts from "./Category/Nfts.vue";
import CollectUserDetails from "./CollectUserDetails.vue";
export default {
  async created() {
    this.user = this.$store.getters.getMetamaskAccount.user;
  },
  components: {
    ProfileBar,
    All,
    // Collection,
    // Nfts,
    CollectUserDetails
  },
  data() {
    return {
      user: {},
      activeOption: 0,
      currentComponent: "All",
      profileOptions: [
        {
          name: "NFTs",
          component: "All",
        },
        // {
        //   name: "Collection",
        //   component: "Collection",
        // },
        // {
        //   name: "NFTs",
        //   component: "Nfts",
        // },
      ],
    };
  },
  methods: {
    getProfileOption(component, optionIndex) {
      this.activeOption = optionIndex;
      this.currentComponent = component;
    },
  },
};
</script>

<style lang="scss">
.profileWithAssets {
  min-height: 100vh;
}
.profileWithAssets .profile {
  padding: 0 80px 0 80px;
}
.profileWithAssets .profileWithAssetsBg {
  background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 200px;
  padding: 10px;
  background-position-y: center;
}

.profileWithAssets .profileWithAssetsBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.profileWithAssets .profile .profileOptions .nav-tabs .nav-item .nav-link {
  font-size: 20px;
  font-family: "Bold";
}

.profileWithAssets .profile .profileOptions .nav-tabs .nav-item a.active {
  color: #d8b871;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
.profileWithAssets .profile {
  padding: 0px 10px 10px 10px;
}
.profileWithAssets .profileWithAssetsBg {
  height: auto;
  background-image: none;
  padding: 0px;
}

.profileWithAssets .profileWithAssetsBg h4 {
  font-size: 20px;
  color: #1D2034;

}
.profileWithAssets .profile .profileOptions {
    margin-top: 30px;
}
.profileWithAssets .profile .profileOptions .nav-tabs .nav-item .nav-link {
  font-size: 18px;

}

.profileWithAssets .profile .profileOptions .nav-tabs .nav-item a.active {
  color: #d8b871;
}
}
</style>
