<template>
  <div>
    <div class="buyOrBidNft">
      <div
        class="buyOrBidNftBg d-flex align-items-center justify-content-center"
      >
        <b-container>
          <b-row>
            <b-col md="12">
              <h4 class="text-center">Item Details</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="nft">
        <b-modal
          id="updatePrice"
          v-if="asset.id"
          hide-footer
          centered
          size="md"
          modal-class="updatePrice"
          hide-header
        >
          <b-form
            @submit.prevent="
              Number(updateNftPrice) > Number(0)
                ? updateAssetPrice()
                : $toast.error('Please enter a valid price')
            "
          >
            <b-input-group>
              <template #append>
                <b-input-group-text>
                  <b-button
                    v-if="Number(updateNftPrice) > Number(0)"
                    class="placeBidPrice"
                    type="submit"
                    >Update Price</b-button
                  >
                  <b-button
                    v-else
                    :variant="
                      Number(updateNftPrice) > Number(0) ? 'success' : 'danger'
                    "
                    type="submit"
                    >{{
                      Number(updateNftPrice) > Number(0)
                        ? "Valid Price"
                        : "Invalid Price"
                    }}</b-button
                  >
                </b-input-group-text>
              </template>
              <b-form-input v-model="updateNftPrice"></b-form-input>
            </b-input-group>
          </b-form>


        </b-modal>
        <b-container fluid>
          <b-row>
            <b-col md="4" class="min-vh-50 d-flex flex-column">
              <b-skeleton-img v-if="fetchData"></b-skeleton-img>
              <img :src="asset.image" alt="" v-else />
              <div class="description mt-3 mb-2">
                <div class="heading">Description</div>
                <div v-if="fetchData">
                  <b-skeleton animation="fade" width="70%"></b-skeleton>
                  <b-skeleton animation="fade" width="50%"></b-skeleton>
                  <b-skeleton animation="fade" width="40%"></b-skeleton>
                  <b-skeleton animation="fade" width="30%"></b-skeleton>
                </div>
                <div v-else class="mt-2">
                  <p v-if="this.asset.description.length > 45">
                    {{ handleDescription }}...
                    <span
                      class="text-info btn p-0 m-0"
                      @click="longText = !longText"
                      >{{ longText ? "Read More" : "Read Less" }}</span
                    >
                  </p>
                  <p v-else>{{ asset.description }}</p>
                </div>
              </div>
            </b-col>
            <b-col md="8" class="min-vh-50 d-flex flex-column">
              <div class="ownerName">
                <div class="d-flex align-items-center">
                  <b-skeleton-icon
                    icon="person"
                    :icon-props="{ fontScale: 3 }"
                    v-if="fetchData"
                  ></b-skeleton-icon>
                  <img
                    :src="asset.photo"
                    style="width: 87px; height: 87px"
                    class="rounded-circle"
                    v-else
                    alt=""
                  />
                  <div v-if="fetchData" class="ml-2 w-100">
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                  </div>
                  <div class="ml-2" v-else>
                    <span class="name">{{ asset.username }}</span> <br />
                    <span class="type">Item Owner</span>
                  </div>
                </div>
                <div></div>
              </div>

              <div class="assetPrice mt-4">
                <div v-if="fetchData">
                  <b-skeleton animation="fade" type="button"></b-skeleton>
                </div>
                <div v-else>
                  <div class="price">Price</div>
                  <div class="priceVal">
                    <span
                      >{{ convertToNormalPrice(asset) }}
                      <span>{{ checkCurrency(asset.currency) }}</span></span
                    >
                  </div>
                </div>
                <div class="placeOrBid mt-2">
                  <b-skeleton
                    v-if="fetchData"
                    type="button"
                    animation="fade"
                    width="70%"
                  ></b-skeleton>
                  <div v-else class="d-flex justify-content-between flex-wrap">
                    <b-button
                      class="mb-1"
                      @click="withdrawNft"
                      :disabled="price.disabled || price.disabled"
                      >{{ withdraw.text }}</b-button
                    >
                    <b-button
                      :disabled="price.disabled || withdraw.disabled"
                      class="mb-1"
                      @click="$bvModal.show('updatePrice')"
                      >{{ price.text }}</b-button
                    >
                  </div>
                </div>
              </div>

              <div class="bidsHistory mt-5">
                <div ref="bidHistory">
                  <b-nav align="center">
                    <b-nav-item
                      @click="getHistory(history.component, historyIndex)"
                      :active="activeHistory == historyIndex ? true : false"
                      v-for="(history, historyIndex) in history"
                      :key="historyIndex"
                    >
                      {{ history.name }}
                    </b-nav-item>
                  </b-nav>

                  <div v-if="nftBids == null">
                    <div class="userBid d-flex w-100 mb-2">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                    <div class="userBid d-flex w-100 mb-2">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                    <div class="userBid d-flex w-100">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2" v-else>
                    <component
                      :creator="asset.creator"
                      :asset="asset"
                      :nftBids="nftBids"
                      v-bind:is="currentComponent"
                    ></component>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
web3;

import History from "../../BuyNft/History/History.vue";
import Details from "../../BuyNft/History/Details.vue";
export default {
  async created() {
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;

    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    this.fetchData = true;
    await this.getNftInfo();
    this.updateNftPrice = await this.convertToNormalPrice(this.asset);

    setTimeout(() => {
      this.fetchData = false;
    }, 3000);
  },
  data() {
    return {
      contract: null,

      account: null,
      asset: {},
      fetchData: false,
      activeHistory: 0,
      currentComponent: "History",
      nftBids: null,
      history: [
        { name: "History", component: "History" },
        { name: "Details", component: "Details" },
      ],
      price: {
        text: "Update Price",
        disabled: false,
      },
      withdraw: {
        text: "Withdraw",
        disabled: false,
      },
      updateNftPrice: "",
    };
  },
  components: {
    // Bids,
    History,
    Details,
  },
  methods: {
    async updateAssetPrice() {
      this.$bvModal.hide("updatePrice");
      if (
        Number(this.updateNftPrice) ==
        Number(this.convertToNormalPrice(this.asset))
      ) {
        return;
      }
      this.price = {
        text: "Processing....",
        disabled: true,
      };

      let uPrice = await web3.utils.toWei(
        this.updateNftPrice.toString(),
        "Ether"
      );

      const updatePrice = await this.contract.methods
        .updateSellingPrice(this.asset.id.toString(), uPrice.toString())
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });

      if (updatePrice) {
        this.price.text = "Updating Database....";
        await this.syncMetamaskWithApi();
        this.$toast.success(
          `${this.asset.name} price is successfully changed`,
          {
            position: "top",
            duration: 3000,
          }
        );
        setTimeout(() => {
          this.getNftInfo();
        }, 3000);
      }
      if (!updatePrice) {
        this.price.text = "Retry";
        this.price.disabled = false;
        return this.$toast.error(`Unable to update price, please try again`, {
          position: "top",
          duration: 3000,
        });
      }

      this.price = {
        text: "Transaction Successfull",
        disabled: true,
      };
    },
    async withdrawNft() {
      this.withdraw = {
        text: "Processing....",
        disabled: true,
      };

      const updatePrice = await this.contract.methods
        .withdrawSellOrder(this.asset.id.toString())
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });

      if (updatePrice) {
        this.withdraw.text = "Updating Database....";
        await this.syncMetamaskWithApi();
        this.$toast.success(`${this.asset.name} is removed from listing`, {
          position: "top",
          duration: 3000,
        });
        setTimeout(() => {
          this.$router.push({ name: "Profile" });
        }, 3000);
      }
      if (!updatePrice) {
        this.withdraw.text = "Retry";
        this.withdraw.disabled = false;
        return this.$toast.error(
          `Unable to remove from listing, please try again`,
          {
            position: "top",
            duration: 3000,
          }
        );
      }

      this.withdraw = {
        text: "Transaction Successfull",
        disabled: true,
      };
    },
    async buyNft() {
      this.buy = "Please approve metamask";
      // let price = await web3.utils.toWei(this.asset.price.toString(), "Ether");
      let buyStatus = await this.contract.methods
        .buyToken(this.asset.id, this.asset.price)
        .send({ from: this.account, value: this.asset.price })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });

      return buyStatus;
    },
    async getNftInfo() {
      this.fetchData = true;
      const nftId = atob(this.$route.params.nftId);
      await this.$axios
        .get(
          `https://us-central1-everestcoin.cloudfunctions.net/get-nft-by-id?id=${nftId}`
        )
        .then((res) => {
          this.asset = res.data.result[0];
          this.nftBids = res.data.bids;
          res;
        })
        .catch((err) => {
          err;
        });
      this.fetchData = false;
    },
    convertToNormalPrice(asset) {
   if (Number(asset.price) == 0) {
        return 0;
      } else {
        let UpdatedPrice =
          asset.auction == 0
            ? web3.utils.fromWei(asset.price.toString(), "Ether")
            : (asset.price / 10 ** 18).toString();

        return UpdatedPrice;
      }
    },
    getHistory(component, componentIndex) {
      this.activeHistory = componentIndex;
      this.currentComponent = component;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .updatePrice .modal-content {
  background-color: #1d2034;
  border-radius: 30px;
}

::v-deep .updatePrice .modal-body .input-group {
  border-radius: 20px;
  height: 60px;
  background-color: #1d2034;
  border: 3px solid white !important;
  overflow: hidden;
  border: none;
}
::v-deep .updatePrice .modal-body .input-group .input-group-text {
  height: 60px;
  background-color: transparent;
  // border-left-style: none;
  overflow: hidden;
  background-color: #d8b871;
}
::v-deep .updatePrice .modal-body .input-group .input-group-text button {
  font-size: 16px;
  font-family: "Bold";
  border: none;
  overflow: hidden;
  height: 60px;
  color: #ff4444;
  background-color: #d8b871;
}
::v-deep
  .updatePrice
  .modal-body
  .input-group
  .input-group-text
  .placeBidPrice {
  color: #ffffff;
}
::v-deep .updatePrice .modal-body input {
  background-color: #1d2034;
  height: 60px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: "SemiBold";
}

:v-deep .updatePrice .modal-body .input-group input::placeholder {
  color: #ffffff;
  font-size: 16px;
  font-family: "SemiBold";
}

.buyOrBidNft .nft {
  padding: 0 80px 0 80px;
  margin-top: 50px;
}
.buyOrBidNft .buyOrBidNftBg {
  background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 200px;
  padding: 10px;
  background-position-y: center;
}

.buyOrBidNft .buyOrBidNftBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.buyOrBidNft .buyOrBidNftBg .nft .container .row .col-md-8 {
  height: 100vh !important;
  display: flex !important;
}

.buyOrBidNft .nft .col-md-4 img,
.buyOrBidNft .nft .col-md-4 .b-skeleton {
  max-width: 400px;
  max-height: 400px;
  border-radius: 20px;
  border: 3px solid #d8b871;
}
.buyOrBidNft .nft .col-md-8 .ownerName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.buyOrBidNft .nft .col-md-8 .ownerName .name,
.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  font-size: 20px;
  font-family: "SemiBold";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .ownerName .type {
  font-size: 18px;
  font-family: "Regular";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 7px;
  border-radius: 20px;
  background-color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .assetPrice .price {
  font-size: 20px;
  font-family: "Regular";
}

.buyOrBidNft .nft .col-md-8 .assetPrice .priceVal {
  font-size: 28px;
  font-family: "SemiBold";
}

.buyOrBidNft .nft .col-md-8 .likesViews button {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  border: none;
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .likesViews button i {
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-4 .description .heading {
  font-size: 20px;
  font-family: "Bold";
  padding: 0px;
  margin: 0px;
}

.buyOrBidNft .nft .col-md-4 .description p {
  font-size: 18px;
  font-family: "Regular";
  padding: 0px;
  margin: 0px;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
  background-color: #f2f2f2;
  color: #1d2034;
  font-size: 20px;
  font-family: "SemiBold";
  // border-radius: 20px;
  // min-width: 50%;
  max-width: 50%;
  border-radius: 5px;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a {
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a.active {
  color: #d8b871;
}

.buyOrBidNft .nft .col-md-8 .placeOrBid button {
  background-color: #d8b871;
  color: #1d2034;
  min-width: 220px;
  height: 50px;
  font-size: 22px;
  font-family: "Bold";
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .buyOrBidNft .nft {
    padding: 0 0px 0px 0px;
    margin-top: 0px;
    padding: 10px;
  }
  .buyOrBidNft .buyOrBidNftBg {
    background-image: none;
    height: auto;
  }

  .buyOrBidNft .buyOrBidNftBg h4 {
    font-size: 20px;
    color: #1d2034;
  }

  .buyOrBidNft .nft .col-md-4 img,
  .buyOrBidNft .nft .col-md-4 .b-skeleton {
    max-width: 100%;
    max-height: 200px;
  }

  .buyOrBidNft .nft .col-md-8 .ownerName {
    margin-top: 20px;

    align-items: start;
  }

  .buyOrBidNft .nft .col-md-8 .ownerName .name,
  .buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
    font-size: 18px;
    margin-top: 10px;
  }

  .buyOrBidNft .nft .col-md-8 .description .heading {
    font-size: 20px;
  }

  .buyOrBidNft .nft .col-md-8 .description p {
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
    max-width: 100%;
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .placeOrBid button {
    max-width: 100%;
    height: 50px;
    font-size: 20px;
  }
}
</style>
