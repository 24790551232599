<template>
  <div>
    <div class="nftCreation">
      <div class="nftCreationBg d-flex align-items-center">
        <b-container>
          <b-row no-gutters>
            <b-col md="12">
              <h4 class="text-center">Create</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="nft mt-3">
        <b-container fluid>
          <b-row>
            <b-col md="4">
              <ProfileBar />
            </b-col>
            <b-col md="8" class="min-vh-100">
              <div class="nftType" v-if="newNft == null" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-out-cubic">
                <b-button @click="newNft = true">Mint New NFT</b-button>
                <b-button @click="newNft = false">Mint From Existing NFT</b-button>
              </div>
              <div v-else>
                  <div v-if="newNft" data-aos="fade-up" data-aos-duration="2000">
                      <NftForm @nftType="newNft = null" />
                  </div>
                  <div v-else>
                      <SelectExistingNft @nftType="newNft = null" />
                  </div>

              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBar from "../Profile/ProfileBar.vue";
import NftForm from "./NftForm.vue";
import SelectExistingNft from "./SelectExistingNft.vue";
export default {
  components: {
    ProfileBar,
    NftForm,
    SelectExistingNft
  },
  data() {
    return {
        newNft: null
    };
  },
};
</script>

<style lang="scss">
.nftCreation .nft {
  padding: 0 80px 0 80px;
}
.nftCreation .nftCreationBg {
  background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 200px;
  padding: 10px;
  background-position-y: center;
}

.nftCreation .nftCreationBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.nftCreation .createAsset {
  font-size: 18px;
  font-family: "Bold";
  display: none;
}

.nftCreation .nft .col-md-8 .form-heading {
  font-size: 30px;
  font-family: "Bold";
}

.nftCreation .nft .col-md-8 form {
  background-color: #faf9f9;
  border-radius: 20px;
  padding: 20px;
}
.nftCreation .nft .col-md-8 form textarea {
  height: 100px;
  border-radius: 10px;
}
.nftCreation .nft .col-md-8 form input,
.nftCreation .nft .col-md-8 form .custom-file,
.nftCreation .nft .col-md-8 form select {
  width: 100%;
  height: 60px;
  border-radius: 10px;
}

.nftCreation .nft .col-md-8 form button:not(:nth-child(2)) {
  border-radius: 20px;
  color: #0c0e1a;
  font-size: 20px;
  background-color: #d8b871;
  font-family: "Bold";
  border: none;
}

.nftCreation .nft .col-md-8 form button:nth-child(2) {
  font-size: 14px;
  font-family: "SemiBold";
  margin-left: 10px;
}

.nftCreation .nftType {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // min-height: 50vh;
}
.nftCreation .nftType button
 {
   min-width: 350px;
  max-width: 350px;
  color: #1D2034;
  font-size: 20px;
  font-family: "Bold";
  background-color: #d8b871;
  // border-radius: 10px;
  margin-bottom: 20px;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .nftCreation .nft {
    padding: 0 10px 0 10px;
  }
  .nftCreation .nftCreationBg {
    display: none !important;
    height: 200px;
  }

  .nftCreation .createAsset {
    display: block;
  }

  .nftCreation .nftCreationBg h4 {
    font-size: 18px;
  }

  .nftCreation .nft .col-md-8 .form-heading {
    font-size: 18px;
    margin-top: 30px;
  }

  .nftCreation .nft .col-md-8 form {
    background-color: #faf9f9;
    border-radius: 20px;
    padding: 10px;
  }
  .nftCreation .nft .col-md-8 form textarea {
    height: 100px;
    border-radius: 10px;
  }
  .nftCreation .nft .col-md-8 form input,
  .nftCreation .nft .col-md-8 form .custom-file {
    height: 40px;
  }

  .nftCreation .nft .col-md-8 form button {
    border-radius: 18px;
  }
}
</style>
