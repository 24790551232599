<template>
  <div>
    <div class="topCollections mt-3 mb-2">
      <div class="heading mb-2">
        <h4>Top Collections over last <span class="days">7 days</span></h4>
        <b-button>See All <i class="fas fa-arrow-right ml-1"></i></b-button>
      </div>

      <div class="collections">
        <b-list-group>
              <b-list-group-item
            v-if="collections.length == 0 && !noCollections"
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="loader">

              <div class="collectionLoader">
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
              </div>

              <div class="collectionLoader">
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
              </div>
              <div class="collectionLoader">
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
                  <b-skeleton-img></b-skeleton-img>
              </div>



            </div>

          </b-list-group-item>
          <b-list-group-item v-else-if="collections.length == 0 && noCollections"
          style="width: 100%; height: auto; box-shadow: none; border: none">
              <div class="noCollection">
              No Data Found!
            </div>
          </b-list-group-item>

          <b-list-group-item
            v-for="(collection, collectionIndex) in collections"
            :key="collectionIndex"
            v-else
          >
            <div class="collection">
              <img :src="collection.img1" alt="" />
              <img :src="collection.img2" alt="" />
              <img :src="collection.img2" alt="" />
              <img :src="collection.img2" alt="" />
            </div>
            <div class="info  p-2">
                <div class="nameLikes d-flex justify-content-between">
                    <span class="name">
                        <span class="font-weight-bolder">Landscapes</span> - {{collection.items}}
                    </span>
                     <span>
                  <i class="far fa-heart mr-1" v-if="!collection.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i>
                  {{ collection.likes }}K
                </span>
                </div>
                <div class="createdBy">
                    <span>Created By: <img :src="collection.ownerImg" alt=""> <span class="font-weight-bolder">{{collection.owner}}</span> </span>
                </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      collections: this.$sampleData.topCollections,
      noCollections: false

    };
  },
};
</script>

<style lang="scss">
.topCollections {
  padding: 0 58px 0 58px !important;
}

.topCollections .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.topCollections .heading .days {
  color: #d8b871;
}
.topCollections .heading h4 {
  font-size: 30px;
  font-family: "Bold";
}

.topCollections .heading button {
  font-size: 18px;
  font-family: "Regular";
  border: 2px solid #1d2034;
  background-color: transparent;
  color: #1d2034;
  border-radius: 20px;
}

.topCollections .list-group-item .noCollection {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.topCollections .list-group {
    flex-direction: row;
    flex-wrap: wrap;
}

.topCollections .list-group-item {
  width: 400px;
  overflow: hidden;
  padding: 0px;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 20px 0 20px 20px !important;
  cursor: pointer;
}

.topCollections .list-group-item .collection {
  width: 400px;
  height: 418px;
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
  overflow: hidden;
}

.topCollections .list-group-item .collection img:first-child {
  width: 366px;
  height: 241px;
  border-radius: 20px;
}

.topCollections .list-group-item .collection img {
  width: 114px;
  height: 109px;
  border-radius: 20px;
}

.topCollections .list-group-item .collection img:not(:first-child) {
    margin-left: 10px;
}

.topCollections .list-group-item .info .createdBy img {
    width: 39px;
    height: 39px;
    border-radius: 30px;
}

.topCollections .list-group-item .loader  {
  display: flex;
  flex-wrap: wrap;
}

.topCollections .list-group-item .loader  .collectionLoader{
  width: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
//   overflow: hidden;
   border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  margin: 20px 0 20px 20px;
}

.topCollections .list-group-item .b-aspect {
  margin-bottom: 20px;
}
.topCollections .list-group-item .b-skeleton-img {
  border-radius: 20px !important;
}

.topCollections .list-group-item .loader .collectionLoader .b-aspect:first-child .b-skeleton-img {
  width: 366px;
  height: 241px;
  border-radius: 20px;
}

.topCollections .list-group-item .loader .collectionLoader .b-skeleton-img {
  width: 114px;
  height: 109px;
  border-radius: 20px;
}

.topCollections .list-group-item .loader .collectionLoader .b-aspect:not(:first-child) {
    margin-left: 10px !important;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
    .topCollections {
  padding: 0 0px 0 0px !important;
}

.topCollections .heading {

  flex-direction: column;
  justify-content: none;
  align-items: none;
}

.topCollections .heading h4 {
  font-size: 20px;

}

.topCollections .heading button {
  font-size: 20px;

}

// .topCollections .list-group {
//   justify-content: center !important;
// }

.topCollections .collections {
  padding: 10px;
}

.topCollections .list-group-item {
  width: 100%;
  overflow: hidden;

}

.topCollections .list-group-item .collection {
  width: 100%;

}

.topCollections .list-group-item .collection img:first-child {
  width: 100%;

}

.topCollections .list-group-item .collection img {
  width: 80px;

}

.topCollections .list-group-item .collection img:not(:first-child) {
    margin-left: 5px;
}


.topCollections .list-group-item .loader  {
  display: flex;
  flex-wrap: wrap;
//   justify-content: center;
}

.topCollections .list-group-item .loader  .collectionLoader{
  width: 100%;

  margin: 20px 0 0px 0px;
//   justify-content: center;
}

.topCollections .list-group-item .collectionLoader .b-aspect {
  margin-bottom: 20px;

}
.topCollections .list-group-item .collectionLoader .b-skeleton-img {
  border-radius: 20px !important;
}

.topCollections .list-group-item .loader .collectionLoader .b-aspect:first-child .b-skeleton-img {

  height: 241px;
  border-radius: 20px;
}

.topCollections .list-group-item .loader .collectionLoader .b-skeleton-img {
  width: 70px;
  height: 90px;
  border-radius: 20px;
}

.topCollections .list-group-item .loader .collectionLoader .b-aspect:not(:first-child) {
    margin-left: 10px !important;
}
}
</style>
