<template>
  <div>
    <div class="buyOrBidNft">
      <div
        class="buyOrBidNftBg d-flex align-items-center justify-content-center"
      >
        <b-container>
          <b-row>
            <b-col md="12">
              <h4 class="text-center">Item Details</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="nft">
        <b-container fluid>
          <b-row>
           <b-col
              md="4"
              class="min-vh-50 d-flex flex-column align-items-center justify-content-between"
            >

             <b-skeleton-img v-if="fetchData"></b-skeleton-img>
             <img v-else :src="asset.image" alt="">


              <div class="auctionTime mt-3 text-center">
                <div class="end text-white">

                </div>
              <vue-countdown
                    :time="auctionTime"
                    v-slot="{ days, hours, minutes, seconds }"
                    :transform="transformSlotProps"
                  >

                    <div class="auctionTimer">
                       <span>{{
                    auctionTime == null ? "Auction Ended" : "Auction Ends In"
                  }}</span>
                      <div>
                        <span>{{ days }}</span>
                      <span>:</span>
                      <span>{{ hours }}</span>
                      <span>:</span>
                      <span>{{ minutes }}</span>
                      <span>:</span>
                      <span>{{ seconds }}</span>
                      </div>
                    </div>
                  </vue-countdown>
                  </div>


            </b-col>
            <b-col md="8" class="min-vh-50 d-flex flex-column justify-content-between">
              <div class="ownerName">

                  <div class="d-flex align-items-center">
                  <b-skeleton-icon
                    icon="person"
                    :icon-props="{ fontScale: 3 }"
                    v-if="fetchData"
                  ></b-skeleton-icon>
                  <img
                    :src="asset.photo"
                    style="width: 87px; height: 87px"
                    class="rounded-circle"
                    v-else
                    alt=""
                  />
                  <div v-if="fetchData" class="ml-2 w-100">
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                  </div>
                  <div class="ml-2" v-else>
                    <span class="name">{{ asset.username }}</span> <br />
                    <span class="type">Item Owner</span>
                  </div>
                  </div>
                  <div>
                  <div v-if="fetchData" class="ml-2 w-100">
                    <b-skeleton
                      animation="fade"
                      type="button"
                      width="100%"
                    ></b-skeleton>
                  </div>
                 <div v-else class="d-flex">
                    <div class="highestBid">
                      <i class="fas fa-fire text-warning"></i>
                      <span>Highest Bid</span>
                      <span class="line">|</span>
                      <span
                        >{{ convertToNormalPrice(asset) }}
                        <span>{{ checkCurrency(asset.currency) }}</span></span
                      >
                    </div>
                    <!-- <Share :asset="asset" /> -->
                  </div>
                </div>


              </div>

              <!-- <div class="likesViews mt-3">
                <b-button><i class="far fa-heart"></i> 22.5K</b-button>
                <b-button class="ml-3"
                  ><i class="fas fa-eye"></i> 50.5K</b-button
                >
              </div> -->

              <div class="description mt-3 mb-2">
                <div class="heading">Description</div>
                <div v-if="fetchData">
                  <b-skeleton animation="fade" width="70%"></b-skeleton>
                  <b-skeleton animation="fade" width="50%"></b-skeleton>
                  <b-skeleton animation="fade" width="40%"></b-skeleton>
                  <b-skeleton animation="fade" width="30%"></b-skeleton>
                </div>
                <p class="mt-2" v-else>
                  {{asset.description}}
                </p>
              </div>

              <div class="bidsHistory">
                <div ref="bidHistory">
                  <b-nav align="center">
                    <b-nav-item
                      @click="getHistory(history.component, historyIndex)"
                      :active="activeHistory == historyIndex ? true : false"
                      v-for="(history, historyIndex) in history"
                      :key="historyIndex"
                    >
                      {{ history.name }}
                    </b-nav-item>
                  </b-nav>
                  <div v-if="nftBids == null">
                    <div class="userBid d-flex w-100 mb-2">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                    <div class="userBid d-flex w-100 mb-2">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                    <div class="userBid d-flex w-100">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2" v-else>
                    <component
                     :creator="asset.creator"
                    :asset="asset"
                      :nftBids="nftBids"
                      v-bind:is="currentComponent"
                    ></component>
                  </div>
                </div>

              </div>

              <div class="placeOrBid mt-2">
                <b-skeleton
                  v-if="fetchData"
                  type="button"
                  animation="fade"
                  width="70%"
                ></b-skeleton>
                <div v-else>
                  <b-button
                    v-if="auctionTime == null"
                    :disabled="buy.disabled"
                    @click="claimNft"
                    >{{ buy.text }}</b-button
                  >
                  <b-button v-else disabled>{{ buy.text }}</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
import Bids from "../../BuyNft/History/Bids.vue";
import History from "../../BuyNft/History/History.vue";
import Details from "../../BuyNft/History/Details.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  async created() {
    this.fetchData = true;
    this.contractAddress = await this.$store.getters.getSmartContractStructure
      .smartContract.address;
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;
    this.usdtContract = await this.$store.getters.getSmartContractStructure
      .usdtContract.contract;
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;

    await this.getNftInfo();

    setTimeout(() => {
      this.fetchData = false;
    }, 3000);
    this.bidPrice = Number(this.convertToNormalPrice(this.asset)) + 1;
    // this.fetchData = false;
  },
  computed: {
    calculateHeight() {
      return this.$refs.bidHistory + "px";
    },
  },
  data() {
    return {
      usdtContract: null,
      contract: null,
      contractAddress: null,
      account: null,
      asset: {},
      bidPrice: null,
      fetchData: false,
      activeHistory: 0,
      currentComponent: "History",
      nftBids: null,
      history: [
        { name: "History", component: "History" },
        { name: "Details", component: "Details" },
      ],
      buy: {
        text: "Claim",
        disabled: false,
      },
      auctionTime: null,
    };
  },
  components: {
    Bids,
    History,
    Details,
    VueCountdown,
  },
  methods: {
    async claimNft() {
      this.buy = {
        text: "Please approve metamask...",
        disabld: true,
      };

      const claimNft = await this.contract.methods
        .claimAuction(this.asset.id.toString())
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });

      if (claimNft) {
        this.buy.text = "Updating Database....";
        await this.syncMetamaskWithApi();
        this.$toast.success(`${this.asset.name} is successfully claimed`, {
          position: "top",
          duration: 3000,
        });
        setTimeout(() => {
          this.$router.push({ name: "Profile" });
        });
      }
      if (!claimNft) {
        this.buy = {
          text: "Retry",
          disabled: false,
        };
        return this.$toast.error(`Unable to claim, please try again`, {
          position: "top",
          duration: 3000,
        });
      }
      this.buy.text = "NFT Claimed";
    },


    async getNftInfo() {
      this.fetchData = true;
      const nftId = atob(this.$route.params.nftId);
      await this.$axios
        .get(
          `https://us-central1-everestcoin.cloudfunctions.net/get-nft-by-id?id=${nftId}`
        )
        .then((res) => {
          this.asset = res.data.result[0];
          this.nftBids = res.data.bids;
          if (this.asset.end_time != null) {
            let convertUnixToDate = this.$moment
              .unix(this.asset.end_time)
              .format("YYYY-MM-DD HH:mm:ss");

            if (
              this.$moment().format("DD/MM/YYYY") >
              this.$moment(convertUnixToDate).format("DD/MM/YYYY")
            ) {
              this.auctionTime = null;
            } else {
              this.auctionTime = this.$moment(convertUnixToDate).diff(
                this.$moment()
              );
            }
          }
          res;
        })
        .catch((err) => {
          err;
        });
      this.fetchData = false;
    },

    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    getHistory(component, componentIndex) {
      this.activeHistory = componentIndex;
      this.currentComponent = component;
    },
    convertToNormalPrice(asset) {
   if (Number(asset.price) == 0) {
        return 0;
      } else {
        let UpdatedPrice =
          asset.auction == 0
            ? web3.utils.fromWei(asset.price.toString(), "Ether")
            : (asset.price / 10 ** 18).toString();

        return UpdatedPrice;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buyOrBidNft .bidPrice .modal-content {
  border-radius: 50px;
  height: 200px;
}
.buyOrBidNft .nft {
  padding: 0 58px 0 58px;
  margin-top: 50px;
}
.buyOrBidNft .buyOrBidNftBg {
 background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 200px;
  padding: 10px;
  background-position-y: center;
}

.buyOrBidNft .buyOrBidNftBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.buyOrBidNft .nft .col-md-4 img,
.buyOrBidNft .nft .col-md-4 .b-skeleton {
  width: 100%;
  height: 691px;
  border-radius: 20px;
  border: 3px solid #D8B871;
}
.buyOrBidNft .nft .col-md-8 .ownerName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.buyOrBidNft .nft .col-md-8 .ownerName .name,
.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  font-size: 20px;
  font-family: "SemiBold";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-4 .auctionTime {
  width: 100%;
}

.buyOrBidNft .nft .col-md-4 .auctionTime .auctionTimer {
  background-color: #1d2034;
  color: #ffffff;
  font-size: 20px;
  font-family: "SemiBold";
  padding: 10px;
  border-radius: 10px;
  letter-spacing: 2px;
}

.buyOrBidNft .nft .col-md-8 .ownerName .type {
  font-size: 18px;
  font-family: "Regular";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .ownerName .type {
  font-size: 18px;
  font-family: "Regular";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 7px;
  border-radius: 20px;
  background-color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .likesViews button {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  border: none;
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .likesViews button i {
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .description .heading {
  font-size: 30px;
  font-family: "Bold";
}

.buyOrBidNft .nft .col-md-8 .description p {
  font-size: 22px;
  font-family: "Light";
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
  background-color: #0c0e1a;
  color: #ffffff;
  font-size: 20px;
  font-family: "SemiBold";
 width: 40%;
  border-radius: 20px;
}

// .buyOrBidNft .nft .col-md-8 .bidsHistory {
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//     height: 350px;
//     overflow-y: scroll;
//     padding: 5px;
// }

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a {
  color: #ffffff;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a.active {
  color: #d8b871;
}

.buyOrBidNft .nft .col-md-8 .placeOrBid button {
  background-color: #d8b871;
  color: #1d2034;
  width: 100%;
  height: 50px;
  font-size: 22px;
  font-family: "Bold";
  border: none;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .buyOrBidNft .nft {
    padding: 0 0px 0px 0px;
    margin-top: 0px;
    padding: 10px;
  }
  .buyOrBidNft .buyOrBidNftBg {
    background-image: none;
    height: auto;
  }

  .buyOrBidNft .buyOrBidNftBg h4 {
    font-size: 20px;
    color: #1d2034;
  }

  .buyOrBidNft .nft .col-md-4 img,
  .buyOrBidNft .nft .col-md-4 .b-skeleton {
    width: 100%;
    height: 300px;
    border-radius: 20px;
  }
.buyOrBidNft .nft .col-md-8 .ownerName {

  align-items: flex-start;

}
  .buyOrBidNft .nft .col-md-8 .ownerName {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: none;
  }

  .buyOrBidNft .nft .col-md-8 .ownerName .name,
  .buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
    font-size: 18px;
    margin-top: 10px;
  }

  .buyOrBidNft .nft .col-md-8 .description .heading {
    font-size: 20px;
  }

  .buyOrBidNft .nft .col-md-8 .description p {
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .placeOrBid button {
    width: 100%;
    height: 50px;
    font-size: 20px;
  }

  .buyOrBidNft .nft .col-md-4 .auctionTime .end {

  font-size: 16px;

}

.buyOrBidNft .nft .col-md-4 .auctionTime .auctionTimer {

  font-size: 16px;
  font-family: "SemiBold";
  padding: 5px;
  border-radius: 10px;
  letter-spacing: 2px;
  overflow: hidden;
}
}
</style>
