<template>
  <div>
    <div class="existingNft">
      <div class="form-heading">
        <b-button @click="$emit('nftType')"
          ><i class="fas fa-arrow-circle-left fa-2x text-info"></i
        ></b-button>
        Select NFT
      </div>
      <div class="mintedNfts mt-2">
        <b-list-group>
          <b-list-group-item class="d-flex flex-row w-100 flex-wrap" v-if="nfts.length == 0 && !noNftFound">
            <b-col cols="12" class="mt-3">
              <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
            </b-col>
          </b-list-group-item>
          <b-list-group-item v-else-if="nfts.length == 0 && noNftFound">
            <p class="noData">No Data Found</p>
          </b-list-group-item>
          <b-list-group-item
            v-for="(nft, nftIndex) in nfts"
            :key="nftIndex"
            class="mintNfts"
            v-else
          >
            <b-overlay
              :show="loadMint"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="md"
            >
              <div class="asset">
                <div class="image">
                  <img
                    :src="nft.image"
                    alt="img"
                    class="img-thumbnail img-fluid"
                  />
                  <span :id="'asset' + nftIndex" class="name">{{
                    nft.name
                  }}</span>
                </div>
                <b-popover
                  :target="'asset' + nftIndex"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>Description - {{ asset.name }}</template>
                  {{ nft.description }}
                </b-popover>
                <div class="mintBtn mt-1">
                  <b-button block @click="prepareToMintNft(nft)">Mint</b-button>
                </div>
              </div>
            </b-overlay>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;
    await this.getExistingNfts();
  },
  data() {
    return {
      account: null,
      contract: null,
      fetchData: false,
      nfts: [],
      noNftFound: false,
      loadMint: false,
    };
  },
  methods: {
    async getExistingNfts() {
      const url =
        "https://us-central1-everestcoin.cloudfunctions.net/minted-nfts";
      await this.$axios
        .post(url, { address: this.account })
        .then((response) => {
          this.nfts = response.data;
          if (this.nfts.length == 0) {
            this.noNftFound = true;
          } else {
            this.noNftFound = false;
          }
          response;
        });
    },
    async prepareToMintNft(asset) {
      this.loadMint = true;
      this.$toast.info("Storing NFT Information", {
        position: "top-right",
        duration: 3000,
      });
      const storeIpfsLink = await this.storeIpfsDataOnCloud(asset);

      if (storeIpfsLink) {
        this.$toast.info("Please approve metamask & wait", {
          position: "top",
          duration: 3000,
        });

        let contractExecution = await this.executeMetamaskContracts(asset);
        if (contractExecution) {
          this.$toast.info("Updating Database...", {
            position: "top-right",
            duration: 3000,
          });
          await this.syncMetamaskWithApi();

          this.$toast.success(`${asset.name} is successfully minted`, {
            position: "top",
            duration: 3000,
          });
          setTimeout(() => {
            this.$emit("nftType");
          }, 2000);
        }
        if (!contractExecution) {
          this.$toast.error("Unable to mint asset, Please try again!", {
            position: "top",
            duration: 3000,
          });
        }
      }

      if (!storeIpfsLink) {
        this.$toast.error("Unable to create asset, Please try again!", {
          position: "top",
          duration: 3000,
        });
      }
      this.loadMint = false;
    },

    async storeIpfsDataOnCloud(asset) {
      let status = null;

      const assetPostData = await {
        name: asset.name,
        description: asset.description,
        image: asset.image,
        ipfs_link: asset.ipfs_link,
        address: this.account,
        royalty: asset.royalty,
        collection: asset.collection,
      };

      const url =
        "https://us-central1-everestcoin.cloudfunctions.net/add-asset-data";
      await this.$axios
        .post(url, assetPostData)
        .then((res) => {
          status = true;

          res;
        })
        .catch((err) => {
          status = false;
          err;
        });
      return status;
    },

    async executeMetamaskContracts(asset) {
      let mintToken = await this.contract.methods
        .mintToken(asset.ipfs_link, asset.royalty)
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });

      return mintToken;
    },
  },
};
</script>

<style lang="scss">
.existingNft .form-heading button {
  background-color: transparent;
  border: 0px;
}
.existingNft .list-group {
  flex-direction: row;
  flex-wrap: wrap;
}
.existingNft .mintNfts {
  padding: 5px;
  margin: 0px;
  overflow: hidden;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 10px;
}

.existingNft .mintNfts .asset img {
  width: 200px;
  height: 200px;
}

.existingNft .mintNfts .asset .info {
  // margin-left: 10px;
}

.existingNft .mintNfts .asset .name {
  font-size: 18px;
  font-family: "Bold";
  color: #d8b871;
  display: flex;
  justify-content: center;
}

.existingNft .mintNfts .asset .info .description {
  font-size: 16px;
  font-family: "Regular";
  word-wrap: break-word;
}

.existingNft .mintNfts .asset button {
  font-size: 14px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {

.existingNft .mintNfts {
  width: 100%;
}

.existingNft .mintNfts .asset img {
  width: 100%;
  height: 200px;
}

.existingNft .mintNfts .asset .info {
  // margin-left: 10px;
}

.existingNft .mintNfts .asset .name {
  font-size: 18px;
  font-family: "Bold";
  color: #d8b871;
  display: flex;
  justify-content: center;
}

.existingNft .mintNfts .asset .info .description {
  font-size: 16px;
  font-family: "Regular";
  word-wrap: break-word;
}

.existingNft .mintNfts .asset button {
  font-size: 14px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

}
</style>
