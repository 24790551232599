<template>
  <div>
    <div class="navbar" data-aos="fade-down" data-aos-duration="3000">
      <b-navbar toggleable="lg">
        <b-navbar-brand
          class="btn p-0 m-0"
          @click="$router.push({ name: 'Home' })"
        >
          <img
            :src="require('@/assets/images/everestLogo.svg')"
            alt=""
            class="img-fluid"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              @click="
                $router.push({
                  name: 'Marketplace',
                  params: {
                    category: 'all',
                  },
                })
              "
              >Marketplace</b-nav-item
            >
            <!-- <b-nav-item>Ranking</b-nav-item>
            <b-nav-item>Collections</b-nav-item>
            <b-nav-item>Pricing</b-nav-item> -->
            <b-nav-item
              v-if="metaAccount.account == null"
              @click="$emit('connectMetamask')"
              >Connect Wallet <i class="fas fa-wallet ml-1"></i
            ></b-nav-item>
            <b-nav-item v-else @click="$emit('disconnectWallet')"
              >Disconnect<i class="fas fa-wallet ml-1"></i
            ></b-nav-item>

            <b-nav-item-dropdown
              right
              v-if="metaAccount.account && metaAccount.account != null"
            >
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <em>
                  <img
                    v-if="$store.getters.getMetamaskAccount.user.image != null"
                    :src="$store.getters.getMetamaskAccount.user.image"
                    style="
                      min-width: 50px;
                      max-height: 50px;
                      border: 2px solid #1d2034;
                    "
                    class="rounded-circle"
                    alt=""
                  />
                  <i class="fas fa-user" v-else></i>
                </em>
              </template>
              <!-- <b-dropdown-item @click="$router.push({ name: 'WalletsInfo' })"
                >Wallet Info</b-dropdown-item
              > -->
              <b-dropdown-item
                v-if="metaAccount.hasRole"
                @click="goToAdminPanel('Admin')"
                >Admin Panel</b-dropdown-item
              >
              <!-- <b-dropdown-item @click="$router.push({ name: 'Login' })"
                >Login</b-dropdown-item
              > -->
              <b-dropdown-item
                @click="goToAdminPanel('CreateNft')"
                v-if="metaAccount.minter_role"
                >Create</b-dropdown-item
              >
              <b-dropdown-item @click="goToAdminPanel('Profile')"
                >Profile</b-dropdown-item
              >
              <!-- <b-dropdown-item @click="$router.push({ name: 'SellNft' })"
                >Sell Nft</b-dropdown-item
              > -->
              <!-- <b-dropdown-item href="#">Sign Out</b-dropdown-item> -->
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.metaAccount = this.$store.getters.getMetamaskAccount.metamaskAccount;
  },
  data() {
    return {
      metaAccount: {},
    };
  },
  methods: {
    goToAdminPanel(path) {
      if (!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.replace({
          name: "Profile",
          params: {
            address: btoa(this.metaAccount.account),
          },
        });
      }
      this.$router.push({
        name: path,
        params: {
          address: btoa(this.metaAccount.account),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.navbar nav {
  width: 100vw !important;
  display: flex;
  align-items: center;
  // background-color: red;
  // padding: 25px;
  z-index: -1;
}

.navbar nav .navbar-nav .nav-item:not(:first-child) {
  margin-left: 30px;
}
.navbar nav .navbar-nav {
  display: flex;
  align-items: center;
}
.navbar nav .navbar-nav .nav-item a {
  font-family: "Regular";
  font-size: 16px;
  color: #1d2034;
  padding: 5px;
  letter-spacing: 1px;
}

.navbar nav .navbar-nav .nav-item a:hover {
  color: #d8b871;
}

.navbar nav .navbar-nav .nav-item:nth-child(2) a {
  border: 2px solid #1d2034 !important;
  border-radius: 50px;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .navbar nav {
    padding: 5px;
  }

  .navbar .navbar-brand img {
    width: 200px;
  }

  .navbar nav .navbar-nav .nav-item:not(:first-child) {
    margin-left: 0px;
  }

  .navbar nav .navbar-nav {
    padding: 10px;
  }

  .navbar nav .navbar-nav .nav-item:nth-child(5) {
    width: 60%;
    border: 2px solid #1d2034 !important;
  }
}
</style>
