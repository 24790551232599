<template>
  <div>
    <div class="walletInfo">
      <div
        class="walletInfoBg d-flex align-items-center justify-content-center"
      >
        <b-container>
          <b-row>
            <b-col md="12">
              <h4 class="text-center">Connect Wallet</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="walletInformation mt-3">
        <b-list-group>
          <b-list-group-item
            v-for="(wallet, walletIndex) in wallets"
            :key="walletIndex"
          >
            <img :src="require('@/' + wallet.img)" alt="" />
            <h6>{{ wallet.name }}</h6>
            <p>{{ wallet.description }}</p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wallets: [
        {
          name: "MetaMask ",
          description:
            "A browser extension with great flexibility. The web's most popular wallet",
          img: "assets/images/MetamaskW.svg",
        },
        {
          name: "Trust Wallet ",
          description:
            "A browser extension with great flexibility. The web's most popular wallet",
          img: "assets/images/TrustW.svg",
        },
        {
          name: "Wallet Connet ",
          description:
            "A browser extension with great flexibility. The web's most popular wallet",
          img: "assets/images/WalletW.svg",
        },
        {
          name: "Bsc Scan ",
          description:
            "A browser extension with great flexibility. The web's most popular wallet",
          img: "assets/images/BscW.svg",
        },
        {
          name: "Coin Base ",
          description:
            "A browser extension with great flexibility. The web's most popular wallet",
          img: "assets/images/CoinW.svg",
        },
        {
          name: "Ethereum ",
          description:
            "A browser extension with great flexibility. The web's most popular wallet",
          img: "assets/images/EtheriumW.svg",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.walletInfo .walletInformation {
  padding: 0 58px 0 58px;
  margin-top: 50px;
}
.walletInfo .walletInfoBg {
  background-image: url("~@/assets/images/homepageBg.svg");
  background-size: cover;
  height: 316px;
}

.walletInfo .walletInfoBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.walletInfo .walletInformation .list-group {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.walletInfo .walletInformation .list-group .list-group-item {
  background-color: #faf9f9;
  border-radius: 20px;
  width: 350px;
  height: 320px;
  text-align: center;
  margin: 10px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
}

.walletInfo .walletInformation .list-group .list-group-item img {
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
}

.walletInfo .walletInformation .list-group .list-group-item h6 {
  font-size: 30px;
  font-family: "Bold";
}

.walletInfo .walletInformation .list-group .list-group-item p {
  font-size: 20px;
  font-family: "Light";
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .walletInfo .walletInformation {
    padding: 0 10px 0 10px;
    margin-top: 30px;
  }
  .walletInfo .walletInfoBg {
    height: 200px;
  }

  .walletInfo .walletInfoBg h4 {
    font-size: 20px;
  }

  .walletInfo .walletInformation .list-group {
    margin-top: 30px;
  }

  .walletInfo .walletInformation .list-group .list-group-item {
    width: 100%;
    height: 320px;

    margin: 10px 0px 20px 0px;
  }

  .walletInfo .walletInformation .list-group .list-group-item img {
    width: 100px;
    height: 100px;
    margin: auto;
    display: block;
  }

  .walletInfo .walletInformation .list-group .list-group-item h6 {
    font-size: 30px;
    font-family: "Bold";
  }

  .walletInfo .walletInformation .list-group .list-group-item p {
    font-size: 20px;
    font-family: "Light";
  }
}
</style>
