<template>
  <div>
    <div class="collection" v-if="createCollection">
      <CollectionForm
        @nftType="createCollection = false"
        @collectionCreated="getNftsCollections(), (createCollection = false)"
      />
    </div>
    <div class="nftForm" v-else>
      <div class="form-heading">
        <b-button @click="$emit('nftType')" :disabled="createBtn.disabled"
          ><i class="fas fa-arrow-circle-left fa-2x text-info"></i
        ></b-button>
        Create Item
      </div>
      <b-form class="mt-3 min-vh-50" @submit.prevent="prpareToUploadOnIpfs">
        <b-form-group v-if="fetchData">
          <b-skeleton type="input"></b-skeleton>
        </b-form-group>
        <b-form-group v-else>
          <div class="createCollection mb-2">
            <b-button
              size="sm"
              @click="createCollection = true"
              :disabled="createBtn.disabled"
              >Create New Collection</b-button
            >
          </div>
          <b-form-select
            :options="collectionsOptions"
            v-model="asset.collection"
            :disabled="createBtn.disabled"
          ></b-form-select>
        </b-form-group>
        <div
          v-if="asset.collection != null"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-easing="ease-out-cubic"
        >
          <b-form-group label="Choose File*">
            <b-form-file
              v-model="asset.file"
              placeholder="Choose PNG, JPG, GIF"
              :disabled="createBtn.disabled"
              accept="image/jpeg, image/png, image/gif"
              required
            ></b-form-file>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox v-model="airdrop" switch
              >Enable Airdrop</b-form-checkbox
            >
          </b-form-group>

          <b-form-group v-if="airdrop">
            <b-modal
              id="airdropList"
              title="List of addresses"
              no-close-on-backdrop
              no-close-on-keyboard
              no-close-on-esc
              hide-footer
              centered
              size="md"
              modal-class="airdropList"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(address, addressIndex) in viewAddresses"
                  :key="addressIndex"
                >
                  <p
                    :class="
                      !address.validation
                        ? 'alert alert-success'
                        : 'alert alert-danger'
                    "
                  >
                    <span>
                      {{ address.address }}
                      <span v-if="address.validation">
                        - Invalid Address will be excluded while execution</span
                      >
                    </span>
                  </p>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
            <b-form-input
              :trim="true"
              v-model="asset.addresses"
              placeholder="Paste addresses seperated by commas"
              @input="addAddresses"
              :required="addresses.length == 0 ? true : false"
              v-if="
                Object.keys(viewAddresses).length == 0 || addresses.length == 0
              "
            ></b-form-input>
            <div>
              <div
                class="listOfAddresses mt-1"
                v-if="Object.keys(viewAddresses).length != 0"
              >
                <span
                  class="btn p-0 m-0 list text-info"
                  @click="$bvModal.show('airdropList')"
                  :disabled="createBtn.disabled"
                  >{{ Object.keys(viewAddresses).length }}
                  {{
                    Object.keys(viewAddresses).length > 1
                      ? "addresses"
                      : "address"
                  }}
                  added. Click here to view.
                </span>
                <b-button
                  variant="danger"
                  pill
                  class="ml-2"
                  size="sm"
                  @click="(addresses = []), (viewAddresses = {})"
                  :disabled="createBtn.disabled"
                  >Clear All</b-button
                >
              </div>
              <b-alert
                variant="danger"
                class="text-center mt-2 p-2"
                style="font-size: 14px; font-family: 'SemiBold'"
                show
                v-if="
                  Object.keys(viewAddresses).length != 0 &&
                  addresses.length == 0
                "
                >All Addresses are invalid!</b-alert
              >
            </div>
          </b-form-group>

          <b-form-group label="Item Name*">
            <b-form-input
              placeholder="Item Name"
              v-model="asset.name"
              :disabled="createBtn.disabled"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Item Description*">
            <b-form-textarea
              placeholder="Item Description(500 Characters Max)"
              v-model="asset.description"
              :disabled="createBtn.disabled"
              required
            ></b-form-textarea>
            <div class="text-right">
              <small
                :style="{
                  fontSize: '12px',
                  fontFamily: 'Bold',
                  color: charactersCount > 500 ? '#ff4444' : 'black',
                }"
                >{{ charactersCount }}</small
              >
            </div>
          </b-form-group>

          <b-form-group label="Royalty*">
            <b-form-spinbutton
              placeholder="Royalty"
              v-model="asset.royalty"
              required
              :disabled="createBtn.disabled"
              min="1"
              :max="maxRoyalty"
            ></b-form-spinbutton>
          </b-form-group>

          <b-form-group>
            <b-button
              block
              :disabled="
                createBtn.disabled || (airdrop && addresses.length == 0)
              "
              type="submit"
              class="d-flex align-items-center justify-content-center"
              >{{ createBtn.text
              }}<b-spinner
                v-if="createBtn.disabled && asset != {}"
                class="ml-2"
                variant="light"
                small
                type="grow"
              ></b-spinner
            ></b-button>
          </b-form-group>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import UploadToIPFS from "./UploadToIPFS.js";
// import { required, minLength, maxLength  } from "vuelidate/lib/validators";
// import Web3 from "web3";
import CollectionForm from "./CollectionForm";
export default {
  async created() {
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    this.maxRoyalty = await this.$store.getters.getMetamaskAccount
      .metamaskAccount.royalty;
    this.fetchData = true;

    this.$set(this.asset, "collection", null);
    this.$set(this.asset, "royalty", this.maxRoyalty);

    await this.getNftsCollections();
    this.fetchData = false;
  },
  components: {
    CollectionForm,
  },
  data() {
    return {
      createCollection: false,
      fetchData: false,
      contract: null,
      account: null,
      asset: {},
      addresses: [],
      viewAddresses: {},
      airdrop: false,
      collectionsOptions: [],
      createBtn: {
        text: "Create Item",
        disabled: false,
      },
      maxRoyalty: null,
    };
  },
  computed: {
    charactersCount() {
      if (this.asset.description) {
        return this.asset.description.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    async addAddresses() {
      let addresses = await this.asset.addresses.split(",");

      await addresses.forEach((address) => {
        if (address != "") {
          this.$set(
            this.viewAddresses,
            address.toLowerCase().replace(/ /g, ""),
            {
              address: address.toLowerCase().replace(/ /g, ""),
              validation: false,
            }
          );
        }
      });

      await addresses.forEach((address) => {
        if (address.toLowerCase().replace(/ /g, "").length > 42) {
          this.viewAddresses[
            address.toLowerCase().replace(/ /g, "")
          ].validation = true;
        }
        if (address.toLowerCase().replace(/ /g, "").length == 42) {
          this.addresses.push(address.toLowerCase().replace(/ /g, ""));
        }
      });
      this.asset.addresses = "";
    },
    deleteAddress(address) {
      let add = address.toLowerCase();
      delete this.viewAddresses[add];
    },
    async getNftsCollections() {
      this.fetchData = true;
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-collections`;
      await this.$axios.get(url).then((res) => {
        const collections = res.data;

        if (collections.length == 0) {
          this.collectionsOptions = [
            {
              value: null,
              text: "No collections Found, Please create one to proceed",
              disabled: true,
            },
          ];
        } else {
          this.collectionsOptions = [
            {
              value: null,
              text: "Choose Collection to proceed",
              disabled: true,
            },
          ];
          collections.forEach((collection) => {
            this.collectionsOptions.push({
              value: collection.id,
              text: collection.name,
            });
          });
        }
      });
      this.fetchData = false;
    },
    async prpareToUploadOnIpfs() {
      if (this.asset.description.length > 500) {
        return this.$toast.error(
          "Description cannot be greater than 500 characters",
          {
            position: "top",
            duration: 3000,
          }
        );
      }
      // await this.$v.$touch();
      // if (this.$v.$invalid) {
      //   this.submitStatus = "ERROR";
      // } else {
      this.createBtn = {
        text: "Uploading Asset...",
        disabled: true,
      };
      const getUrl = await UploadToIPFS.uploadFile(this.asset);

      if (getUrl.status) {
        this.createBtn.text = "Storing Asset Information...";
        const storeIpfsLink = await this.storeIpfsDataOnCloud(
          getUrl.jsonObjectUrl,
          getUrl.assetDataJsonObject
        );

        if (storeIpfsLink) {
          this.createBtn.text = "Please approve metamask & wait...";

          let contractExecution = await this.executeMetamaskContracts(
            getUrl.jsonObjectUrl
          );
          if (contractExecution) {
            this.createBtn.text = "Updating Database....";
            await this.syncMetamaskWithApi();
            const assetName = await this.asset.name;
            this.asset = {};
            this.createBtn.text = "NFT Created";
            this.$toast.success(`${assetName} is successfully created`, {
              position: "top",
              duration: 3000,
            });
            setTimeout(() => {
              this.$router.push({
                name: "Profile",
                params: {
                  address: this.account,
                },
              });
            }, 2000);
          }
          if (!contractExecution) {
            this.$toast.error("Unable to create asset, Please try again!", {
              position: "top",
              duration: 3000,
            });
            return this.clearResponse();
          }
        }

        if (!storeIpfsLink) {
          this.$toast.error("Unable to create asset, Please try again!", {
            position: "top",
            duration: 3000,
          });
          return this.clearResponse();
        }
      }

      if (!getUrl.status) {
        this.$toast.error(
          "Unable to store asset information, Please try again!",
          {
            position: "top",
            duration: 3000,
          }
        );
        return this.clearResponse();
      }
      // }
    },

    async storeIpfsDataOnCloud(jsonUrl, assetDataJsonObject) {
      let status = null;

      const assetPostData = await {
        name: this.asset.name,
        description: this.asset.description.replace(/'/g, ""),
        image: assetDataJsonObject.image,
        ipfs_link: jsonUrl,
        address: this.account,
        royalty: this.asset.royalty,
        collection: this.asset.collection,
        count: this.airdrop ? this.addresses.length : 1,
      };

      const url =
        "https://us-central1-everestcoin.cloudfunctions.net/add-asset-data";
      await this.$axios
        .post(url, assetPostData)
        .then((res) => {
          status = true;

          res;
        })
        .catch((err) => {
          status = false;
          err;
        });
      return status;
    },

    async executeMetamaskContracts(jsonUrl) {
      let mintToken;

      if (this.airdrop) {
        let arrayAddress = this.addresses;
        mintToken = await this.contract.methods
          .mintBulkTokens(jsonUrl, this.asset.royalty, arrayAddress)
          .send({ from: this.account })
          .then((res) => {
            res;
            return true;
          })
          .catch((err) => {
            err;
            return false;
          });
      } else {
        mintToken = await this.contract.methods
          .mintToken(jsonUrl, this.asset.royalty)
          .send({ from: this.account })
          .then((res) => {
            res;
            return true;
          })
          .catch((err) => {
            err;
            return false;
          });
      }

      return mintToken;
    },
    clearResponse() {
      setTimeout(() => {
        this.createBtn.text = "Retry";
        this.createBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.airdropList .modal-content {
  background-color: #1d2034;
  border-radius: 10px;
  border: 3px solid #d8b871;
}

.airdropList header {
  color: #ffffff;
  font-family: "Bold";
  font-size: 14px;
}
.airdropList .list-group .list-group-item p {
  word-break: break-all;
  font-size: 16px;
  font-family: "SemiBold";
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0px;
  // margin: 0px;
}

.airdropList .list-group .list-group-item p span:last-child {
  font-size: 14px;
}

.airdropList .list-group .list-group-item p button {
  background-color: transparent;
  border: none;
}

.airdropList .list-group .list-group-item p button i {
  color: red;
}
.airdropList .list-group .list-group-item {
  background-color: #1d2034;
  padding: 0px;
  margin: 0px;
}

.nftForm .form-heading button {
  background-color: transparent;
  border: 0px;
}
.nftForm .listOfAddresses span {
  font-size: 16px;
  font-family: "SemiBold";
}
</style>
