import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from "../components/Homepage/Homepage.vue";
import GetNftsCopies from "../components/Homepage/GetNftsCopies.vue";
import Marketplace from "../components/Marketplace/Marketplace.vue";
import Collections from "../components/Marketplace/Collections.vue";
import BuyNft from "../components/BuyNft/BuyNft.vue";
import AuctionNft from "../components/BuyNft/AuctionNft.vue";
import WalletsInfo from "../components/ConnectWallet/WalletsInfo.vue";
import Login from "../components/UserLogin/Login.vue";
import SignUp from "../components/UserLogin/SignUp.vue";
import NftCreations from "../components/CreateNft/NftCreations.vue";
import Profile from "../components/Profile/Profile.vue";
import SellNft from "../components/SellNft/SellNft.vue";
import Panel from "../components/AdminPanel/Panel.vue"
import UpdatePriceOrWithdrawNft from "../components/Profile/UpdateNFT/UpdatePriceOrWithdrawNft.vue"
import ViewNftOnly from "../components/Profile/UpdateNFT/ViewNftOnly.vue"
import NotFound from "../components/NotFound.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Homepage
  },
  {
    path: '/nfts/:nftName/:nftId',
    name: 'NftCopies',
    component: GetNftsCopies
  },
  //MarketPlace
  {
    path: '/marketplace/:category',
    name: 'Marketplace',
    component: Marketplace
  },
  {
    path: '/collections/:collectionId',
    name: 'Collections',
    component: Collections
  },
  //BUY/Sell?Auction
  {
    path: '/buyNft/:nftId',
    name: 'BuyNft',
    component: BuyNft
  },
  {
    path: '/sellNft/:nftId',
    name: 'SellNft',
    component: SellNft
  },
  {
    path: '/auctionNft/:nftId',
    name: 'AuctionNft',
    component: AuctionNft
  },
  //WALLETS
  {
    path: '/walletsInfo',
    name: 'WalletsInfo',
    component: WalletsInfo
  },
  //LOGIN REGISTER
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  //CREATE NFT
  {
    path: '/:address/createNft',
    name: 'CreateNft',
    component: NftCreations
  },
  //PROFILE
  {
    path: '/:address/myProfile',
    name: 'Profile',
    component: Profile
  },
  //Admin
  {
    path: '/:address/admin',
    name: 'Admin',
    component: Panel
  },
  //Withdraw/Update NFT
  {
    path: '/updateNft/:address//:nftId',
    name: 'UpdateNft',
    component: UpdatePriceOrWithdrawNft
  },
  {
    path: '/viewNft/:address/:nftId',
    name: 'ViewNftOnly',
    component: ViewNftOnly
  },
  //No Path Found
  { path: "/:notFound(.*)", component: NotFound },
]




const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
