<template>
  <div>
    <div class="collectionForm">
      <div class="form-heading">
        <b-button @click="$emit('nftType')" :disabled="collectionBtn.disabled"
          ><i class="fas fa-arrow-circle-left fa-2x text-info"></i
        ></b-button>
        Create Collection
      </div>
      <b-form @submit.prevent="prepareToCreateCollection">
        <b-form-group>
          <b-form-file
            placeholder="Collection Image"
            required
            v-model="collection.file"
          ></b-form-file>
        </b-form-group>
        <b-form-group>
          <b-form-input
            placeholder="Collection Name"
            required
            v-model="collection.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-button type="submit" block :disabled="collectionBtn.disabled">{{
            collectionBtn.text
          }}</b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { create } from "ipfs-http-client";
const storeInIpfs = create("https://ipfs.infura.io:5001/api/v0");
export default {
  data() {
    return {
      collection: {},
      collectionBtn: {
        text: "Create Collection",
        disabled: false,
      },
    };
  },
  methods: {
    async prepareToCreateCollection() {
      this.collectionBtn = {
        text: "Uploading Image....",
        disabled: true,
      };
      const storeInIpfs = await this.getIpfsImageLink();
      if (storeInIpfs.status) {
        this.collectionBtn.text = "Storing Collection....";
        this.$toast.info("Uploaded Successfully", {
          duration: 3000,
          position: "top-right",
        });
        const collection = {
          name: this.collection.name,
          image: storeInIpfs.assetDataJsonObject.image,
        };
        const uploadToServer = await this.createCollection(collection);
        if (uploadToServer) {
          this.collectionBtn.name = `${collection.name} Created`;
          this.$toast.success(
            `${this.collection.name} is successfully created`,
            {
              duration: 3000,
              position: "top",
            }
          );
          setTimeout(() => {
            this.$emit("collectionCreated");
          }, 3000);
        }
        if (!uploadToServer) {
          this.$toast.error("Unable to create collection, Please try again!", {
            duration: 3000,
            position: "top",
          });
          return (this.collectionBtn = {
            text: "Create Collection",
            disabled: false,
          });
        }
      }
      if (!storeInIpfs) {
        this.$toast.error("Unable to upload file, Please try again!", {
          duration: 3000,
          position: "top",
        });
        return (this.collectionBtn = {
          text: "Create Collection",
          disabled: false,
        });
      }
    },
    async getIpfsImageLink() {
      let upload = {
        status: null,
        url: null,
      };
      try {
        const uploadToIps = await storeInIpfs.add(this.collection.file);
        const FileUrl = await `https://ipfs.infura.io/ipfs/${uploadToIps.path}`;

        const assetDataJsonObject = await {
          name: this.collection.name,
          image: FileUrl,
        };
        const jsonToStringify = await JSON.stringify(assetDataJsonObject);

        const storeJsonInIpfs = await storeInIpfs.add(jsonToStringify);

        const jsonObjectUrl = `https://ipfs.infura.io/ipfs/${storeJsonInIpfs.path}`;

        upload = {
          status: true,
          jsonObjectUrl: jsonObjectUrl,
          assetDataJsonObject: assetDataJsonObject,
        };
      } catch (error) {
        upload = {
          status: false,
          url: null,
        };
        error;
      }
      return upload;
    },
    async createCollection(collection) {
      let status = null;
      const url = `https://us-central1-everestcoin.cloudfunctions.net/add-collection`;
      await this.$axios
        .post(url, collection)
        .then((res) => {
          res;
          status = true;
        })
        .catch((err) => {
          err;
          status = false;
        });
      return status;
    },
  },
};
</script>

<style lang="scss">
.collectionForm .form-heading button {
  border: none;
  background-color: transparent;
}
</style>
