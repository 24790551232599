<template>
  <div>
    <div class="fixedForm">
      <b-form
        @submit.prevent="
          !checkSellingPrice
            ? setFixedSeling()
            : $toast.error('Please enter a valid price')
        "
      >
        <b-alert
          class="text-danger"
          variant="danger"
          :show="checkSellingPrice ? true : false"
          >{{ checkSellingPrice ? "Please Enter a valid price" : "" }}</b-alert
        >
        <b-form-group>
          <b-form-input
            placeholder="Enter Price"
            v-model="fixed.price"
            required
            :disabled="fixedBtn.disabled"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <p class="font-weight-bolder">USDT</p>
        </b-form-group>
        <b-form-group>
          <b-form-datepicker
            v-model="fixed.date"
            placeholder="Select Auction End Date"
            :date-disabled-fn="dateDisabled"
            menu-class="w-100"
            calendar-width="100%"
            required
            :disabled="fixedBtn.disabled"
          ></b-form-datepicker>
        </b-form-group>

        <b-form-group>
          <b-button
            block
            type="submit"
            :disabled="fixedBtn.disabled"
            class="d-flex align-items-center justify-content-center"
            >{{ fixedBtn.text
            }}<b-spinner
              variant="light"
              v-if="fixedBtn.disabled"
              class="ml-2"
              small
              type="grow"
              label="Spinning"
            ></b-spinner
          ></b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);

export default {
  props: ["asset"],
  async created() {
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    this.$set(
      this.fixed,
      "currency",
      this.$store.getters.getSmartContractStructure.usdtContract.address
    );
  },
  data() {
    return {
      contract: null,
      account: null,
      fixed: {},
      fixedBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  computed: {
    checkSellingPrice() {
      if (
        isNaN(this.fixed.price) &&
        this.fixed.price &&
        this.fixed.price != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    dateDisabled(ymd, date) {
      date;
      return new Date(ymd) < new Date() && new Date(ymd) != new Date();
    },
    async setFixedSeling() {
      if (!this.fixed.date || this.fixed.date == "") {
        return this.$toast.error("Please select the auction end date!", {
          position: "top",
          duration: 3000,
        });
      }
      this.fixedBtn = {
        text: "Please approve metamask & wait...",
        disabled: true,
      };
      let getBidDate = this.$moment
        .utc(this.fixed.date)
        .format("YYYY-MM-DD 23:59:00");
      const newPrice = web3.utils.toWei(this.fixed.price.toString(), "Ether");
      console.log(newPrice)
      let convertToUnix = this.$moment(getBidDate).format("X");
      let fSell = await this.contract.methods

        .sellToken(
          this.asset.id.toString(),
          newPrice,
          this.fixed.currency.toString(),
          0,
          convertToUnix.toString(),
          newPrice,
          true
        )
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });
      if (fSell) {
        this.fixedBtn.text = "Updating Database....";
        await this.syncMetamaskWithApi();
        this.$toast.success("NFT Listed for Auction", {
          position: "top",
          duration: 3000,
        });
        setTimeout(() => {
          this.$router.push({
            name: "Marketplace",
            params: {
              category: "all",
            },
          });
        }, 3000);
      }
      if (!fSell) {
        this.fixedBtn = {
          text: "Retry",
          disabled: false,
        };
        return this.$toast.error("Unable to list for Auction", {
          position: "top",
          duration: 3000,
        });
      }
      this.fixedBtn = {
        text: "NFT Listed For Auction",
        disabled: true,
      };
    },
  },
};
</script>
