<template>
  <div>
    <div class="explore mt-3 mb-2">
      <div class="heading mb-2">
        <h4>Explore</h4>
        <b-button>See All <i class="fas fa-arrow-right ml-1"></i></b-button>
      </div>

      <div class="exploreNft mt-2">
        <b-list-group>
          <b-list-group-item
            style="width: 100%; height: auto; box-shadow: none; border: none"
            v-if="exploreNfts.length == 0 && !noNftsFound"
          >
            <div class="loader w-100">
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
            </div>
          </b-list-group-item>
          <b-list-group-item
            v-else-if="exploreNfts.length == 0 && noNftsFound"
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="noNft">No Data Found!</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(art, artIndex) in exploreNfts"
            :key="artIndex"
            v-else
            @click="$router.push({ name: 'AuctionNft' })"
          >
            <img :src="art.url" c alt="" />
            <div class="info">
              <div class="d-flex justify-content-between">
                <span>{{ art.name }}</span>
                <span>
                  <i class="far fa-heart mr-1" v-if="!art.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i>
                  {{ art.likes }}
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <span
                  >Owned By:
                  <span class="font-weight-bolder">{{ art.owner }}</span></span
                >
                <span>{{ art.price }}</span>
              </div>
              <b-button block>{{
                art.auction ? "Place Bid" : "Buy Asset"
              }}</b-button>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exploreNfts: this.$sampleData.nfts,
      noNftsFound: false,
    };
  },
};
</script>

<style lang="scss">
.explore {
  padding: 0 58px 0 58px;
}

.explore .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.explore .heading .days {
  color: #d8b871;
}
.explore .heading h4 {
  font-size: 30px;
  font-family: "Bold";
}

.explore .heading button {
  font-size: 18px;
  font-family: "Regular";
  border: 2px solid #1d2034;
  background-color: transparent;
  color: #1d2034;
  border-radius: 20px;
}

.explore .list-group-item .loader {
  display: flex;
  flex-wrap: wrap;
}
.explore .list-group-item .b-skeleton-img {
  width: 295px;
  height: 410px;
  margin-left: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.explore .list-group-item .noNft {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.explore .list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.explore .list-group-item {
  margin: 0 0 30px 40px;
}

.explore .list-group-item {
  width: 295px;
  height: 410px;
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.explore .list-group-item img {
  width: 100%;
  height: 267px;
}

.explore .list-group-item .info {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Regular";
}

.explore .list-group-item .info button {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .explore {
    padding: 0 0px 0 0px;
  }

  .explore .heading {
    padding: 5px;
  }
  .explore .heading h4 {
    font-size: 18px;
  }

  .explore .heading button {
    font-size: 16px;
    height: 40px;
  }
  .explore .list-group {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .explore .exploreNft {
    padding: 10px;
  }

  .explore .list-group-item {
    margin: 0 0 30px 0px;
  }

  .explore .list-group-item {
    width: 100%;
    height: 350px;
  }

  .explore .list-group-item img {
    width: 100%;
    height: 200px;
  }

  .explore .list-group-item .loader {
    display: block;
    flex-wrap: none;
  }

  .explore .list-group-item .b-skeleton-img {
    width: 100%;
    height: 410px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
</style>
