<template>
  <div>
    <div class="topSellers mt-3 mb-2">
      <div class="heading">
        <h4>Top Sellers</h4>
        <b-button>View All <i class="fas fa-arrow-right ml-1"></i></b-button>
      </div>
      <div class="topSeller mt-2">
        <b-list-group>
          <b-list-group-item
            v-if="topSellers.length == 0 && !noSellers"
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="loader">
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
            </div>
          </b-list-group-item>
          <b-list-group-item
            v-else-if="topSellers.length == 0 && noSellers"
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="noSeller">No Data Found!</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(seller, sellerIndex) in topSellers"
            :key="sellerIndex"
            v-else
          >
            <div class="seller" data-aos="zoom-in" data-aos-duration="2000">
              <img :src="seller.photo" alt="" />
              <div class="nameAndPrice d-flex flex-column">
                <span>{{ seller.name }}</span>
                <!-- <span>100 ETH</span> -->
              </div>

              <!-- <div class="percentage">
                <span :class="false ? 'text-danger' : 'text-success'">
                  {{seller.percent}}
                  -45%
                </span>
              </div> -->

              <div class="divide"></div>

              <div class="items text-center">
                <span class="items">{{ seller.sold }}</span> <br />

                <span>Items</span>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    await this.getNfts();
  },
  data() {
    return {
      topSellers: [],
      noSellers: false,
    };

  },
  methods: {
    async getNfts() {
      // const web3 = await new Web3(window.ethereum);
      // web3
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-top-sellers`;
      await this.$axios
        .get(url)
        .then((res) => {
          let nftsData = res.data;

         nftsData.forEach((nft) => {
            this.topSellers.push({
              id: nft.id,
              name: nft.name,
              email: nft.email,
              photo: nft.photo,
              address: nft.address,
              sold: nft.sold,
            });
          });

           if (nftsData.length == 0) {
            this.noSellers = true;
          } else {
            this.noSellers = false;
          }
          res
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>

<style lang="scss">
.topSellers {
   display: flex;
  flex-direction: column;
  margin: 70px 0px 100px 0px;
  padding: 0 80px 0 80px;
  overflow: hidden;
}

.topSellers .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 45px;
}

.topSellers .heading h4 {
  font-size: 30px;
  font-family: "Bold";
}

.topSellers .heading button {
  font-size: 18px;
  font-family: "Regular";
  // border: 2px solid #1d2034;
  background-color: transparent;
  color: #1d2034;
  border-radius: 20px;
  border: none;
}

.topSellers .list-group-item .loader {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.topSellers .list-group-item .b-aspect {
  height: 90px;
  margin-bottom: 20px;
}
.topSellers .list-group-item .b-skeleton-img {
  width: 350px;
  height: 90px;
  margin-left: 30px;
  border-radius: 20px !important;
}
.topSellers .list-group-item .noSeller {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}
.topSellers .list-group {
  flex-direction: row;
  flex-wrap: wrap;
}

.topSellers .list-group-item {
  min-width: 23%;
  height: 90px;
  overflow: hidden;
  padding: 0px;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 0 0 30px 20px;
  cursor: pointer;
}
.topSellers .list-group-item .seller {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 90px;
}

.topSellers .list-group-item .seller .divide {
  height: 60px;
  border: 1px solid #1d2034;
}
.topSellers .list-group-item img {
  width: 67px;
  height: 67px;
  border-radius: 30px;
}

.topSellers .list-group-item .seller .nameAndPrice,
.topSellers .list-group-item .seller .percentage,
.topSellers .list-group-item .seller .items .items {
  font-size: 16px;
  font-family: "SemiBold";
}

.topSellers .list-group-item .seller .items .items {
  font-size: 20px;
}

.topSellers .list-group-item .seller .items span:last-child {
  font-size: 14px;
  font-family: "light";
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .topSellers {
    padding: 0 0px 0 0px;
  }

  .topSellers .heading {
    padding: 5px;
  }

  .topSellers .heading h4 {
    font-size: 18px;
  }

  .topSellers .heading button {
    font-size: 16px;
    height: 40px;
  }

  .topSellers .topSeller {
    padding: 10px;
  }

  .topSellers .list-group {
    justify-content: center;
  }

  .topSellers .list-group-item {
    width: 100%;
    height: 70px;
    margin: 0 0 20px 0px;
  }
  .topSellers .list-group-item .seller {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 70px;
  }

  .topSellers .list-group-item .seller .divide {
    height: 50px;
    border: 1px solid #1d2034;
  }
  .topSellers .list-group-item img {
    width: 50px;
    height: 50px;
    border-radius: 30px;
  }

  .topSellers .list-group-item .seller .nameAndPrice,
  .topSellers .list-group-item .seller .percentage,
  .topSellers .list-group-item .seller .items .items {
    font-size: 16px;
    font-family: "SemiBold";
  }

  .topSellers .list-group-item .seller .items .items {
    font-size: 16px;
  }

  .topSellers .list-group-item .seller .items span:last-child {
    font-size: 14px;
    font-family: "light";
  }
}
</style>
