<template>
  <div>
    <div class="searchNft d-flex justify-content-center mt-3">
      <b-input-group >
        <template #append>
          <b-input-group-text>
            <b-button>
              <i class="fas fa-search text-dark "></i>
            </b-button>
          </b-input-group-text>
        </template>
        <b-form-input placeholder="Search" v-model="search"></b-form-input>
      </b-input-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style lang="scss">
.searchNft .input-group {
  width: 492px;

  border-radius: 20px;
}

.searchNft .input-group input {
  height: 50px;
  border-radius: 20px;
  border-right-style: none;
}

.searchNft .input-group .input-group-text button {
  background-color: transparent;
  border: none;
}

.searchNft .input-group input::placeholder {
  font-family: "Bold";
  font-size: 30px;
  color: #b5b5b5;
}

.searchNft .input-group .input-group-text {
  //    border: 1px solid red;
  border-radius: 20px;
  background-color: #ffffff;
  border-left-style: none;
}

.searchNft .input-group .input-group-text i {
  font-size: 30px;
}

.searchNft .input-group .input-group-append {
  height: 50px;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
    .searchNft {
        padding: 10px;
    }
  .searchNft .input-group {
    width: 100%;
  }

  .searchNft .input-group input {
    height: 40px;
  }
  .searchNft .input-group .input-group-text i {
  font-size: 20px;
}
  .searchNft .input-group input::placeholder {
    font-size: 18px;
  }

  .searchNft .input-group .input-group-append {
    height: 40px;
  }
}
</style>
