<template>
  <div>
    <div class="notFound">
      <h3>{{ msg }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      this.msg = "Redirecting to main page....";
      this.redirect();
    }, 5000);
  },
  data() {
    return {
      msg: "Page Not Found!",
    };
  },
  methods: {
    redirect() {
      setTimeout(() => {
        this.$router.push({ path: "/" });
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.notFound {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-image: url("https://cdn.vox-cdn.com/thumbor/IyhdGiOSYO_g1vUvKZTpv_6F2kk=/1400x788/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22397464/VRG_ILLO_4489_003.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

.notFound h3 {
  font-size: 30px;
  font-family: "Bold";
  letter-spacing: 3px;
  color: #d8b871;
}
</style>
