<template>
  <div>
    <div class="notableArt allNfts">
      <b-list-group id="marketAssets">
        <b-list-group-item
          style="width: 100%; height: auto; box-shadow: none; border: none"
          v-if="allNfts.length == 0 && !noNftsFound"
          class="loader"
        >
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="allNfts.length == 0 && noNftsFound"
          style="width: 100%; height: auto; box-shadow: none; border: none"
        >
          <div class="noNft">No Data Found!</div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(art, artIndex) in allNfts.length > 12
            ? getAssetRows
            : allNfts"
          :key="artIndex"
          v-else
        >
          <div data-aos="zoom-in" data-aos-duration="2000">
            <img :src="art.image" c alt="" style="position: relative" />
            <span
              style="
                position: absolute;
                left: 10px;
                top: 10px;
                background-color: #d8b871;
                color: #ffffff;
                padding: 2px;
                border-radius: 50%;
                fontfamily: Bold;
                fontsize: 14px;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-if="
                account != null &&
                account.toLowerCase() != art.address.toLowerCase() &&
                $store.getters.getAsset.assetLinks[art.ipfs_link]
              "
              >{{
                $store.getters.getAsset.assetLinks[art.ipfs_link].count
              }}</span
            >

            <div class="info ">
              <div>
                <span>{{ art.name }}</span>

              </div>

              <div class="d-flex justify-content-between">
                <span
                  >Owned By:
                  <span class="font-weight-bolder">{{
                    art.username
                  }}</span></span
                >
                <span
                  >{{ art.price }}
                  <span>{{ checkCurrency(art.currency) }}</span></span
                >
              </div>

              <b-button
                v-if="
                  account != null &&
                  account.toLowerCase() != art.address.toLowerCase() &&
                  !$store.getters.getAsset.assetLinks[art.ipfs_link]
                "
                block
                @click="goToNftPage(art.id, art.auction)"
                >{{ art.auction ? "Place Bid" : "Buy Asset" }}</b-button
              >
              <b-button
                v-else-if="
                  account != null &&
                  account.toLowerCase() != art.address.toLowerCase() &&
                  $store.getters.getAsset.assetLinks[art.ipfs_link]
                "
                block
                @click="getNftCopies(art)"
              >
                Buy Asset
              </b-button>
              <b-button v-else-if="account == null" block disabled
                >Connect Wallet</b-button
              >
              <b-button v-else disabled block>Already Owned</b-button>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>

      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="marketAssets"
        align="center"
        size="md"
        v-if="allNfts.length > 12"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["nfts", "noNftsFound", "account", "nftsObject"],
  async mounted() {
    await this.nfts;
    await this.nftsObject;
    for (let nft in this.nftsObject) {
      await this.allNfts.push(this.nftsObject[nft]);
    }

    this.rows = this.allNfts.length;
  },
  data() {
    return {
      currentPage: 1,
      rows: "",
      perPage: 12,
      allNfts: [],
    };
  },
  computed: {
    getAssetRows() {
      return this.allNfts.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    goToNftPage(nftId, auction) {
      if (!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({ name: "Profile" });
      }
      this.$router.push({
        name: auction ? "AuctionNft" : "BuyNft",
        params: {
          nftId: btoa(nftId),
        },
      });
    },
    getNftCopies(asset) {
      if (!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({ name: "Profile" });
      }
      this.$router.push({
        name: "NftCopies",
        params: {
          nftName: asset.name,
          nftId: btoa(asset.ipfs_link),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.notableArt .pagination .page-item .page-link {
  background-color: #d8b871;
  color: #ffffff;
  border: 1px solid white;
  font-size: 14px;
  font-family: "SemiBold";
  box-shadow: none;
}

.notableArt .pagination .active .page-link {
  color: #d8b871;
  background-color: #ffffff;
}
</style>
