<template>
  <div>
    <div class="adminNfts">
      <b-skeleton-table
        v-if="fetchData"
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <div class="table-responsive" ref="table" :style="getTableStyle" v-else>
        <table class="table table-bordered table-striped" id="users">
          <thead class="thead-light">
            <tr>
              <th class="align-middle">ID</th>
              <th class="align-middle">Name</th>
              <th class="align-middle">Email</th>
              <th class="align-middle">Photo</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(nft, nftIndex) in nfts.length > 5 ? getAssetRows : nfts"
              :key="nftIndex"
              class="bg-white"
            >
              <td class="align-middle">{{ nft.id }}</td>
              <td class="align-middle">
                <span :id="'asset' + nftIndex" class="text-info">{{
                  nft.name
                }}</span>
                <b-popover
                  :target="'asset' + nftIndex"
                  triggers="hover"
                  placement="topright"
                >

                    <p style="word-break: break-all;">Address: {{ nft.address }}</p>

                </b-popover>
              </td>
              <td class="align-middle">
                  <a :href="'mailto:'+ nft.email">{{nft.email}}</a>
              </td>
              <td class="align-middle">
                  <img :src="nft.photo" style="width: 70px; height: 70px"  alt="" class="img-fluid rounded-circle">
              </td>

            </tr>
          </tbody>
        </table>
      </div>
       <b-pagination
            class="mt-2"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="users"
            align="center"
            size="md"
            v-if="nfts.length > 5"
          ></b-pagination>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
export default {
  async created() {
    this.fetchData = true;
    await this.getNfts();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      nfts: [],
       currentPage: 1,
      rows: "",
      perPage: 5,
    };
  },
  computed: {
    getTableStyle() {
      if(this.$refs.table && this.$refs.table.clientHeight > 400) {
        return {height: 400+'px', overflowY: 'scroll'}
      } else {
        return ''
      }
    },
     getAssetRows() {
      return this.nfts.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    async getNfts() {
      const web3 = await new Web3(window.ethereum);
      web3
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-users`;
      await this.$axios
        .get(url)
        .then((res) => {
          let nftsData = res.data;

          nftsData.forEach((nft) => {
            this.nfts.push({
              id: nft.id,
              name: nft.name,
              email: nft.email,
              photo: nft.photo,
              address: nft.address
            });
          });
          this.rows = this.nfts.length;
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>

<style lang="scss">
.adminNfts .table-responsive {
    border-radius: 10px !important;
}
.adminNfts table tbody tr td {
  font-size: 16px;
  font-family: "SemiBold" !important;
}
</style>
