<template>
  <div id="app">
    <div class="loadScreen bg-secondary" v-if="loadData">
      <div class="text-center">
        <div class="d-flex align-items-center">
          <img
            :src="require('@/assets/images/MetamaskW.svg')"
            alt=""
            class="img-fluid"
          />
          <p class="ml-3">{{ loadingText }}</p>
        </div>

        <div class="loader"></div>
      </div>
    </div>
    <div v-else>
      <Navbar
        @connectMetamask="connectMetamaskAccount"
        @disconnectWallet="disconnectWallet()"
      />
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script>
// import store from "./store/index.js";
import Navbar from "./components/Navigation/Navbar.vue";
import Footer from "./components/Navigation/Footer.vue";
import Metamask from "./components/ConnectWallet/ConnectMetamask.js";
export default {
  mounted() {
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        accounts;
        this.$router.push({ path: "/" });
        window.location.reload();
      });
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
        this.loadingText = "Switch Network...";

        chainId;
      });
      window.ethereum.on("connect", (connect) => {
        connect;
      });
    }
  },
  async created() {
    this.loadingText = "Checking Metamask...";
    this.loadData = true;
    if (localStorage.getItem("MetamaskConnected")) {
      await this.connectMetamaskAccount();
    }

    this.loadData = false;
  },
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      metaAccount: {},
      theAccount: null,
      loadData: false,
      status: false,
      userInfoExist: false,
      loadingText: "Checking Metamask.......",
    };
  },
  methods: {
    disconnectWallet() {
      this.loadingText = "Disconnecting Wallet...";
      this.loadData = true;
      setTimeout(() => {
        this.$router.push({ path: "/" });
        window.location.reload();
        localStorage.removeItem("MetamaskConnected");
      }, 3000);
    },
    async connectMetamaskAccount() {
      this.loadData = true;

      const checkRinkyConnection = await this.checkMetamaskNetwork();

      if (!checkRinkyConnection || checkRinkyConnection == undefined) {
        this.$toast.error("Unable to connect to metamask!", {
          position: "top",
          duration: 3000,
        });
        return (this.loadData = false);
      }
      this.loadingText = "Connecting Metamask...";
      const checkMetamaskConnection = await Metamask.connectMetamask();

      if (checkMetamaskConnection.status) {
        this.$toast.success("Metamask Connected", {
          position: "top",
          duration: 3000,
        });
        localStorage.setItem("MetamaskConnected", true);
        this.theAccount = await checkMetamaskConnection.account;
        this.metaAccount = await this.$store.getters.getMetamaskAccount
          .metamaskAccount;

        this.loadData = await false;
        await this.checkUserInfoExist();
        await this.getCurrentRoyalty();
        if (!this.userInfoExist) {
          await this.$router.push({ name: "Profile" });
          this.$toast.error("Please submit your details", {
            position: "top",
            duration: 3000,
          });
        }
      }
      if (!checkMetamaskConnection.status) {
        if (checkMetamaskConnection.status.user_allowed) {
          this.$toast.error("Please allow metamask to connect", {
            position: "top",
            duration: 3000,
          });
        } else {
          this.$toast.error("Please Install Metamask", {
            position: "top",
            duration: 3000,
          });
          setTimeout(() => {
            window.open("https://metamask.io/download.html", "_blank").focus();
          }, 3000);
        }
      }

      this.loadData = false;
      //  this.$bvModal.show("internetConnection");
    },
    async checkMetamaskNetwork() {
      this.loadingText = "Checking Metamask Network....";
      let connection = false;
      if (typeof window.ethereum !== "undefined") {
        const rinkbyNetwork = window.ethereum.networkVersion;
        if (Number(rinkbyNetwork) != 56) {
          const customRpc = this.$store.getters.getCustomRpc.customRpc;

          try {
            await window.ethereum
              .request({
                method: "wallet_addEthereumChain",
                // params: customRpc,
                params: customRpc,
              })
              .then((res) => {
                alert(
                  "Network Switched Successfully, Please Connect Wallet Now"
                );

                connection = true;
                res;
              })
              .catch((error) => {
                if (error.code == -32002) {
                  alert(
                    `The Request is pending in metamask, please accept it & refresh`
                  );
                }
              });
          } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
              try {
                window.ethereum.request({
                  method: "wallet_addEthereumChain",
                  // params: customRpc,
                  params: customRpc,
                });
              } catch (addError) {
                // handle "add" error
                addError;
              }
            }
            switchError;
            // handle other "switch" errors
          }
        } else if (Number(rinkbyNetwork) == 56) {
          connection = true;
        } else {
          connection = false;
        }
      }
      return connection;
    },
    async getCurrentRoyalty() {
      const contract = await this.$store.getters.getSmartContractStructure
        .smartContract.contract;
      let getRoyalty = await contract.methods
        .maxRoyalty()
        .call()
        .then((res) => {
          return res;
        });
      this.$store.state.metamaskAccount.royalty = getRoyalty;
    },
    async handleMetamaskChageId() {
      await this.connectMetamaskAccount();
    },
    async checkUserInfoExist() {
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-user-details`;
      await this.$axios
        .post(url, { address: this.metaAccount.account })
        .then((response) => {
          if (response.data.exist) {
            this.userInfoExist = response.data.exist;
            this.$store.state.user.exist = response.data.exist;
          }

          if (response.data.record) {
            this.$userExist = true;

            this.$store.state.user = {
              exist: true,
              name: response.data.record.name,
              email: response.data.record.email,
              image: response.data.record.photo,
            };
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Regular";
  src: url("~@/assets/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Bold";
  src: url("~@/assets/fonts/SourceSansPro-Bold.ttf");
}

@font-face {
  font-family: "SemiBold";
  src: url("~@/assets/fonts/SourceSansPro-SemiBold.ttf");
}

@font-face {
  font-family: "Light";
  src: url("~@/assets/fonts/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: "ItalicBold";
  src: url("~@/assets/fonts/SourceSansPro-BoldItalic.ttf");
}

body {
  margin: 0px;
  overflow-x: hidden;
}

.form-group-error {
  border-color: #f79483 !important;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  // border-radius: 0.25rem;
}
.input-error-message {
  font-size: 12px;
  font-weight: 900;
  color: #f57f6c;
  margin-top: 0px !important;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

//Loader

.loadScreen {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadScreen img {
  animation: metamaskAnimation 1s ease-in-out infinite alternate;
}

@keyframes metamaskAnimation {
  from {
    transform: rotate(-20deg);
  }
  to {
    transform: rotate(20deg);
  }
}

.loadScreen p {
  font-size: 24px;
  font-family: "Bold";
  color: #d8b871;
}

.loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  padding: 0px;
  opacity: 0.5;
  border: 3px solid #d8b871;
  -webkit-animation: loader 1s ease-in-out infinite alternate;
  animation: loader 1s ease-in-out infinite alternate;
}

.loader:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #d8b871;
}

.loader:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid #d8b871;
}

@keyframes loader {
  from {
    transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }
  to {
    transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}
@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }
  to {
    -webkit-transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}
</style>
