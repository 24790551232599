<template>
  <div>
    <div class="featuredNfts mt-3 mb-2">
      <div class="heading">
        <h4>NFTS</h4>
      </div>
      <div class="notableNfts mt-2">
        <b-list-group>
          <b-list-group-item
            style="width: 100%; height: auto; box-shadow: none; border: none"
            v-if="featuredNfts.length == 0 && !noNftsFound"
          >
            <div class="loader w-100">
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
            </div>
          </b-list-group-item>
          <b-list-group-item
            v-else-if="featuredNfts.length == 0 && noNftsFound"
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="noNft">No Data Found!</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(art, artIndex) in featuredNfts"
            :key="artIndex"
            v-else
          >
            <div data-aos="zoom-in" data-aos-duration="2000">
              <img :src="art.image" c alt="" />
              <div class="info">
                <div class="d-flex justify-content-between">
                  <span>{{ art.name }}</span>
                  <!-- <span>
                  <i class="far fa-heart mr-1" v-if="!art.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i>
                  {{ art.likes }}
                </span> -->
                </div>
                <div class="d-flex justify-content-between">
                  <span
                    >Owned By:
                    <span class="font-weight-bolder">{{
                      art.username
                    }}</span></span
                  >
                  <span
                    >{{ convertToNormalPrice(art) }}
                    <span>{{ checkCurrency(art.currency) }}</span></span
                  >
                </div>
                <b-button v-if="account == null" block disabled
                  >Connect Wallet</b-button
                >

                <b-button
                  v-else-if="account.toLowerCase() != art.address.toLowerCase()"
                  block
                  @click="goToNftPage(art.id, art.auction)"
                  >{{ art.auction ? "Place Bid" : "Buy Asset" }}</b-button
                >

                <b-button v-else disabled block>Already Owned</b-button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
export default {
  async created() {
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    await this.getNftCopies();
  },
  data() {
    return {
      account: null,
      featuredNfts: [],
      noNftsFound: false,
    };
  },
  methods: {
    async getNftCopies() {
      const ipfsLink = atob(this.$route.params.nftId);
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-nfts-from-ipfs-link`;
      await this.$axios.post(url, { ipfs_link: ipfsLink }).then((res) => {
        const copies = res.data;
        if (copies.length == 0) {
          this.noNftsFound = true;
        } else {
          this.noNftsFound = false;
          this.featuredNfts = copies;
        }
      });
    },
    convertToNormalPrice(asset) {
      if (Number(asset.price) == 0) {
        return 0;
      } else {
        let UpdatedPrice =
          asset.auction == 0
            ? web3.utils.fromWei(asset.price.toString(), "Ether")
            : (asset.price / 10 ** 18).toString();

        return UpdatedPrice;
      }
    },
    goToNftPage(nftId, auction) {
      this.$router.push({
        name: auction ? "AuctionNft" : "BuyNft",
        params: {
          nftId: btoa(nftId),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//Featured
.featuredNfts {
  display: flex;
  flex-direction: column;
  margin: 70px 0 100px 0;
  padding: 0 80px 0 80px;
  // overflow: hidden;
}

.featuredNfts .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 45px;
}

.featuredNfts .heading h4 {
  font-size: 30px;
  font-family: "Bold";
}

.featuredNfts .heading button {
  font-size: 18px;
  font-family: "Regular";
  // border: 2px solid #1d2034;
  background-color: transparent;
  color: #1d2034;
  border-radius: 20px;
  border: none;
}

.featuredNfts .list-group-item .noNft {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.featuredNfts .list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.featuredNfts .list-group-item {
  min-width: 23%;
  max-width: 23%;
  height: 350px;
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px;
}

.featuredNfts .list-group-item.loader {
  width: 100%;
  min-width: none;
  max-width: none;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  border: none;
  box-shadow: none;
}

.featuredNfts .list-group-item.loader .b-skeleton-img {
  width: 295px;
  height: 410px;
  margin: auto;
  margin-left: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.featuredNfts .list-group-item img {
  width: 100%;
  height: 200px;
}

.featuredNfts .list-group-item .info {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Regular";
}

.featuredNfts .list-group-item .info button {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .homepageBg {
    width: 100%;
    background-image: url("~@/assets/images/homepageBg.svg");
    background-size: cover;
    // background-color: #FFFFFF;
    height: auto;
    // background-blend-mode: darken;
    overflow: hidden;
  }

  .homepageBg h4 {
    font-size: 18px;
  }

  .homepageBg h6 {
    font-size: 16px;
  }

  .homepageBg .homepageBgBtns .start,
  .homepageBg .homepageBgBtns .learn {
    width: 100%;
    height: 40px;
    font-size: 16px;
  }

  .homepageBg .homepageBgBtns .learn {
    margin-left: 0px;
    margin-top: 10px;
  }

  //Featured
  .featuredNfts {
    padding: 0 10px 0 10px;
  }

  .featuredNfts .heading {
    padding: 5px;
  }

  .featuredNfts .heading h4 {
    font-size: 18px;
  }

  .featuredNfts .heading button {
    height: 40px;
    font-size: 16px;
  }

  .featuredNfts .notableNfts {
    padding: 10px;
  }

  .featuredNfts .list-group {
    justify-content: center;
  }

  .featuredNfts .list-group-item {
    min-width: 100%;
    max-width: 100%;
  }

  .featuredNfts .list-group-item img {
    width: 100%;
    height: 200px;
  }

  .featuredNfts .list-group-item .info {
    font-size: 16px;
  }

  .featuredNfts .list-group-item .loader {
    display: block;
    flex-wrap: none;
  }

  .featuredNfts .list-group-item .b-skeleton-img {
    width: 100%;
    height: 410px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
</style>
