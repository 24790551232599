<template>
  <div>
    <div class="notableArt catogoryNft">
      <b-list-group>
        <b-list-group-item
          style="width: 100%; height: auto; box-shadow: none; border: none"
          v-if="auctionedNfts.length == 0 && !loadAuction"
        >
          <div class="loader w-100">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="auctionedNfts.length == 0 && loadAuction"
          style="width: 100%; height: auto; box-shadow: none; border: none"
        >
          <div class="noNft">No Categories Listed Yet!</div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(art, artIndex) in auctionedNfts"
          :key="artIndex"
          v-else
        >
          <img :src="art.image" c alt="" />
          <div class="info">
            <div class="d-flex justify-content-between">
              <span>{{ art.name }}</span>
              <!-- <span>
                  <i class="far fa-heart mr-1" v-if="!art.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i>
                  {{ art.likes }}
                </span> -->
            </div>

            <div class="d-flex justify-content-between">
              <span
                >Owned By:
                <span class="font-weight-bolder">{{ art.username }}</span></span
              >
              <span>{{ art.price }} <span>{{checkCurrency(art.currency)}}</span></span>
            </div>
          <b-button
              v-if=" account != null && account.toLowerCase() != art.address.toLowerCase()"
              block
              @click="goToNftPage(art.id, art.auction)"
              >{{ art.auction ? "Place Bid" : "Buy Asset" }}</b-button
            >
             <b-button
                  v-else-if="
                  account == null
                  "
                  block
                  disabled

                  >Connect Wallet</b-button
                >
            <b-button v-else disabled block>Already Owned</b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
export default {
  props: ["nfts", "noNftsFound", "account"],
  async created() {
    await this.nfts;
    await this.nfts.forEach((auction) => {
      if (auction.collection) {
        this.auctionedNfts.push(auction);
      }
    });
    if (this.auctionedNfts.length == 0) {
      this.loadAuction = true;
    } else {
      this.loadAuction = false;
    }
  },
  data() {
    return {
      auctionedNfts: [],
      loadAuction: false,
    };
  },
  methods: {},
};
</script>

