import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import SampleData from "./components/SampleData.js";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import axios from "axios";
// import VueCountdown from "@chenfengyuan/vue-countdown";
import moment from "moment";
import Share from "./components/Share/Share.vue"

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import AOS from 'aos'
import 'aos/dist/aos.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueToast);
Vue.prototype.$axios = axios;

if (process.env.NODE_ENV === "production") {
  Vue.config.devtools = false;
}

// Vue.component(VueCountdown.name, VueCountdown);

Vue.prototype.$sampleData = SampleData;
Vue.prototype.$moment = moment;
Vue.prototype.$userExist = store.getters.getMetamaskAccount.user.exist;
// console.log(store.getters.getMetamaskAccount.user.exist);
Vue.config.productionTip = false;
Vue.component("Share", Share);

Vue.mixin({
  methods: {
    syncMetamaskWithApi: async function () {
      const url =
        "https://us-central1-everestcoin.cloudfunctions.net/sync-events";
      await axios
        .get(url)
        .then((res) => {
          res;
        })
        .catch((e) => {
          e;
        });
    },
    checkCurrency: function (currency) {
      if (currency == "0x0000000000000000000000000000000000000000") {
        return "BNB";
      } else {
        return "USDT";
      }
    },
    // decryptAPIResponse: async function(apiResponse) {

    //   const decryptFromAPI  = await CryptoJS.AES.decrypt(apiResponse, key);

    //   const result = await JSON.parse(decryptFromAPI.toString(CryptoJS.enc.Utf8));

    //   return result
    // }
  },
});

new Vue({
  router,
  store,
  mounted() {
    AOS.init()
  },
  render: (h) => h(App),
}).$mount("#app");
