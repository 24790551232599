<template>
  <div>
    <div class="details">
      <div v-if="fetchData">
        <div>
        <b-skeleton animation="throb" width="100%"></b-skeleton>
        <b-skeleton animation="throb" width="100%"></b-skeleton>
        <b-skeleton animation="throb" width="100%"></b-skeleton>
        </div>

        <div class="mt-2">
        <b-skeleton animation="throb" width="100%"></b-skeleton>
        <b-skeleton animation="throb" width="100%"></b-skeleton>
        <b-skeleton animation="throb" width="100%"></b-skeleton>
        </div>
      </div>
      <div v-else>
        <div class="owner">
          <img :src="asset.photo" class="rounded-circle img-fluid" alt="" />
          <div class="ml-2">
            <div class="name">
              Owner: <span>{{ asset.username }}</span>
            </div>
            <p class="address">
              Address: <span>{{ asset.address }}</span>
            </p>
          </div>
        </div>
        <div class="owner">
          <img
            :src="creatorInfo.photo"
            class="rounded-circle img-fluid"
            alt=""
          />
          <div class="ml-2">
            <div class="name">
              Creator: <span>{{ creatorInfo.name }}</span>
            </div>
            <p class="address">
              Address: <span>{{ creatorInfo.address }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["creator", "asset"],
  async created() {
    this.fetchData = true;
    await this.getCreatorDetails();
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      creatorInfo: {},
    };
  },
  methods: {
    async getCreatorDetails() {
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-user-details`;
      await this.$axios
        .post(url, { address: this.creator })
        .then((response) => {
          if (response.data.record) {
            this.creatorInfo = {
              name: response.data.record.name,
              email: response.data.record.email,
              photo: response.data.record.photo,
              address: response.data.record.address,
            };
          }
          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.details .owner {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #d8b871;
  margin-bottom: 5px;
  display: flex;
  width: 500px;
  align-items: center;
}
.details .owner img {
  width: 70px;
  height: 70px;
  border-radius: 2px solid #d8b871;
}
.details .owner .name,
.details .owner .address {
  font-size: 18px;
  font-family: "Regular";
  word-break: break-all;
}
.details .owner .name span,
.details .owner .address span {
  font-family: "SemiBold";
}
@media only screen and (max-width: 600px) and (orientation: portrait) {
  .details .owner {
    padding: 5px;
  width: 100%;

}
.details .owner img {
  width: 50px;
  height: 50px;

}
.details .owner .name,
.details .owner .address {
  font-size: 14px;

}

}
</style>
