<template>
  <div>
    <div class="collectonsNfts">
      <b-list-group>
        <b-list-group-item
          style="width: 100%; height: auto; box-shadow: none; border: none"
          v-if="auctionedNfts.length == 0 && !loadAuction"
        >
          <div class="loader w-100">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="auctionedNfts.length == 0 && loadAuction"
          style="width: 100%; height: auto; box-shadow: none; border: none"
        >
          <div class="noNft">No NFTs Found!</div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(art, artIndex) in auctionedNfts"
          :key="artIndex"
          v-else
        >
          <img :src="art.image" c alt="" />
          <div class="info">
            <div class="d-flex justify-content-between">
              <span>{{ art.name }}</span>
              <!-- <span>
                  <i class="far fa-heart mr-1" v-if="!art.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i>
                  {{ art.likes }}
                </span> -->
            </div>

            <div class="d-flex justify-content-between">
              <span
                >Owned By:
                <span class="font-weight-bolder">{{ art.username }}</span></span
              >
              <span
                >{{ art.price }}
                <span>{{ checkCurrency(art.currency) }}</span></span
              >
            </div>
           <b-button
                  v-if="
                   account != null &&
                    account.toLowerCase() != art.address.toLowerCase() &&
                    !$store.getters.getAsset.assetLinks[art.ipfs_link]
                  "
                  block
                  @click="goToNftPage(art.id, art.auction)"
                  >{{ art.auction ? "Place Bid" : "Buy Asset" }}</b-button
                >
                <b-button
                  v-else-if="
                   account != null &&
                    account.toLowerCase() != art.address.toLowerCase() &&
                    $store.getters.getAsset.assetLinks[art.ipfs_link]
                  "
                  block
                  @click="getNftCopies(art)"
                >
                  View({{
                    $store.getters.getAsset.assetLinks[art.ipfs_link].count
                  }})
                </b-button>
                 <b-button
                  v-else-if="
                  account == null
                  "
                  block
                  disabled

                  >Connect Wallet</b-button
                >
                <b-button v-else disabled block>Already Owned</b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
export default {
  async created() {
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    await this.getNftBasedOnCollections();
  },
  data() {
    return {
      account: null,
      auctionedNfts: [],
      loadAuction: false,
    };
  },
  methods: {
    async getNftBasedOnCollections() {
      const collectionId = atob(this.$route.params.collectionId);
      const url =
        "https://us-central1-everestcoin.cloudfunctions.net/get-nfts-for-collection";
      await this.$axios
        .post(url, { collection: collectionId })
        .then((response) => {
          const nftsData = response.data;
          if (nftsData.length == 0) {
            this.loadAuction = true;
          } else {
            this.loadAuction = false;
          }
          nftsData.forEach((nft) => {
            this.auctionedNfts.push({
              id: nft.id,
              name: nft.name,
              description: nft.description,
              ipfs_link: nft.ipfs_link,
              address: nft.address,
              price: web3.utils.fromWei(nft.price.toString(), "Ether"),
              created_at: nft.created_at,
              image: nft.image,
              auction: nft.auction == 1 ? true : false,
              selling: nft.selling == 1 ? true : false,
              end_time: nft.end_time,
              bidder: nft.bidder,
              creator: nft.creator,
              currency: nft.currency,
              photo: nft.photo,
              username: nft.username,
              featured: nft.featured == 1 ? true : false,
            });
          });
          response;
        })
        .catch((err) => {
          err;
        });
    },
    goToNftPage(nftId, auction) {
       if(!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({name: "Profile"});
      }
      this.$router.push({
        name: auction ? "AuctionNft" : "BuyNft",
        params: {
          nftId: btoa(nftId),
        },
      });
    },
    getNftCopies(asset) {
       if(!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({name: "Profile"});
      }
      this.$router.push({
        name: "NftCopies",
        params: {
          nftName: asset.name,
          nftId: btoa(asset.ipfs_link),
        },
      });
    },
  },
};
</script>

<style lang="scss">

//Featured
.collectonsNfts {
  display: flex;
  flex-direction: column;
  margin: 70px 0 100px 0;
  padding: 0 80px 0 80px;
  overflow: hidden;
}

.collectonsNfts .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 45px;
}

.collectonsNfts .heading h4 {
  font-size: 30px;
  font-family: "Bold";
}

.collectonsNfts .heading button {
  font-size: 18px;
  font-family: "Regular";
  // border: 2px solid #1d2034;
  background-color: transparent;
  color: #1d2034;
  border-radius: 20px;
  border: none;
}

.collectonsNfts .list-group-item .noNft {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.collectonsNfts .list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.collectonsNfts .list-group-item {
  min-width: 23%;
  max-width: 23%;
  height: 350px;
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px;
}

.collectonsNfts .list-group-item.loader {
  width: 100%;
  min-width: none;
  max-width: none;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  border: none;
  box-shadow: none;
}

.collectonsNfts .list-group-item.loader .b-skeleton-img {
  width: 295px;
  height: 410px;
  margin: auto;
  margin-left: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.collectonsNfts .list-group-item img {
  width: 100%;
  height: 200px;
}

.collectonsNfts .list-group-item .info {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Regular";
}

.collectonsNfts .list-group-item .info button {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
.homepageBg {

  height: 200px;
  padding: 10px;

}

.homepageBg .col-md-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepageBg .col-md-12 img {
  display: none;
}

  .homepageBg h4 {
    font-size: 18px;
  }

  .homepageBg h6 {
    font-size: 16px;
  }

  .homepageBg .homepageBgBtns .start,
  .homepageBg .homepageBgBtns .learn {
    width: 100%;
    height: 40px;
    font-size: 16px;
  }

  .homepageBg .homepageBgBtns .learn {
    margin-left: 0px;
    margin-top: 10px;
  }

  //Featured
  .collectonsNfts {
    padding: 0 10px 0 10px;
  }

  .collectonsNfts .heading {
    padding: 5px;
  }

  .collectonsNfts .heading h4 {
    font-size: 18px;
  }

  .collectonsNfts .heading button {
    height: 40px;
    font-size: 16px;
  }

  .collectonsNfts .notableNfts {
    padding: 10px;
  }

  .collectonsNfts .list-group {
    justify-content: center;
  }

  .collectonsNfts .list-group-item {
    min-width: 100%;
    max-width: 100%;
  }

  .collectonsNfts .list-group-item img {
    width: 100%;
    height: 200px;
  }

  .collectonsNfts .list-group-item .info {
    font-size: 16px;
  }

  .collectonsNfts .list-group-item .loader {
    display: block;
    flex-wrap: none;
  }

  .collectonsNfts .list-group-item .b-skeleton-img {
    width: 100%;
    height: 410px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
</style>
