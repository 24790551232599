<template>
  <div>
    <div class="adminPanel">
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <h4><i class="fas fa-tachometer-alt mr-2"></i>Admin Panel</h4>
            <img
              :src="
                $store.getters.getMetamaskAccount.user
                  ? $store.getters.getMetamaskAccount.user.image
                  : 'https://monstar-lab.com/global/wp-content/uploads/sites/11/2019/04/male-placeholder-image.jpeg'
              "
              alt="img"
              class="img-fluid rounded-circle"
            />
          </b-col>
          <b-col md="3">
            <b-list-group>
              <b-list-group-item
                v-for="(admin, adminIndex) in adminsOptions"
                :key="adminIndex"
                @click="switchAdminOption(admin.component, adminIndex)"
                :active="activeComponent == adminIndex ? true : false"
              >
                {{ admin.name }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="9">
            <component
              :contract="contract"
              :account="account"
              :is="currentComponent"
            ></component>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Nfts from "./AdminOptions/Nfts.vue";
import Users from "./AdminOptions/Users.vue";
import AddMinter from "./AdminOptions/AddMinter.vue";
import UpdateRoyalty from "./AdminOptions/UpdateRoyalty.vue";
export default {
  async created() {
    this.metaAccount = await this.$store.getters.getMetamaskAccount;
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;

    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    let getOwner = await this.getContractOwner();
    if (getOwner.toLowerCase() == this.account.toLowerCase()) {
      this.adminsOptions.push({
        name: "Update Royalty",
        component: "UpdateRoyalty",
      });
    }
  },
  components: {
    Nfts,
    Users,
    AddMinter,
    UpdateRoyalty,
  },
  data() {
    return {
      contract: null,
      account: null,
      metaAccount: null,
      activeComponent: 0,
      currentComponent: "Nfts",
      adminsOptions: [
        {
          name: "NFTs",
          component: "Nfts",
        },
        {
          name: "Users",
          component: "Users",
        },
        {
          name: "Add Minter",
          component: "AddMinter",
        },
      ],
    };
  },
  methods: {
    async getContractOwner() {
      let getOwner = await this.contract.methods
        ._contractOwner()
        .call()
        .then((res) => {
          return res;
        });
      return getOwner;
    },
    switchAdminOption(component, adminIndex) {
      this.currentComponent = component;
      this.activeComponent = adminIndex;
    },
  },
};
</script>

<style lang="scss">
.adminPanel {
  min-height: 100vh;
  background-color: #1d2034;
  padding: 10px;
}
.adminPanel .col-md-12 img {
  width: 50px;
  height: 50px;
}
.adminPanel .col-md-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-family: "Bold";
  font-size: 28px;
  letter-spacing: 2px;
  padding: 40px;
}

.adminPanel .col-md-3 .list-group .list-group-item {
  width: 200px;
  font-size: 20px;
  font-family: "SemiBold";
  background-color: #1d2034;
  cursor: pointer;
  color: #ffffff;
  border: none;
}

.adminPanel .col-md-3 .list-group .list-group-item.active {
  border: 1px solid #ffffff;
  opacity: 0.5;
  border-radius: 10px;
}

.adminPanel .col-md-8 {
  // background-color: #ffffff;
}
</style>
