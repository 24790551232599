<template>
  <div>
    <div class="collectUserInfo">
      <b-alert variant="success" class="fill-msg text-center" show
        >Please Enter Your Details</b-alert
      >
      <b-form class="mt-2" @submit.prevent="prepareUserDetails">
        <div class="d-flex justify-content-between">
          <b-form-group>
            <b-form-file
              placeholder="Select Image"
              ref="userImage"
              @change="checkuserImage"
              v-model="userDetails.image"
              style="display: none"
              accept=".jpg, .png, .jpeg"
            ></b-form-file>
            <div class="imgSelection">
              <img
                v-if="imgUrl == null"
                @click="selectUserImg"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPcAAADMCAMAAACY78UPAAAAPFBMVEX29vawsLD5+fm6urqtra3CwsKysrLy8vLi4uLe3t7m5uaxsbHp6enFxcXNzc3s7OzT09O9vb3Q0NDZ2dlO/F6aAAAFFUlEQVR4nO2dyXarMAxAQQYzT+H///UZkjRNHyHEtmyJ6C66aLvgHnmQDZaTRBAEQRAEQRCEO3An9oMEY3Et2nKeR8Nc1UPyBfYAQ9VPOvtNqrqxTk7sDkndq0X0L+Z3TT4Xp1Q3ke71hvMv96lKzmYOUE470nf35lKcyhxK9d56NU/785hDOx2SvrX3yzlaOxT9cevVXJcnEIdSf6a9mHfsQw4fBvse8pq1OBTKRnsxnxmLQ/t5G/8R79mKQ2ltvYjnsZ/fEqhctI34FNvACldtpuJujZytOLTu2stEzm1wK7QHbSM+8hKHDzLyfXFWOStcPGkbitgyx4Han3aWMwq48qbNKWP12coXcS4tffCqzWYyg86vd5q1sZUO4SVjefJmMbRB7tubR8A99+7Vm0HAvffuVXyIrfUeBG0Gmy8wY3inOrbXO2DC0DbLk9hibyhQwk2+oSM1c/INHWU0X6A+hfvZZtnwpr3xgpC03LxJpy7ue8cv0aS9rd4CHoL0KhxyLO00q2PL7QBYwxr17SY0bdoDOlK2tnpTztjQpjHiu2zet5h+eU+EvT2+L/gP8aYHojfpdo7Yv1PKCfq3juffOn9/a74GPl8A//GuKHt/63rM75vvJ2/S6+8v3W9B3F+jPI0liPuppLcdEPfPab8RRevgKrbZGwocbdrZWoLylcfqTXn2XkBq6NSbeYKTopNOzq+gvDIhnazdwPieiXjSsoIwhROfvG94DziLcCP0cB7h9p67kM9Z7nj+uKeJ7XMYsD0NuwWnkzWDR20eg9oVjy1dM0hZHnibxDNuB+D9dHHi2ywbeDkoyWYKewCtB21OY9od90ODtL9RfAnUrtqxDSyBtnEIOcO+fce+jAmLLZY9LLcZs5Ty688D2GVumRp4axvx4eO2nmUX7tYr47Haa49gt6fQNsNbd9w80+xS09dAmx+st6dn9iXInoC2T9+ZZ5k6mfUCFPNekUXzp64+ZSXRpZTonDcbBVTNr1Rfni/UD0xA67lT+qlc7tRXw5nr5d64Fkiuy8pQ1kPxDfWRH3xbOWxBEARBYAvsEfvhELhqLbdYjH2XT0oprXXTNOanVmrKu36cy7q4/+MZMCJFW43dpNOfjHxjXXJFT91YtQVz+UV5WYWkm7KvlqO3iz24yhvncr21w2o/dV2ZVtxu9jCP246TnfKTvLrUfFblAOv1LE7OD3fNY0MCYLjsXc9ip94Sb/CQVAeuZ7FQJ73hCMVoX9f/nbqmepUNJO93ip3MSV5lA8UF1Xo1z8iZw4zWwp/M05FSP4fW54eZ++aKzAdtpmOHsl7NexohDxjsmziJS3zQKirumRP4LgDrQOi++BR3YHf5YMlNXMf8AAaGILPXNvE6uc/rGz4n2jmEuNrRKnz4uYnHSTxGxMHjGRJr8Qh93NMFRG40wU/UebuAyI3Q58IRC8R+ROATCT4uVfND2IM3RRPb94eQh2bRqrRYELD+IJ1WvhCwpeNVWbMh1BUAMVbcewRbjVPIWJ4IcnSWWrhDBRyxxrk1IbRJDeZXQgzpSIWn3AgwhyMVGnMDP2lDrBzpAP6hSkop6i/wGzrB0XwBewpHrHjtAnY9PprdG38mg5GoN/IheSr7S/+BXAqCZNayoMQbxzu24Ask3uIt3uIt3uIt3uIt3uJNEfEWb/EWb/EWb/EWb/EWb4qIt3iLt3iLt3gz9s6wvXtFE+wrIHYricUEV1sQBEEQBEEQgvAP11lefkObcB4AAAAASUVORK5CYII="
                alt=""
                class="img-fluid sampleImg rounded-circle"
                disabled
              />
              <img
                @click="imgUrl = null"
                v-else
                :src="imgUrl"
                class="img-fluid rounded-circle"
                alt=""
              />
            </div>
          </b-form-group>
          <div>
            <b-form-group>
              <b-form-input
                placeholder="Enter Name"
                v-model="userDetails.name"
                required
                :disabled="createBtn.disabled"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-input
                type="email"
                placeholder="Enter Email"
                v-model="userDetails.email"
                required
                :disabled="createBtn.disabled"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <b-form-group>
          <b-button block type="submit" :disabled="createBtn.disabled">{{
            createBtn.text
          }}</b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { create } from "ipfs-http-client";
import Web3 from "web3";
export default {
  created() {
    this.user = this.$store.getters.getMetamaskAccount.metamaskAccount;
  },
  data() {
    return {
      user: {},
      account: {},
      userDetails: {},
      imgUrl: null,
      createBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  methods: {
    async prepareUserDetails() {
      const web3 = await new Web3(window.ethereum);
      this.createBtn = {
        text: "Please wait",
        disabled: true,
      };
      let imageUrl = null;
      if (this.userDetails.image && this.userDetails.image != null) {
        this.createBtn.text = "Uploading Image";
        const uploadImage = await this.uploadUserImageToIpfs();
        imageUrl = await uploadImage.url;
      }

      this.createBtn.text = "Submitting Details";
      const signature = await web3.eth.personal
        .sign("", this.user.account)
        .then((res) => {
          let status = {
            signature: res,
            status: true,
          };
          res;
          return status;
        })
        .catch((err) => {
          let status = {
            signature: null,
            status: false,
          };
          err;
          return status;
        });
      if (!signature.status) {
        this.createBtn = {
          text: "Submit",
          disabled: false,
        };
        return this.$toast.error("Please approve metamask popup", {
          position: "top",
          duration: 3000,
        });
      }
      const user = await {
        name: this.userDetails.name,
        email: this.userDetails.email,
        address: this.user.account,
        signature: signature.signature,
        photo: imageUrl,
      };

      const uploadInfo = await this.uploadUserDetailsToApi(user);

      if (uploadInfo) {
        this.$toast.success("Details successfully stored, Thank You :)", {
          position: "top",
          duration: 3000,
        });
        this.$store.state.user = {
          exist: true,
          name: user.name,
          email: user.email,
          image: user.photo,
        };
        setTimeout(() => {
          this.$router.push({ name: "Home" });
        }, 2000);
      }
      if (!uploadInfo) {
        this.$toast.error("Unable to store details, Please try again!", {
          position: "top",
          duration: 3000,
        });
      }
      this.createBtn = {
        text: "Submit",
        disabled: false,
      };
    },
    async uploadUserImageToIpfs() {
      let upload = {
        status: null,
        url: null,
      };

      const storeInIpfs = await create("https://ipfs.infura.io:5001/api/v0");
      try {
        const uploadToIps = await storeInIpfs.add(this.userDetails.image);

        const FileUrl = await `https://ipfs.infura.io/ipfs/${uploadToIps.path}`;
        upload = {
          status: true,
          url: FileUrl,
        };
      } catch (err) {
        upload = {
          status: false,
          url: null,
        };
        err;
      }
      return upload;
    },
    async uploadUserDetailsToApi(user) {
      let status = null;
      await this.$axios
        .post(
          "https://us-central1-everestcoin.cloudfunctions.net/add-user-details",
          user
        )
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },

    selectUserImg() {
      let fileInputElement = this.$refs.userImage;
      fileInputElement.$refs.input.click();
    },
    checkuserImage($event) {
      const file = $event.target.files[0];
      this.imgUrl = URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.collectUserInfo {
  padding: 40px;
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
  margin: 50px 0px 50px 0px;
}

.collectUserInfo .imgSelection img {
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.collectUserInfo .fill-msg {
  font-size: 16px;
  font-family: "Bold";
}

.collectUserInfo form input {
  // width: 100%;
  height: 60px;
  border-radius: 20px;
}

.collectUserInfo form button {
  height: 50px;
  border-radius: 20px;
  border: none;
  background-color: #d8b871;
  color: #1d2034;
  font-family: "Bold";
  font-size: 20px;
}

.collectUserInfo form input::placeholder {
  font-size: 16px;
  font-family: "Light";
}
</style>
