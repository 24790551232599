<template>
  <div>
    <div class="notableArt auctionedNfts">
      <b-list-group>
        <b-list-group-item
          style="width: 100%; height: auto; box-shadow: none; border: none"
          v-if="auctionedNfts.length == 0 && !loadAuction"
          class="loader"
        >
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="auctionedNfts.length == 0 && loadAuction"
          style="width: 100%; height: auto; box-shadow: none; border: none"
        >
          <div class="noNft">No Auctioned NFT Listed Yet!</div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(art, artIndex) in auctionedNfts.length > 12
            ? getAssetRows
            : auctionedNfts"
          :key="artIndex"
          v-else
        >
          <img :src="art.image" c alt="" style="position: relative" />
          <span
            style="
              position: absolute;
              left: 10px;
              top: 10px;
              background-color: #d8b871;
              color: #ffffff;
              padding: 2px;
              border-radius: 50%;
              fontfamily: Bold;
              fontsize: 14px;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            v-if="
              account != null &&
              account.toLowerCase() != art.address.toLowerCase() &&
              $store.getters.getAsset.assetLinks[art.ipfs_link]
            "
            >{{ $store.getters.getAsset.assetLinks[art.ipfs_link].count }}</span
          >
          <div class="info">
            <div class="d-flex justify-content-between">
              <span>{{ art.name }}</span>
              <!-- <span>
                  <i class="far fa-heart mr-1" v-if="!art.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i>
                  {{ art.likes }}
                </span> -->
            </div>

            <div class="d-flex justify-content-between">
              <span
                >Owned By:
                <span class="font-weight-bolder">{{ art.username }}</span></span
              >
              <span
                >{{ art.price }}
                <span>{{ checkCurrency(art.currency) }}</span></span
              >
            </div>
            <b-button
              v-if="
                account != null &&
                account.toLowerCase() != art.address.toLowerCase() &&
                !$store.getters.getAsset.assetLinks[art.ipfs_link]
              "
              block
              @click="goToNftPage(art.id, art.auction)"
              >{{ art.auction ? "Place Bid" : "Buy Asset" }}</b-button
            >
            <b-button
              v-else-if="
                account != null &&
                account.toLowerCase() != art.address.toLowerCase() &&
                $store.getters.getAsset.assetLinks[art.ipfs_link]
              "
              block
              @click="getNftCopies(art)"
            >
              Buy Asset
            </b-button>
            <b-button v-else-if="account == null" block disabled
              >Connect Wallet</b-button
            >
            <b-button v-else disabled block>Already Owned</b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="marketAssets"
        align="center"
        size="md"
        v-if="auctionedNfts.length > 12"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
export default {
  props: ["account"],
  async created() {
    await this.getAuctionNfts();
  },
  data() {
    return {
      auctionedNfts: [],
      loadAuction: false,
      currentPage: 1,
      rows: "",
      perPage: 12,
    };
  },
  computed: {
    getAssetRows() {
      return this.auctionedNfts.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    async getAuctionNfts() {
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-auctions`;
      await this.$axios.get(url).then((res) => {
        const nftsData = res.data;
        let featuredNfts = {};
        nftsData.forEach((nft) => {
          this.$set(featuredNfts, nft.ipfs_link, {
            id: nft.id,
            name: nft.name,
            description: nft.description,
            ipfs_link: nft.ipfs_link,
            address: nft.address,
            price: nft.auction == 0
                  ? web3.utils.fromWei(nft.price.toString(), "Ether")
                  : (nft.price / 10 ** 18).toString(),
            created_at: nft.created_at,
            image: nft.image,
            auction: nft.auction == 1 ? true : false,
            selling: nft.selling == 1 ? true : false,
            end_time: nft.end_time,
            bidder: nft.bidder,
            creator: nft.creator,
            currency: nft.currency,
            photo: nft.photo,
            username: nft.username,
            featured: nft.featured == 1 ? true : false,
          });
        });

        for (let featured in featuredNfts) {
          this.auctionedNfts.push(featuredNfts[featured]);
        }

        if (this.auctionedNfts.length == 0) {
          this.loadAuction = true;
        } else {
          this.loadAuction = false;
        }

        this.rows = this.auctionedNfts.length;
      });
    },
    goToNftPage(nftId, auction) {
      if (!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({ name: "Profile" });
      }
      this.$router.push({
        name: auction ? "AuctionNft" : "BuyNft",
        params: {
          nftId: btoa(nftId),
        },
      });
    },
    getNftCopies(asset) {
      if (!this.$store.getters.getMetamaskAccount.user.exist) {
        return this.$router.push({ name: "Profile" });
      }
      this.$router.push({
        name: "NftCopies",
        params: {
          nftName: asset.name,
          nftId: btoa(asset.ipfs_link),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.notableArt .pagination .page-item .page-link {
  background-color: #d8b871;
  color: #ffffff;
  border: 1px solid white;
  font-size: 14px;
  font-family: "SemiBold";
  box-shadow: none;
}

.notableArt .pagination .active .page-link {
  color: #d8b871;
  background-color: #ffffff;
}
</style>
