<template>
  <div>
    <div class="marketplace">
      <div
        class="marketPlaceBg d-flex align-items-center justify-content-center"
      >
        <b-container>
          <b-row>
            <b-col md="12">
              <h4
                class="text-center"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                NFT Marketplace
              </h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="loadData" v-if="fetchData">
        <b-list-group>
          <b-list-group-item class="loader">
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="marketplaceOptions" v-else>
        <b-tabs justified lazy v-model="tabIndex" class="min-vh-100">
          <b-tab no-body title="All">
            <AllNfts
              :account="account"
              :nfts="nfts"
              :noNftsFound="noNftsFound"
              :nftsObject="nftsObject"
            />
          </b-tab>

          <b-tab no-body title="Featured">
            <FeaturedNfts :account="account" />
          </b-tab>

          <b-tab no-body title="Live Auctions">
            <AuctionedNfts
              :account="account"
              :nfts="nfts"
              :noNftsFound="noNftsFound"
              :nftsObject="nftsObject"
            />
          </b-tab>

          <b-tab no-body title="Collections">
            <Collections />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";

// import Search from "../Search/Search.vue";
import AllNfts from "./AllNfts.vue";
import FeaturedNfts from "./FeaturedNfts.vue";
import AuctionedNfts from "./AuctionedNfts.vue";
// import Category from "./Category.vue";
import Collections from "../Homepage/Collections/Collections.vue";
export default {
  async created() {
    this.fetchData = true;
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    await this.getMarketplaceData();
    if (this.$route.params.category != "all") {
      if (this.$route.params.category == "featured") {
        this.tabIndex = 1;
      } else if (this.$route.params.category == "auctions") {
        this.tabIndex = 2;
      } else {
        this.tabIndex = 3;
      }
    }
    this.fetchData = false;
  },
  data() {
    return {
      account: null,
      nfts: [],
      noNftsFound: false,
      tabIndex: 0,
      nftsObject: {},
      fetchData: false,
    };
  },
  components: {
    // Search,
    AllNfts,
    FeaturedNfts,
    AuctionedNfts,
    Collections,
    // Category,
  },
  methods: {
    async getMarketplaceData() {
      const web3 = await new Web3(window.ethereum);
      const url =
        "https://us-central1-everestcoin.cloudfunctions.net/dashboard";
      await this.$axios
        .get(url)
        .then((response) => {
          const nftsData = response.data;

          nftsData.forEach((nft) => {
            this.nfts.push({
              id: nft.id,
              name: nft.name,
              description: nft.description,
              ipfs_link: nft.ipfs_link,
              address: nft.address,
              price:
                nft.auction == 0
                  ? web3.utils.fromWei(nft.price.toString(), "Ether")
                  : (nft.price / 10 ** 18).toString(),
              created_at: nft.created_at,
              image: nft.image,
              auction: nft.auction == 1 ? true : false,
              selling: nft.selling == 1 ? true : false,
              end_time: nft.end_time,
              bidder: nft.bidder,
              creator: nft.creator,
              currency: nft.currency,
              photo: nft.photo,
              username: nft.username,
              featured: nft.featured == 1 ? true : false,
            });
            this.$set(this.nftsObject, nft.ipfs_link, {
              id: nft.id,
              name: nft.name,
              description: nft.description,
              ipfs_link: nft.ipfs_link,
              address: nft.address,
              price:
                nft.auction == 0
                  ? web3.utils.fromWei(nft.price.toString(), "Ether")
                  : (nft.price / 10 ** 18).toString(),
              created_at: nft.created_at,
              image: nft.image,
              auction: nft.auction == 1 ? true : false,
              selling: nft.selling == 1 ? true : false,
              end_time: nft.end_time,
              bidder: nft.bidder,
              creator: nft.creator,
              currency: nft.currency,
              photo: nft.photo,
              username: nft.username,
              featured: nft.featured == 1 ? true : false,
            });

            if (nftsData.length == 0) {
              this.noNftsFound = true;
            } else {
              this.noNftsFound = false;
            }
          });
          response;
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>

<style lang="scss">
.marketplace .marketplaceOptions {
  display: flex;
  flex-direction: column;
  margin: 70px 0px 100px 0px;
  padding: 0 80px 0 80px;
  overflow: hidden;
}
.marketplace .marketPlaceBg {
  background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 200px;
  padding: 10px;
  background-position-y: center;
}

.marketplace .marketPlaceBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.marketplace .marketplaceOptions .filterMarketplace {
  background-color: transparent;
  border: none;
}

.marketplace .marketplaceOptions .card-header {
  background-color: #ffffff;
}
.marketplace .marketplaceOptions .tabs {
  //  margin: 50px 0 0 0 0;
  border: none;
}
.marketplace .marketplaceOptions .nav-tabs .nav-item a {
  background-color: #ffffff;
  font-size: 20px;
  font-family: "Regular";
  color: #1d2034;
  border: none;
}

.marketplace .marketplaceOptions .nav-tabs .nav-item a.active {
  font-size: 20px;
  font-family: "SemiBold";
  color: #ffffff;
  background-color: #1d2034;
  border: none;
}
// .marketplace .notableArt {
//   margin: 0px;
//   padding: 0px;
// }

.marketplace .notableArt .list-group-item .noNft {
  font-size: 24px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.marketplace .notableArt .list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
  padding: 50px 0 0 0;
}

// .notableArt .list-group-item {
//   margin: 10px 0 20px 20px;
// }

.marketplace .notableArt .list-group-item {
  min-width: 283px;
  max-width: 283px;
  min-height: 422px;
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
}

.marketplace .notableArt .list-group .loader,
.marketplace .loadData .list-group .loader {
  width: 100%;
  min-width: none;
  max-width: none;
  min-height: auto;
  display: flex;
  flex-wrap: wrap;
}
.marketplace .notableArt .list-group-item .b-skeleton-img,
.marketplace .notableArt .loadData .loader .b-skeleton-img {
  width: 250px;
  height: 350px;
  margin-left: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.marketplace .notableArt .list-group-item img {
  width: 100%;
  height: 283px;
}

.marketplace .notableArt .list-group-item .info {
  // margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Regular";
  // height: auto;
  min-height: 139px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marketplace .notableArt .list-group-item .info button {
  // margin-top: 10px;
  font-size: 16px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .marketplace .notableArt {
    padding: 0 0px 0 0px;
  }
  .marketplace .marketPlaceBg {
    height: auto;
  }
  .marketplace .marketPlaceBg h4 {
    font-size: 24px;
  }
  .marketplace .marketplaceOptions {
    margin: 20px 0px 0px 0px;
    padding: 0 20px 0 20px;
    overflow: hidden;
  }
  .marketplace .notableArt .heading {
    padding: 5px;
  }

  .marketplace .notableArt .heading h4 {
    font-size: 18px;
  }

  .marketplace .notableArt .heading button {
    height: 40px;
    font-size: 16px;
  }

  .marketplace .notableArt .notableNfts {
    padding: 10px;
  }

  .marketplace .notableArt .list-group {
    justify-content: center;
  }

  .marketplace .notableArt .list-group-item {
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
  }

  .notableArt .list-group-item img {
    width: 100%;
    height: 250px;
  }

  .marketplace .notableArt .list-group-item .info {
    min-height: auto;
    font-size: 16px;
  }

  .marketplace .notableArt .list-group-item .loader {
    display: block;
    flex-wrap: none;
  }

  .marketplace .marketplaceOptions .tabs {
    //  margin: 50px 0 0 0 0;
    width: 100%;
    border: none;
  }
  .marketplace .marketplaceOptions .nav-tabs .nav-item a {
    background-color: #ffffff;
    font-size: 14px;
    font-family: "Regular";
    color: #1d2034;
    border: none;
  }

  .marketplace .marketplaceOptions .nav-tabs .nav-item a.active {
    font-size: 14px;
    font-family: "SemiBold";
    color: #ffffff;
    background-color: #1d2034;
    border: none;
  }

  .marketplace .notableArt .list-group-item .b-skeleton-img,
  .marketplace .notableArt .loadData .loader .b-skeleton-img {
    width: 250px;
    height: 350px;
    margin-left: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
}
</style>
