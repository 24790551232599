import Vue from "vue";
import Vuex from "vuex";
import Contract from "../Contract/Contract.js"

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    customRpc: [
      {
        chainId: "0x38",
        chainName: "BSC MAINNET",
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
      },
    ],
    metamaskAccount: {
      account: null,
      productCount: 0,
      products: [],
      minter_role: false,
      hasRole: false,
      sellingEnabled: false,
      loading: false,
      transaction: false,
      royalty: null
    },
    smartContract: {
      abi: Contract.abi,
      address: Contract.address,
      contract: null,

    },
    usdtContract: {
      abi: Contract.usdt,
      address: Contract.usdtAddress,
      contract: null
    },
    sellContract: {
      abi: Contract.sell,
      address: Contract.sellAddress,
      contract: null
    },
    user: {
      exist: false,
      name: null,
      email: null,
      image: null,
    },
    asset: {},
    assetLinks: {}
  },
  getters: {
    getCustomRpc(state) {
      return {
        customRpc: state.customRpc,
      };
    },
    getDecrytionKey(state) {
      return {
        decrytionKey: state.decrytionKey,
      };
    },
    getAsset(state) {
      return {
        asset: state.asset,
        assetLinks: state.assetLinks
      };
    },
    getMetamaskAccount(state) {
      return {
        metamaskAccount: state.metamaskAccount,
        user: state.user
      };
    },
    getSmartContractStructure(state) {
      return {
        smartContract: state.smartContract,
        usdtContract: state.usdtContract,
        sellContract: state.sellContract,
      };
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
