import { create } from "ipfs-http-client";

const storeInIpfs = create("https://ipfs.infura.io:5001/api/v0");

const uploadFilesWithIPFS = {
  uploadFile: uploadFile,
};

async function uploadFile(assetData) {
  let upload = {
    status: null,
    url: null,
  };
  try {
    const uploadToIps = await storeInIpfs.add(assetData.file);
    const FileUrl = await `https://ipfs.infura.io/ipfs/${uploadToIps.path}`;

    const assetDataJsonObject = await {
      name: assetData.name,
      description: assetData.description,
      collection: assetData.collection,
      image: FileUrl,
    };
    const jsonToStringify = await JSON.stringify(assetDataJsonObject);

    const storeJsonInIpfs = await storeInIpfs.add(jsonToStringify);

    const jsonObjectUrl = `https://ipfs.infura.io/ipfs/${storeJsonInIpfs.path}`;

    upload = {
      status: true,
      jsonObjectUrl: jsonObjectUrl,
      assetDataJsonObject: assetDataJsonObject,
    };
  } catch (error) {
    upload = {
      status: false,
      url: null,
    };
    error;
  }
  return upload;
}

export default uploadFilesWithIPFS;
