<template>
  <div>
    <div class="share">
      <b-button variant="light" id="shareNft" pill>
        <i class="fas fa-share-alt"></i>
      </b-button>
      <b-popover
        container="shareNft"
        id="shareNft"
        target="shareNft"
        triggers="click"
        placement="top"
      >
        <b-list-group>
          <b-list-group-item @click="copyLink">
            <i class="fas fa-link" style="color: #0645ad"></i>
            <span>Copy Link</span>
          </b-list-group-item>
          <b-list-group-item @click="shareOnFacebook">
            <i class="fab fa-facebook" style="color: #4267b2"></i>
            <span>Share on Facebook</span>
          </b-list-group-item>
          <b-list-group-item @click="shareOnTwitter">
            <i class="fab fa-twitter" style="color: #1da1f2"></i>
            <span>Share on Twitter</span>
          </b-list-group-item>
        </b-list-group>
        <!-- <b-button @click="copyLink">Copy Link</b-button> -->
      </b-popover>
    </div>
  </div>
</template>

<script>
export default {
  props: ["asset"],
  created() {
    this.url = window.location.href;
  },
  data() {
    return {
      url: null,
    };
  },
  methods: {
    async copyLink() {
      try {
        const toCopy = window.location.href;
        await navigator.clipboard.writeText(toCopy);
        this.$toast.info(`NFT Link copied to clipboard`, {
          position: "top-right",
          duration: 3000,
        });
      } catch (err) {
        err;
      }
    },
    shareOnFacebook() {
      const text = encodeURIComponent(
        "Check out the amazing art I found on @coineverest's NFT Marketplace"
      );
      const url = encodeURIComponent(this.url);
      window.open(
        `https://www.facebook.com/sharer/sharer.php?t=${text}&u=${url}`,
        "_blank"
      );
    },
    shareOnTwitter() {
      const text = encodeURIComponent(
        "Check out the amazing art I found on @coineverest's NFT Marketplace"
      );
      const url = encodeURIComponent(this.url);
      window.open(`http://twitter.com/share?text=${text}&url=${url}`, "_blank");
    },
  },
};
</script>

<style lang="scss">
.share {
  margin-left: 10px;
}
.share button {
  // background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  // border-radius: 30px;
  padding: 10px;
  color: rgb(112, 122, 131);
}

.share button i {
  font-size: 20px;
}

.share button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.share .popover-body {
  padding: 0px;
  margin: 0px;
  border: none;
}

.share .popover .list-group .list-group-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  min-width: 150px;
  min-height: 50px;
}
.share .popover .list-group .list-group-item:hover {
  transition: all 0.2s ease 0s;
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
}

.share .popover .list-group .list-group-item span {
  font-size: 14px;
  font-family: "SemiBold";
  margin-left: 5px;
}
</style>
