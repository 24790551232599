<template>
  <div>
    <div class="all profileAll">
      <div
        class="refresh mb-2 d-flex justify-content-between align-items-center"
      >
        <span></span>
        <b-pagination
          class="mt-2"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="myAssets"
          align="center"
          size="md"
          v-if="nfts.length > 9"
        ></b-pagination>
        <span v-else></span>
        <b-button class="refreshBtn" @click="getMyNfts" size="sm"
          ><i class="fas fa-sync-alt"></i> Refresh List</b-button
        >
      </div>

      <b-list-group id="myAssets">
        <b-list-group-item
          class="loader"
          v-if="nfts.length == 0 && !noNftsFound"
        >
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
          <b-skeleton-img></b-skeleton-img>
        </b-list-group-item>
        <b-list-group-item
          v-else-if="nfts.length == 0 && noNftsFound"
          style="width: 100%; height: auto; box-shadow: none; border: none"
        >
          <div class="noNft">No Data Found!</div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(art, artIndex) in nfts.length > 9 ? getAssetRows : nfts"
          :key="artIndex"
          v-else
        >
          <img :src="art.image" c alt="" />
          <div class="info">
            <div class="d-flex justify-content-between">
              <span>{{ art.name }}</span>
              <!-- <span>
                  <i class="far fa-heart mr-1" v-if="!art.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i>
                  {{ art.likes }}
                </span> -->
            </div>
            <div class="d-flex justify-content-between">
              <span
                >Owned By:
                <span class="font-weight-bolder">{{ art.username }}</span></span
              >
              <span
                >{{ art.price }}
                <span>{{ checkCurrency(art.currency) }}</span></span
              >
            </div>
            <b-button
              v-if="art.selling && !art.auction"
              block
              @click="getAsset(art.id, 'UpdateNft')"
              >Listed</b-button
            >
            <b-button
              v-if="art.selling && art.auction"
              block
              @click="getAsset(art.id, 'ViewNftOnly')"
              >Under Auction</b-button
            >
            <b-button
              v-if="!art.selling"
              block
              @click="getAsset(art.id, 'SellNft')"
              >Sell Asset</b-button
            >
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
export default {
  async created() {
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    await this.getMyNfts();
  },
  data() {
    return {
      account: null,
      nfts: [],
      noNftsFound: false,
      currentPage: 1,
      rows: "",
      perPage: 9,
    };
  },
  computed: {
    getAssetRows() {
      return this.nfts.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    async getMyNfts() {
      this.nfts = [];
      this.noNftsFound = false;
      const web3 = await new Web3(window.ethereum);
      const url =
        "https://us-central1-everestcoin.cloudfunctions.net/get-my-nfts";
      await this.$axios
        .post(url, { address: this.account })
        .then((response) => {
          const nftsData = response.data;
          web3;
          nftsData.forEach((nft) => {
            this.nfts.push({
              id: nft.id,
              name: nft.name,
              description: nft.description,
              ipfs_link: nft.ipfs_link,
              address: nft.address,

              price: (nft.price / 10 ** 18).toString(),
              created_at: nft.created_at,
              image: nft.image,
              auction: nft.auction == 1 ? true : false,
              selling: nft.selling == 1 ? true : false,
              end_time: nft.end_time,
              bidder: nft.bidder,
              creator: nft.creator,
              currency: nft.currency,
              photo: nft.photo,
              username: nft.username,
            });
          });

          if (nftsData.length == 0) {
            this.noNftsFound = true;
          } else {
            this.rows = this.nfts.length;
            this.noNftsFound = false;
          }

          this.$forceUpdate();
        })
        .catch((err) => {
          err;
        });
    },
    async getAsset(assetId, pathName) {
      this.$router.push({
        name: pathName,
        params: {
          nftId: btoa(assetId),
          address: btoa(this.account),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.profileAll {
  padding: 50px 0 0 0;
}
.profileAll .list-group-item .noNft {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.profileAll .list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

// .profileAll .list-group-item {
//   margin: 10px 0 20px 20px;
// }

.profileAll .list-group-item {
  min-width: 31%;
  max-width: 31%;
  height: 350px;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
}

.profileAll .list-group-item.loader {
  // width: 100vw;
  // min-width: none;
  // max-width: none;
  height: auto;
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  // flex-wrap: wrap;
  border: none;
  box-shadow: none;
}

.profileAll .list-group-item.loader .b-skeleton-img {
  width: 250px;
  height: 350px;
  margin-left: 10px;
}

.profileAll .list-group-item img {
  width: 100%;
  height: 220px;
}

.profileAll .list-group-item .info {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Regular";
}

.profileAll .list-group-item .info button {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

.profileAll .refresh button.refreshBtn {
  background-color: #d8b871;
  max-width: 200px;
  max-height: 40px;
  font-size: 14px;
  color: #ffffff;
  font-family: "SemiBold";
  outline: none;
  box-shadow: none;
  border: none;
}

.profileAll .refresh .pagination .page-item .page-link {
  background-color: #d8b871;
  color: #ffffff;
  border: 1px solid white;
  font-size: 14px;
  font-family: "SemiBold";
  box-shadow: none;
}

.profileAll .refresh .pagination .active .page-link {
  color: #d8b871;
  background-color: #ffffff;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .profileAll {
    padding: 0 0px 0 0px;
  }

  .profileAll .heading {
    padding: 5px;
  }

  .profileAll .heading h4 {
    font-size: 18px;
  }

  .profileAll .heading button {
    height: 40px;
    font-size: 16px;
  }

  .profileAll .notableNfts {
    padding: 10px;
  }

  .profileAll .list-group {
    justify-content: center;
  }

  .profileAll .list-group-item {
    max-width: 100%;
  }

  .profileAll .list-group-item {
    width: 100%;
    height: 350px;
  }

  .profileAll .list-group-item img {
    width: 100%;
    height: 200px;
  }

  .profileAll .list-group-item .info {
    font-size: 16px;
  }

  .profileAll .list-group-item.loader {
    flex-direction: column;
    justify-content: center;
  }

  .profileAll .list-group-item.loader .b-skeleton-img {
    width: 100%;
    height: 200px;
  }
}
</style>
