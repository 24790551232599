var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"featuredNfts"},[_c('div',{staticClass:"heading"},[_c('h4',[_vm._v("Featured NFTS")]),_c('b-button',{on:{"click":function($event){return _vm.$router.push({
            name: 'Marketplace',
            params: {
              category: 'featured',
            },
          })}}},[_vm._v("View All "),_c('i',{staticClass:"fas fa-arrow-right ml-1"})])],1),_c('div',{staticClass:"notableNfts"},[_c('b-list-group',[(_vm.featuredNfts.length == 0 && !_vm.noNftsFound)?_c('b-list-group-item',{staticClass:"loader"},[_c('b-skeleton-img'),_c('b-skeleton-img'),_c('b-skeleton-img'),_c('b-skeleton-img')],1):(_vm.featuredNfts.length == 0 && _vm.noNftsFound)?_c('b-list-group-item',{staticStyle:{"width":"100%","height":"auto","box-shadow":"none","border":"none"}},[_c('div',{staticClass:"noNft"},[_vm._v("No Data Found!")])]):_vm._l((_vm.featuredNfts),function(art,artIndex){return _c('b-list-group-item',{key:artIndex},[_c('div',{attrs:{"data-aos":"zoom-in","data-aos-duration":"2000"}},[_c('img',{staticStyle:{"position":"relative"},attrs:{"src":art.image,"c":"","alt":""}}),(
                _vm.account != null &&
                _vm.account.toLowerCase() != art.address.toLowerCase() &&
                _vm.$store.getters.getAsset.assetLinks[art.ipfs_link]
              )?_c('span',{staticStyle:{"position":"absolute","left":"10px","top":"10px","background-color":"#d8b871","color":"#ffffff","padding":"2px","border-radius":"50%","fontfamily":"Bold","fontsize":"14px","width":"25px","height":"25px","display":"flex","justify-content":"center","align-items":"center"}},[_vm._v(_vm._s(_vm.$store.getters.getAsset.assetLinks[art.ipfs_link].count))]):_vm._e(),_c('div',{staticClass:"info"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(art.name))])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Owned By: "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(art.username))])]),_c('span',[_vm._v(_vm._s(art.price)+" "),_c('span',[_vm._v(_vm._s(_vm.checkCurrency(art.currency)))])])]),(
                  _vm.account != null &&
                  _vm.account.toLowerCase() != art.address.toLowerCase() &&
                  !_vm.$store.getters.getAsset.assetLinks[art.ipfs_link]
                )?_c('b-button',{attrs:{"block":""},on:{"click":function($event){return _vm.goToNftPage(art.id, art.auction)}}},[_vm._v(_vm._s(art.auction ? "Place Bid" : "Buy Asset"))]):(
                  _vm.account != null &&
                  _vm.account.toLowerCase() != art.address.toLowerCase() &&
                  _vm.$store.getters.getAsset.assetLinks[art.ipfs_link]
                )?_c('b-button',{attrs:{"block":""},on:{"click":function($event){return _vm.getNftCopies(art)}}},[_vm._v(" Buy Asset ")]):(_vm.account == null)?_c('b-button',{attrs:{"block":"","disabled":""}},[_vm._v("Connect Wallet")]):_c('b-button',{attrs:{"disabled":"","block":""}},[_vm._v("Already Owned")])],1)])])})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }