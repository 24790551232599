<template>
  <div>
    <div class="royalty bg-white rounded">
      <b-row>
        <b-col md="12">
          <div class="load w-100" v-if="fetchData">
            <b-skeleton type="input" class="mt-2"></b-skeleton>
            <b-skeleton type="button" class="mt-2"></b-skeleton>
          </div>
          <b-form @submit.prevent="updateRoyalty" class="w-100" v-else>
            <b-form-group>
              <b-form-spinbutton
                v-model="royalty"
                :min="min"
                :max="max"
                :disabled="submitBtn.disabled"
              ></b-form-spinbutton>
            </b-form-group>
            <b-form-group>
              <b-button type="submit" block :disabled="submitBtn.disabled">{{
                submitBtn.text
              }}</b-button>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["contract", "account"],
  async created() {
    this.fetchData = true;
    await this.getCurrentRoyalty();
    if(this.royalty == null) {
      this.royalty = 1;
    }
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      min: "1",
      max: "100",
      royalty: null,
      royaltyOptions: [],
      submitBtn: {
        text: "Update Royalty",
        disabled: false,
      },
    };
  },
  methods: {
    async getCurrentRoyalty() {
      this.fetchData = true;
      let getRoyalty = await this.contract.methods
        .maxRoyalty()
        .call()
        .then((res) => {
          return res;
        });
      this.max = Number(getRoyalty);
      this.royalty = getRoyalty;
      this.fetchData = false;
    },


    async updateRoyalty() {
      this.submitBtn = {
        text: "Please approve metamask & wait...",
        disabled: true,
      };
      let updateRoyalty = await this.contract.methods
        .updateFee(this.royalty.toString())
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });
      if (updateRoyalty) {
        this.$toast.success("Royalty Updated", {
          position: "top",
          duration: 3000,
        });
      }
      if (!updateRoyalty) {
        this.submitBtn = {
          text: "Update Royalty",
          disabled: false,
        };
        return this.$toast.error(
          "Unable to update royalty, Please try again!",
          {
            position: "top",
            duration: 3000,
          }
        );
      }
      await this.getCurrentRoyalty();
      this.submitBtn = {
        text: "Update Royalty",
        disabled: false,
      };
    },
  },
};
</script>

<style lang="scss">
.royalty form input {
  height: 45px;
  border-radius: 10px;
  font-size: 16px;
  font-family: "SemiBold";
}

.royalty button {
  border: none;
  background-color: #d8b871;
  font-size: 20px;
  font-family: "Bold";
}
</style>
