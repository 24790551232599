<template>
  <div>
    <div class="buyOrBidNft">
      <div
        class="buyOrBidNftBg d-flex align-items-center justify-content-center"
      >
        <b-container>
          <b-row>
            <b-col md="12">
              <h4 class="text-center">Explore Game Characters NFTs</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="nft">
        <b-modal
          id="bidPrice"
          v-if="asset.id"
          hide-footer
          centered
          size="md"
          modal-class="bidPrice"
          hide-header
        >
          <b-form
            @submit.prevent="
              Number(bidPrice) > Number(convertToNormalPrice(asset))
                ? prepareForAuction()
                : $toast.error('Check Bid Price')
            "
          >
            <b-input-group>
              <template #append>
                <b-input-group-text>
                  <b-button
                    v-if="
                      Number(bidPrice) > Number(convertToNormalPrice(asset))
                    "
                    class="placeBidPrice"
                    type="submit"
                    >Place Bid</b-button
                  >
                  <b-button
                    v-else
                    :variant="
                      Number(bidPrice) > Number(convertToNormalPrice(asset))
                        ? 'success'
                        : 'danger'
                    "
                    type="submit"
                    >{{
                      Number(bidPrice) > Number(convertToNormalPrice(asset))
                        ? "Valid Bid"
                        : "Invalid Bid"
                    }}</b-button
                  >
                </b-input-group-text>
              </template>
              <b-form-input v-model="bidPrice"></b-form-input>
            </b-input-group>
          </b-form>
        </b-modal>
        <b-container fluid>
          <b-row>
            <b-col md="12">
              <b-alert
                variant="success"
                style="font-size: 14px; font-family: SemiBold"
                show
              >
                To place a successful bid, make sure your wallet holds the bid
                amount in USDT and a little amount in BNB for gas fees Add USDT
                Contract Address to Metamask:
                0x55d398326f99059ff775485246999027b3197955
              </b-alert>
            </b-col>
            <b-col md="4" class="min-vh-50 d-flex flex-column">
              <b-skeleton-img v-if="fetchData"></b-skeleton-img>
              <img :src="asset.image" v-else alt="" />
              <div class="description mt-3 mb-2">
                <div class="heading">Description</div>
                <div v-if="fetchData">
                  <b-skeleton animation="fade" width="70%"></b-skeleton>
                  <b-skeleton animation="fade" width="50%"></b-skeleton>
                  <b-skeleton animation="fade" width="40%"></b-skeleton>
                  <b-skeleton animation="fade" width="30%"></b-skeleton>
                </div>
                <div v-else class="mt-2">
                  <p v-if="this.asset.description.length > 45">
                    {{ handleDescription }}...
                    <span
                      class="text-info btn p-0 m-0"
                      @click="longText = !longText"
                      >{{ longText ? "Read More" : "Read Less" }}</span
                    >
                  </p>
                  <p v-else>{{ asset.description }}</p>
                </div>
              </div>
              <div class="auctionTime mt-1 text-center">
                <div class="end text-white"></div>
                <vue-countdown
                  :time="auctionTime"
                  v-slot="{ days, hours, minutes, seconds }"
                  :transform="transformSlotProps"
                >
                  <div class="auctionTimer">
                    <span>{{
                      auctionTime == null ? "Auction Ended" : "Auction Ends In"
                    }}</span>
                    <div>
                      <span>{{ days }}</span>
                      <span>:</span>
                      <span>{{ hours }}</span>
                      <span>:</span>
                      <span>{{ minutes }}</span>
                      <span>:</span>
                      <span>{{ seconds }}</span>
                    </div>
                  </div>
                </vue-countdown>
              </div>
            </b-col>
            <b-col md="8" class="min-vh-50 d-flex flex-column">
              <div class="ownerName">
                <div class="d-flex align-items-center">
                  <b-skeleton-icon
                    icon="person"
                    :icon-props="{ fontScale: 3 }"
                    v-if="fetchData"
                  ></b-skeleton-icon>
                  <img
                    :src="asset.photo"
                    style="width: 87px; height: 87px"
                    class="rounded-circle"
                    v-else
                    alt=""
                  />
                  <div v-if="fetchData" class="ml-2 w-100">
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                  </div>
                  <div class="ml-2" v-else>
                    <span class="name">{{ asset.username }}</span> <br />
                    <span class="type">Item Owner</span>
                  </div>
                </div>

                <div>
                  <div v-if="fetchData" class="ml-2 w-100">
                    <b-skeleton
                      animation="fade"
                      type="button"
                      width="100%"
                    ></b-skeleton>
                  </div>

                  <Share :asset="asset" />
                </div>
              </div>

              <div class="assetPrice mt-4">
                <div v-if="fetchData">
                  <b-skeleton animation="fade" type="button"></b-skeleton>
                </div>
                <div v-else>
                  <div class="price">Highest Bid</div>
                  <div class="priceVal">
                    <span
                      >{{ convertToNormalPrice(asset) }}
                      <span>{{ checkCurrency(asset.currency) }}</span></span
                    >
                  </div>
                </div>
                <div class="placeOrBid mt-2">
                  <b-skeleton
                    v-if="fetchData"
                    type="button"
                    animation="fade"
                    width="70%"
                  ></b-skeleton>
                  <b-button
                    @click="$bvModal.show('bidPrice')"
                    v-else
                    :disabled="buy.disabled"
                    >{{ buy.text }}</b-button
                  >
                </div>
              </div>

              <div v-if="fetchData" class="mt-5">
                <b-skeleton animation="fade" width="100%"></b-skeleton>
                <b-skeleton animation="fade" width="100%"></b-skeleton>
              </div>
              <div class="bidsHistory mt-5" v-else>
                <div ref="bidHistory">
                  <b-nav align="center">
                    <b-nav-item
                      @click="getHistory(history.component, historyIndex)"
                      :active="activeHistory == historyIndex ? true : false"
                      v-for="(history, historyIndex) in history"
                      :key="historyIndex"
                    >
                      {{ history.name }}
                    </b-nav-item>
                  </b-nav>
                  <div v-if="nftBids == null">
                    <div class="userBid d-flex w-100 mb-2">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                    <div class="userBid d-flex w-100 mb-2">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                    <div class="userBid d-flex w-100">
                      <div class="userimg">
                        <b-skeleton type="avatar"></b-skeleton>
                      </div>

                      <div class="ml-2 w-100">
                        <div>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                          <b-skeleton
                            animation="throb"
                            width="50%"
                          ></b-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2" v-else>
                    <component
                      :creator="asset.creator"
                      :asset="asset"
                      :nftBids="nftBids"
                      v-bind:is="currentComponent"
                    ></component>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
import Bids from "./History/Bids.vue";
import History from "./History/History.vue";
import Details from "./History/Details.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  async created() {
    this.fetchData = true;
    this.contractAddress = await this.$store.getters.getSmartContractStructure
      .smartContract.address;
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;
    this.usdtContract = await this.$store.getters.getSmartContractStructure
      .usdtContract.contract;
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;

    await this.getNftInfo();
    this.bidPrice = Number(this.asset.price / 10 ** 18) + 1;
    this.longText = this.asset.description.length > 45 ? true : false;

    this.fetchData = false;
  },
  computed: {
    calculateHeight() {
      return this.$refs.bidHistory + "px";
    },
    handleDescription() {
      if (
        this.longText &&
        this.asset.description &&
        this.asset.description.length > 45
      ) {
        return this.asset.description.slice(0, 44);
      }
      return this.asset.description;
    },
  },
  data() {
    return {
      usdtContract: null,
      contract: null,
      contractAddress: null,
      account: null,
      asset: {},
      bidPrice: null,
      fetchData: false,
      activeHistory: 0,
      currentComponent: "History",
      nftBids: null,
      longText: false,
      history: [
        { name: "History", component: "History" },
        { name: "Details", component: "Details" },
      ],
      buy: {
        text: "Place Bid",
        disabled: false,
      },
      auctionTime: null,
    };
  },
  components: {
    Bids,
    History,
    Details,
    VueCountdown,
  },

  methods: {
    async prepareForAuction() {
      this.$bvModal.hide("bidPrice");
      this.buy = {
        text: "Please approve metamask...",
        disabled: true,
      };

      const getApproval = await this.gettingBidAllowance();
      if (getApproval.status) {
        if (Number(this.bidPrice) > Number(getApproval.allowence)) {
          this.buy.text = "Please approve metamask & wait....";
          const checkingBidPrice = await this.usdtContract.methods
            .approve(
              this.contractAddress,
              (this.bidPrice * 10 ** 18).toString()
            )
            .send({ from: this.account })
            .then((res) => {
              res;
              return true;
            })
            .catch((err) => {
              err;
              return false;
            });

          if (checkingBidPrice) {
            this.buy.text = "Almost There....";
          }
          if (!checkingBidPrice) {
            this.buy = {
              text: "Retry",
              disabled: false,
            };
            return this.$toast.error("Unable to proceed", {
              position: "top",
              duration: 3000,
            });
          }
        }
        const newPrice = web3.utils.toWei(this.bidPrice.toString(), "Ether");

        const executeBid = await this.contract.methods
          .bidOnAuction(this.asset.id.toString(), newPrice)
          .send({ from: this.account })
          .then((res) => {
            res;
            return true;
          })
          .catch((err) => {
            console.log(err);
            return false;
          });
        if (executeBid) {
          this.buy.text = "Updating Database....";
          await this.syncMetamaskWithApi();
          this.$toast.success("Bid Successfully Placed", {
            position: "top",
            duration: 3000,
          });
          this.buy.text = "Bid Placed";
          setTimeout(() => {
            this.getNftInfo();
          }, 3000);
        }
        if (!executeBid) {
          this.buy = {
            text: "Retry",
            disabled: false,
          };
          return this.$toast.error("Unable to proceed", {
            position: "top",
            duration: 3000,
          });
        }
      }
      if (!getApproval.status) {
        ("");
      }
    },
    async gettingBidAllowance() {
      const allowence = await this.usdtContract.methods
        .allowance(this.account, this.contractAddress)
        .call()
        .then((res) => {
          return {
            status: true,
            allowence: res,
          };
        })
        .catch((err) => {
          err;
          return {
            status: false,
            allowence: null,
          };
        });
      return allowence;
    },

    async getNftInfo() {
      this.fetchData = true;
      const nftId = atob(this.$route.params.nftId);
      await this.$axios
        .get(
          `https://us-central1-everestcoin.cloudfunctions.net/get-nft-by-id?id=${nftId}`
        )
        .then((res) => {
          this.asset = res.data.result[0];
          this.nftBids = res.data.bids;
          if (this.asset.end_time != null) {
            let convertUnixToDate = this.$moment
              .unix(this.asset.end_time)
              .format("YYYY-MM-DD HH:mm:ss");
            if (
              this.$moment().format("DD/MM/YYYY") >
              this.$moment(convertUnixToDate).format("DD/MM/YYYY")
            ) {
              this.auctionTime = this.$moment(convertUnixToDate).diff(
                this.$moment()
              );

            } else {
              this.auctionTime = null;
            }
          }
          res;
        })
        .catch((err) => {
          err;
        });
      this.fetchData = false;
    },

    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    getHistory(component, componentIndex) {
      this.activeHistory = componentIndex;
      this.currentComponent = component;
    },
    convertToNormalPrice(asset) {
      if (Number(asset.price) == 0) {
        return 0;
      } else {
        let UpdatedPrice = (asset.price / 10 ** 18).toString();

        return UpdatedPrice;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bidPrice .modal-content {
  background-color: #1d2034;
  border-radius: 30px;
}

::v-deep .bidPrice .modal-body .input-group {
  border-radius: 20px;
  height: 60px;
  background-color: #1d2034;
  border: 3px solid white !important;
  overflow: hidden;
  border: none;
}
::v-deep .bidPrice .modal-body .input-group .input-group-text {
  height: 60px;
  background-color: transparent;
  // border-left-style: none;
  overflow: hidden;
  background-color: #d8b871;
}
::v-deep .bidPrice .modal-body .input-group .input-group-text button {
  font-size: 16px;
  font-family: "Bold";
  border: none;
  overflow: hidden;
  height: 60px;
  color: #ff4444;
  background-color: #d8b871;
}
::v-deep .bidPrice .modal-body .input-group .input-group-text .placeBidPrice {
  color: #ffffff;
}
::v-deep .bidPrice .modal-body input {
  background-color: #1d2034;
  height: 60px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: "SemiBold";
}

:v-deep .bidPrice .modal-body .input-group input::placeholder {
  color: #ffffff;
  font-size: 16px;
  font-family: "SemiBold";
}

.buyOrBidNft .nft {
  padding: 0 58px 0 58px;
  margin-top: 50px;
}
.buyOrBidNft .buyOrBidNftBg {
  background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 200px;
  padding: 10px;
  background-position-y: center;
}

.buyOrBidNft .buyOrBidNftBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.buyOrBidNft .nft .col-md-4 img,
.buyOrBidNft .nft .col-md-4 .b-skeleton {
  max-width: 400px;
  max-height: 400px;
  border-radius: 20px;
  border: 3px solid #d8b871;
}
.buyOrBidNft .nft .col-md-8 .ownerName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.buyOrBidNft .nft .col-md-8 .ownerName .name,
.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  font-size: 20px;
  font-family: "SemiBold";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .assetPrice .price {
  font-size: 20px;
  font-family: "Regular";
}

.buyOrBidNft .nft .col-md-8 .assetPrice .priceVal {
  font-size: 28px;
  font-family: "SemiBold";
}
.buyOrBidNft .nft .col-md-4 .auctionTime {
  width: 100%;
}
.buyOrBidNft .nft .col-md-4 .auctionTime .end {
  color: #1d2034;
  font-size: 18px;
  font-family: "Bold";
  letter-spacing: 2px;
}

.buyOrBidNft .nft .col-md-4 .auctionTime .auctionTimer {
  background-color: #1d2034;
  color: #ffffff;
  font-size: 20px;
  font-family: "SemiBold";
  padding: 10px;
  border-radius: 10px;
  letter-spacing: 2px;
}

.buyOrBidNft .nft .col-md-8 .ownerName .type {
  font-size: 18px;
  font-family: "Regular";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 7px;
  border-radius: 20px;
  background-color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .likesViews button {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  border: none;
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .likesViews button i {
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-4 .description .heading {
  font-size: 20px;
  font-family: "Bold";
}

.buyOrBidNft .nft .col-md-4 .description p {
  font-size: 18px;
  font-family: "Light";
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
  background-color: #f2f2f2;
  color: #1d2034;
  font-size: 20px;
  font-family: "SemiBold";
  // border-radius: 20px;
  // min-width: 50%;
  max-width: 50%;
  border-radius: 5px;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a {
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a.active {
  color: #d8b871;
}

.buyOrBidNft .nft .col-md-8 .placeOrBid button {
  background-color: #d8b871;
  color: #1d2034;
  min-width: 220px;
  height: 50px;
  font-size: 22px;
  font-family: "Bold";
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .buyOrBidNft .nft {
    padding: 0 0px 0px 0px;
    margin-top: 0px;
    padding: 10px;
  }
  .buyOrBidNft .buyOrBidNftBg {
    background-image: none;
    height: auto;
  }

  .buyOrBidNft .buyOrBidNftBg h4 {
    font-size: 20px;
    color: #1d2034;
  }

  .buyOrBidNft .nft .col-md-4 img,
  .buyOrBidNft .nft .col-md-4 .b-skeleton {
    max-width: 100%;
    max-height: 200px;
  }

  .buyOrBidNft .nft .col-md-8 .ownerName {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: none;
  }
  .buyOrBidNft .nft .col-md-8 .ownerName {
    align-items: start;
  }
  .buyOrBidNft .nft .col-md-8 .ownerName .name,
  .buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
    font-size: 18px;
    margin-top: 10px;
  }

  .buyOrBidNft .nft .col-md-8 .description .heading {
    font-size: 20px;
  }

  .buyOrBidNft .nft .col-md-8 .description p {
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .placeOrBid button {
    max-width: 100%;
    height: 50px;
    font-size: 20px;
  }
  .buyOrBidNft .nft .col-md-8 .bidsHistory {
    justify-content: center;
  }
  .buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
    max-width: 100%;
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-4 .auctionTime .end {
    font-size: 16px;
  }

  .buyOrBidNft .nft .col-md-4 .auctionTime .auctionTimer {
    font-size: 16px;
    font-family: "SemiBold";
    padding: 5px;
    border-radius: 10px;
    letter-spacing: 2px;
    overflow: hidden;
  }
}
</style>
