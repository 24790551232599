<template>
  <div>
    <div class="fixedForm">
      <b-form
        @submit.prevent="
          !checkSellingPrice
            ? setFixedSeling()
            : $toast.error('Please enter a valid price')
        "
      >
        <b-alert
          class="text-danger"
          variant="danger"
          :show="checkSellingPrice ? true : false"
          >{{ checkSellingPrice ? "Please Enter a valid price" : "" }}</b-alert
        >
        <b-form-group>
          <b-form-input
            placeholder="Enter Price"
            v-model="fixed.price"
            required
            :disabled="fixedBtn.disabled"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <p class="font-weight-bolder">BNB</p>
        </b-form-group>

        <b-form-group>
          <b-button
            block
            type="submit"
            :disabled="fixedBtn.disabled"
            class="d-flex align-items-center justify-content-center"
            >{{ fixedBtn.text
            }}<b-spinner
              variant="light"
              v-if="fixedBtn.disabled"
              class="ml-2"
              small
              type="grow"
              label="Spinning"
            ></b-spinner
          ></b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";

export default {
  props: ["asset"],
  async created() {
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    this.$set(this.fixed, "currency", this.asset.currency);
  },
  data() {
    return {
      contract: null,
      account: null,
      fixed: {},
      fixedBtn: {
        text: "Submit",
        disabled: false,
      },
    };
  },
  computed: {
    checkSellingPrice() {
      if (
        isNaN(this.fixed.price) &&
        this.fixed.price &&
        this.fixed.price != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async setFixedSeling() {
      const web3 = await new Web3(window.ethereum);
      this.fixedBtn = {
        text: "Please approve metamask & wait...",
        disabled: true,
      };

      let price = web3.utils.toWei(this.fixed.price.toString(), "Ether");
      let fSell = await this.contract.methods

        .sellToken(
          this.asset.id.toString(),
          price,
          this.fixed.currency.toString(),
          0,
          0,
          price.toString(),
          false
        )
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });
      if (fSell) {
        this.fixedBtn.text = "Updating Database....";
        await this.syncMetamaskWithApi();
        this.$toast.success("NFT Listed for Selling", {
          position: "top",
          duration: 3000,
        });
        setTimeout(() => {
          this.$router.push({
            name: "Marketplace",
            params: {
              category: "all",
            },
          });
        }, 3000);
      }
      if (!fSell) {
        this.fixedBtn = {
          text: "Retry",
          disabled: false,
        };
        return this.$toast.error("Unable to list for selling", {
          position: "top",
          duration: 3000,
        });
      }
      this.fixedBtn = {
        text: "NFT Listed For Selling",
        disabled: true,
      };
    },
  },
};
</script>
