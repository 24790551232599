<template>
  <div>
    <div class="addMinter">
      <b-form @submit.prevent="addMinterRole">
        <b-form-group label="Enter User Address">
          <b-form-input
            placeholder="Address"
            v-model="address"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
      <b-form-group>
        <b-button @click="addMinterRole" block :disabled="addminter.disabled">{{
          addminter.text
        }}</b-button>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  props: ["contract", "account"],
  data() {
    return {
      addminter: {
        text: "Add Minter",
        disabled: false,
      },
      address: "",
    };
  },
  methods: {
    async addMinterRole() {
      this.addminter = {
        text: "Please Approve Metamask & wait",
        disabled: true,
      };

      const minter = await this.contract.methods
        .grantRole(
          "0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6",
          this.address.toString()
        )
        .send({ from: this.account })
        .then((res) => {
          res;
          return true;
        })
        .catch((err) => {
          err;
          return false;
        });

      if (minter) {
        this.$toast.success(`Successfully Added`, {
          position: "top",
          duration: 3000,
        });
        this.address = "";
      }
      if (!minter) {
        this.$toast.error(`Unable to add, please try again!`, {
          position: "top",
          duration: 3000,
        });
        return (this.addminter = {
          text: "Retry",
          disabled: false,
        });
      }
      this.addminter = {
        text: "Add Minter",
        disabled: false,
      };
    },
  },
};
</script>

<style lang="scss">
.addMinter {
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
}

.addMinter form input {
  height: 45px;
  border-radius: 10px;
  font-size: 16px;
  font-family: "SemiBold";
}

.addMinter button {
  border: none;
  background-color: #d8b871;
  font-size: 20px;
  font-family: "Bold";
}
</style>
