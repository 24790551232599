import Web3 from "web3";
import store from "../../store/index.js";
const metamaskAccounts = {
  connectMetamask: connectMetamaskAccount,
};
async function connectMetamaskAccount() {
  let status = null;
  status;
  if (typeof window.ethereum !== "undefined") {
    const web3 = await new Web3(window.ethereum);
    web3;
    let status;
    let accounts = await window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        e;
        return undefined;
      });

    if (accounts == undefined) {
      return (status = {
        status: false,
        account: null,
        user_allowed: false,
      });
    } else {
      status = {
        status: true,
        account: accounts[0],
        user_allowed: true,
      };
    }

    if (status.status) {
      store.state.metamaskAccount.account = await accounts[0];

      const abi = await store.getters.getSmartContractStructure.smartContract
        .abi;
      const address = await store.getters.getSmartContractStructure
        .smartContract.address;
        const assetContract = await new web3.eth.Contract(abi, address);
      const usdtAbi = await store.getters.getSmartContractStructure.usdtContract
        .abi;
      const usdtAddress = await store.getters.getSmartContractStructure
        .usdtContract.address;
      const usdtContract = await new web3.eth.Contract(usdtAbi, usdtAddress);

      const sellAbi = await store.getters.getSmartContractStructure.sellContract
        .abi;
      const sellAddress = await store.getters.getSmartContractStructure
        .sellContract.address;
      const sellContract = await new web3.eth.Contract(sellAbi, sellAddress);

      store.state.smartContract.contract = await assetContract;
      store.state.usdtContract.contract = await usdtContract;
      store.state.sellContract.contract = await sellContract;

      const minterRole = await assetContract.methods
        .MINTER_ROLE()
        .call()
        .then((res) => {
          return res;
        });

      const minterRoleStatus = await assetContract.methods
        .hasRole(minterRole, accounts[0])
        .call()
        .then((res) => {
          return res;
        });

      store.state.metamaskAccount.minter_role = await minterRoleStatus;

      const hasRoleOrNot = await assetContract.methods
        .hasRole(
          "0x0000000000000000000000000000000000000000000000000000000000000000",
          accounts[0]
        )
        .call()
        .then((res) => {
          return res;
        });

        // let sellingEnabled;
        // if (hasRoleOrNot) {
        //   sellingEnabled = await assetContract.methods
        //     .sellingEnabled()
        //     .call()
        //     .then((res) => {
        //       return res;
        //     });
        //   store.state.metamaskAccount.sellingEnabled = await sellingEnabled;
        // }
        store.state.metamaskAccount.minter_role = await minterRoleStatus;
        store.state.metamaskAccount.hasRole = await hasRoleOrNot;
      // }
      return status;
    } else {
      status = {
        status: false,
        account: null,
        user_allowed: true,
      };
    }

    return status;
  } else {
    return (status = {
      status: false,
      account: null,
      user_allowed: false,
    });
  }
}

export default metamaskAccounts;
