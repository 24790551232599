<template>
  <div>
    <div class="collectonsNft">
      <div class="heading" v-if="$route.name != 'Marketplace'">
        <h4>Collections</h4>
        <b-button
          @click="
            $router.push({
              name: 'Marketplace',
              params: {
                category: 'collections',
              },
            })
          "
          >View All <i class="fas fa-arrow-right ml-1"></i
        ></b-button>
      </div>
      <div class="notableNfts mt-2">
        <b-list-group>
          <b-list-group-item
            v-if="featuredNfts.length == 0 && !noNftsFound"
            class="loader"
          >
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
            <b-skeleton-img></b-skeleton-img>
          </b-list-group-item>
          <b-list-group-item
            v-else-if="featuredNfts.length == 0 && noNftsFound"
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="noNft">No Data Found!</div>
          </b-list-group-item>
          <b-list-group-item
            v-for="(art, artIndex) in featuredNfts"
            :key="artIndex"
            @click="goToNftPage(art.id, 'Collections')"
            v-else
          >
            <div class="collection" data-aos="zoom-in" data-aos-duration="2000">
              <img :src="art.cover_image" alt="img" />
            </div>
            <div class="info">
              <span class="title">
                {{ art.name }}
              </span>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    await this.getNftsCollections();
  },
  data() {
    return {
      featuredNfts: [],
      noNftsFound: false,
    };
  },
  methods: {
    async getNftsCollections() {
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-collections`;
      await this.$axios.get(url).then((res) => {
        const collections = res.data;

        if (collections.length == 0) {
          this.noNftsFound = true;
        } else {
          this.noNftsFound = false;
          if (this.$route.name != "Marketplace") {
            collections.forEach((collection) => {
              if (this.featuredNfts.length != 3) {
                this.featuredNfts.push(collection);
              } else {
                return;
              }
            });
          } else {
            this.featuredNfts = collections;
          }
        }
      });
    },
    goToNftPage(collectionId, path) {

      this.$router.push({
        name: path,
        params: {
          collectionId: btoa(collectionId),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.collectonsNft {
  display: flex;
  flex-direction: column;
  margin: 70px 0px 100px 0px;
  padding: 0 80px 0 80px;
  overflow: hidden;
}

.collectonsNft .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 45px;
}

.collectonsNft .heading h4 {
  font-size: 30px;
  font-family: "Bold";
}

.collectonsNft .heading button {
  font-size: 18px;
  font-family: "Regular";
  // border: 2px solid #1d2034;
  background-color: transparent;
  color: #1d2034;
  border-radius: 20px;
  border: none;
}

.collectonsNft .list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
}

.collectonsNft .list-group-item .noNft {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.collectonsNft .list-group-item {
  min-width: 383px;
  max-width: 383px;
  min-height: 451px;
  // overflow: hidden;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}

.collectonsNft .list-group .loader {
  width: 100%;
  min-width: none;
  max-width: none;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: none;
  border: none;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.collectonsNft .list-group .loader .b-skeleton-img {
  min-width: 350px;
  height: 410px;
  margin-left: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.collectonsNft .list-group-item .collection img {
  width: 100%;
  height: 360px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.collectonsNft .list-group-item .info .title {
  font-size: 20px;
  font-family: "Bold";
  letter-spacing: 1px;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .collectonsNft {
    padding: 0 0px 0 0px;
  }

  .collectonsNft .heading {
    padding: 5px;
  }

  .collectonsNft .heading h4 {
    font-size: 18px;
  }

  .collectonsNft .heading button {
    height: 40px;
    font-size: 16px;
  }

  .collectonsNft .notableNfts {
    padding: 10px;
  }

  .collectonsNft .list-group {
    justify-content: center;
  }

  // .collectonsNft .list-group-item {
  //   margin: 0 0 20px 0px;
  // }

  .collectonsNft .list-group-item {
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
  }

  .collectonsNft .list-group-item .collection img {
    width: 100%;
    height: 250px;
  }

  .collectonsNft .list-group-item .info {
    font-size: 16px;
  }

  .collectonsNft .list-group-item .loader {
    display: block;
    flex-wrap: none;
  }

  .collectonsNft .list-group-item .b-skeleton-img {
    width: 100%;
    height: 410px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
</style>
