<template>
  <div>
    <span ref="test1"></span>
    <div class="adminNfts">
      <b-skeleton-table
        v-if="fetchData"
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <div v-else>
        <b-overlay
          :show="loadNfts"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="md"
        >
          <div class="table-responsive" ref="table" :style="getTableStyle" >
            <table class="table table-bordered table-striped bg-dark" id="nfts">
              <thead class="thead-light">
                <tr>
                  <th class="align-middle">ID</th>
                  <th class="align-middle">Name</th>
                  <th class="align-middle">Owner</th>
                  <th class="align-middle">Price</th>
                  <th class="align-middle">Auction</th>
                  <th class="align-middle">Selling</th>
                  <th class="align-middle">Featured</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(nft, nftIndex) in nfts.length > 10 ? getAssetRows : nfts"
                  :key="nftIndex"
                  class="bg-white"
                >
                  <td class="align-middle">{{ nft.id }}</td>
                  <td class="align-middle">
                    <span :id="'asset' + nftIndex" class="text-info">{{
                      nft.name
                    }}</span>
                    <b-popover
                      :target="'asset' + nftIndex"
                      triggers="hover"
                      placement="topright"
                    >
                      <div class="d-flex">
                        <img
                          :src="nft.image"
                          style="width: 100px; height: 100px"
                          alt=""
                        />
                        <p class="ml-2" style="word-break: break-all">
                          {{ nft.description }}
                        </p>
                      </div>
                    </b-popover>
                  </td>
                  <td class="align-middle">
                    <span :id="'user' + nftIndex" class="text-info">{{
                      nft.username
                    }}</span>

                    <b-popover
                      :target="'user' + nftIndex"
                      triggers="hover"
                      placement="top"
                    >
                      <div
                        class="d-flex flex-column align-items-center"
                        style="overflow: hidden"
                      >
                        <img
                          :src="nft.user_image"
                          style="width: 100px; height: 100px"
                          alt=""
                        />
                        <p style="word-break: break-all">
                          Address: {{ nft.address }}
                        </p>
                      </div>
                    </b-popover>
                  </td>
                  <td class="align-middle d-flex justify-content-between">
                    {{ nft.price }}
                    <span class="font-weight-bolder">{{
                      checkCurrency(nft.currency)
                    }}</span>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      :class="nft.auction == 1 ? 'text-success' : 'text-danger'"
                      ><i class="fas fa-circle"></i
                    ></span>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      :class="nft.selling == 1 ? 'text-success' : 'text-danger'"
                      ><i class="fas fa-circle"></i
                    ></span>
                  </td>
                  <td class="align-middle text-center">
                    <b-form-checkbox
                      switch
                      v-model="nft.featured"
                      @change="featureNft(nft.id, nft.name, nft.featured)"
                    ></b-form-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-pagination
            class="mt-2"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="nfts"
            align="center"
            size="md"
            v-if="nfts.length > 10"
          ></b-pagination>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
export default {
  async created() {
    this.fetchData = true;
    await this.getNfts();
    await this.$refs.table;
    this.fetchData = false;
  },
  // updated() {
  //   if(!this.fetchData) return
  // },
  data() {
    return {
      fetchData: false,
      loadNfts: false,
      nfts: [],
      currentPage: 1,
      rows: "",
      perPage: 10,
    };
  },
  computed: {
    getAssetRows() {
      return this.nfts.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    getTableStyle() {
      if (!this.fetchData) {
        return "";
      } else {
        return { backgroundColor: "red" };
      }
    },
    async featureNft(assetId, assetName, status) {
      this.loadNfts = true;
      const url = `https://us-central1-everestcoin.cloudfunctions.net/updated-featured`;
      await this.$axios
        .post(url, { id: assetId, status: status })
        .then((res) => {
          let msg = status
            ? " is successfully featured"
            : " is removed from featured";
          this.$toast.success(`${assetName} ${msg}`, {
            position: "top",
            duration: 3000,
          });
          res;
        })
        .catch((err) => {
          err;
        });
      this.loadNfts = false;
    },
    async getNfts() {
      const web3 = await new Web3(window.ethereum);
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-nfts`;
      await this.$axios
        .get(url)
        .then((res) => {
          let nftsData = res.data;
          nftsData.forEach((nft) => {

            this.nfts.push({
              id: nft.id,
              name: nft.name,
              description: nft.description,
              ipfs_link: nft.ipfs_link,
              address: nft.address,
              price: nft.auction == 0
                  ? web3.utils.fromWei(nft.price.toString(), "Ether")
                  : (nft.price / 10 ** 18).toString(),
              created_at: nft.created_at,
              image: nft.image,
              auction: nft.auction == 1 ? true : false,
              selling: nft.selling == 1 ? true : false,
              end_time: nft.end_time,
              bidder: nft.bidder,
              creator: nft.creator,
              currency: nft.currency,
              user_image: nft.user_image,
              username: nft.username,
              featured: nft.featured == 1 ? true : false,
            });
          });
          this.rows = this.nfts.length
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>

<style lang="scss">
// .adminNfts .table-responsive {
//   border-radius: 10px !important;
// }
.adminNfts .table-responsive table tbody tr td {
  font-size: 16px;
  font-family: "SemiBold";
}
</style>
