<template>
  <div>
    <div class="login">
      <div class="loginBg d-flex align-items-center justify-content-center">
        <b-container>
          <b-row>
            <b-col md="12">
              <h4 class="text-center">Login</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="loginHeading text-center">
        Login to Your Account
      </div>
      <div class="loginForm mt-3">
        <b-form>
          <b-form-group>
            <b-form-input
              placeholder="Enter Your Email"
              v-model="login.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-input
              placeholder="Enter Your password"
              v-model="login.password"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox>Remember Me</b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-button block :disabled="loginBtn.disabled">{{
              loginBtn.text
            }}</b-button>
          </b-form-group>
          <b-form-group class="w-100">
            <hr />
          </b-form-group>
          <b-form-group class="text-center">
            <span class="or text-center">OR</span>
          </b-form-group>
          <b-form-group>
            <div class="loginWith">
              <b-button><i class="fab fa-facebook-square"></i></b-button>
              <b-button><i class="fab fa-twitter-square"></i></b-button>
              <b-button><i class="fab fa-google-plus-g"></i></b-button>
            </div>
          </b-form-group>
          <b-form-group>
            <div class="createAccount">
              <span class="d-flex justify-content-center btn p-0 m-0"
              @click="$router.push({name: 'SignUp'})"
                >Do Not Have An Account?
                <div class="signup ml-1">Sign Up</div></span
              >
            </div>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: {},
      loginBtn: {
        text: "Sign In",
        disabled: false,
      },
    };
  },
};
</script>

<style lang="scss">
.login .loginForm {
  padding: 0 58px 0 58px;
}
.loginHeading {
  font-size: 18px;
  font-family: "Bold";
  display: none;
}
.login .loginBg {
  background-image: url("~@/assets/images/homepageBg.svg");
  background-size: cover;
  height: 316px;
  padding: 10px;
}

.login .loginBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.login .loginForm {
  display: flex;
  justify-content: center;
}

.login .loginForm form {
  width: 100%;
  padding: 40px;
  width: 757px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
  margin: 50px 0px 50px 0px;
}

.login .loginForm form .form-group {
  width: 100%;
}

.login .loginForm form input {
  // width: 100%;
  height: 60px;
  border-radius: 20px;
}

.login .loginForm form button {
  width: 643px;
  height: 50px;
  border-radius: 20px;
  border: none;
  background-color: #d8b871;
  color: #1d2034;
  font-family: "Bold";
  font-size: 20px;
}

.login .loginForm form input::placeholder {
  font-size: 16px;
  font-family: "Light";
}

.login .loginForm form .loginWith {
  display: flex;
  justify-content: center;
}

.login .loginForm form .loginWith button {
  width: 60px;
  height: 60px;
  background-color: transparent;
}

.login .loginForm form .loginWith button i {
  font-size: 40px !important;
  color: #707070;
}

.login .loginForm form .createAccount,
.login .loginForm form .or {
  font-size: 20px;
  font-family: "Regular";
  color: #1d2034;
  text-align: center;
}

.login .loginForm form .createAccount .signup {
  color: #d8b871;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .login .loginForm {
    padding: 0 10px 0 10px;
  }

  .loginHeading {
    display: block;
  }

  .login .loginBg {
    display: none !important;
    height: 200px;
  }

  .login .loginBg h4 {
    font-size: 20px;
  }

  .login .loginForm form {
    width: 100%;
    padding: 10px;

    height: auto;
    display: flex;

    margin: 30px 0px 30px 0px;
  }

  .login .loginForm form .form-group {
    width: 100%;
  }

  .login .loginForm form input {
    height: 40px;
  }

  .login .loginForm form button {
    width: 100%;
    height: 40px;

    font-size: 18px;
  }

  .login .loginForm form input::placeholder {
    font-size: 16px;
  }

  .login .loginForm form .loginWith {
    display: flex;
    justify-content: center;
  }

  .login .loginForm form .loginWith button {
    width: 50px;
    height: 50px;
  }

  .login .loginForm form .loginWith button i {
    font-size: 30px !important;
  }

  .login .loginForm form .createAccount,
  .login .loginForm form .or {
    font-size: 18px;
  }
}
</style>
