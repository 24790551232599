<template>
  <div>
    <div class="history">
      <b-list-group :class="!maxHeight ? 'bid' : 'bids'" ref="history">
        <b-list-group-item v-if="nftBids.length == 0">
          No Bids Placed Yet!
        </b-list-group-item>
        <b-list-group-item
          v-for="(bid, bidIndex) in nftBids"
          :key="bidIndex"
          v-else
        >
          <div class="userBid d-flex">
            <div class="userimg">
              <b-skeleton type="avatar" v-if="nftBids.length == 0"></b-skeleton>
              <img :src="bid.photo" class="img-fluid rounded-circle" alt="" />
            </div>

            <div class="ml-2">
              <div v-if="nftBids.length == 0">
                <b-skeleton animation="throb" width="40%"></b-skeleton>
                <b-skeleton animation="throb" width="40%"></b-skeleton>
              </div>
              <div v-else>
                <div class="bidPrice">
                  <span v-if="bid.event == 'minted'"> NFT Minted </span>
                  <span v-else-if="bid.event == 'sell'">
                    Sell Order Placed at
                    <span class="font-weight-bolder">
                      {{
                        bid.price != 0
                          ? convertToNormalPrice(bid).toString()
                          : bid.price
                      }}
                      <span>{{ bid.currency }}</span></span
                    >
                  </span>
                  <span v-else-if="bid.event == 'bid'">
                    New Bid at
                    <span class="font-weight-bolder">
                      {{
                        bid.price != 0
                          ? convertToNormalPrice(bid).toString()
                          : bid.price
                      }}
                      <span>{{ bid.currency }}</span></span
                    >
                  </span>
                  <span v-else-if="bid.event == 'buy'">
                    NFT bought at
                    <span class="font-weight-bolder">
                      {{
                        bid.price != 0
                          ? convertToNormalPrice(bid).toString()
                          : bid.price
                      }}
                      <span>{{ bid.currency }}</span></span
                    >
                  </span>
                  <span v-else>
                    Auction started at
                    <span class="font-weight-bolder">
                      {{
                        bid.price != 0
                          ? convertToNormalPrice(bid).toString()
                          : bid.price
                      }}
                      <span>{{ bid.currency }}</span></span
                    >
                  </span>
                </div>
                <div class="name">
                  By <span class="font-weight-bolder"> {{ bid.username }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
export default {
  props: ["nftBids", "asset"],
  mounted() {
    this.getHeight();
  },
  data() {
    return {
      maxHeight: false,
    };
  },

  methods: {
    async getHeight() {
      this.$refs.history;
      let height = await this.$refs.history.clientHeight;

      if (Number(height) > 250) {
        this.maxHeight = true;
      } else {
        this.maxHeight = false;
      }
    },
    convertToNormalPrice(asset) {
      if (Number(asset.price) == 0) {
        return 0;
      } else {
        let UpdatedPrice =
          asset.auction == 0
            ? web3.utils.fromWei(asset.price.toString(), "Ether")
            : (asset.price / 10 ** 18).toString();

        return UpdatedPrice;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.history .bid {
  overflow-y: scroll;
  // overflow-y: hidden;
  max-height: 250px;
}
.history .bids {
  max-height: 250px;
  overflow-y: scroll;
}
.history .list-group .list-group-item {
  border: none;
  background-color: #ffffff;
  font-size: 16px;
  font-family: "Regular";
}
.history .list-group .list-group-item .userBid img {
  width: 60px;
  height: 60px;
  border: 3px solid #d8b871;
}
</style>
