<template>
  <div>
    <div class="buyOrBidNft">
      <div
        class="buyOrBidNftBg d-flex align-items-center justify-content-center"
      >
        <b-container>
          <b-row>
            <b-col md="12">
              <h4 class="text-center">Explore Game Characters NFTs</h4>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="nft">
        <b-modal
          id="sellForm"
          :title="'Set Selling/Auction Price for: ' + asset.name"
          no-close-on-backdrop
          no-close-on-keyboard
          no-close-on-esc
          hide-footer
          centered
          size="md"
          modal-class="sellForm"
        >
          <SellPopup :asset="asset" />
        </b-modal>
        <b-container fluid>
          <b-row>
            <b-col
              md="4"
              class="min-vh-50 d-flex flex-column"
            >
              <b-skeleton-img v-if="fetchData"></b-skeleton-img>
              <img :src="asset.image" v-else alt="" />

              <div class="description mt-3">
                <div class="heading">Description</div>
                <div v-if="fetchData">
                  <b-skeleton animation="fade" width="70%"></b-skeleton>
                  <b-skeleton animation="fade" width="50%"></b-skeleton>
                  <b-skeleton animation="fade" width="40%"></b-skeleton>
                  <b-skeleton animation="fade" width="30%"></b-skeleton>
                </div>
                <div v-else class="mt-2">
                  <p v-if="this.asset.description.length > 45">
                    {{ handleDescription }}...
                    <span
                      class="text-info btn p-0 m-0"
                      @click="longText = !longText"
                      >{{ longText ? "Read More" : "Read Less" }}</span
                    >
                  </p>
                  <p v-else>{{ asset.description }}</p>
                </div>
              </div>
            </b-col>
            <b-col
              md="8"
              class="min-vh-50 d-flex flex-column"
            >
              <div class="ownerName">
                <div class="d-flex align-items-center">
                  <b-skeleton-icon
                    icon="person"
                    :icon-props="{ fontScale: 3 }"
                    v-if="fetchData"
                  ></b-skeleton-icon>
                  <img
                    v-else
                    :src="asset.photo"
                    style="width: 87px; height: 87px"
                    class="rounded-circle"
                    alt=""
                  />
                  <div v-if="fetchData" class="ml-2 w-100">
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                    <b-skeleton animation="fade" width="20%"></b-skeleton>
                  </div>
                  <div class="ml-2" v-else>
                    <span class="name">{{ asset.name }}</span> <br />
                    <span class="type">{{ asset.username }}</span>
                  </div>
                </div>
                <div>

                </div>
              </div>

           <div class="assetPrice mt-4">
                <div v-if="fetchData">
                  <b-skeleton animation="fade" type="button"></b-skeleton>
                </div>
                <div v-else>
                  <div class="price">Price</div>
                  <div class="priceVal">
                    <span
                      >{{ convertToNormalPrice(asset) }}
                      <span>{{ checkCurrency(asset.currency) }}</span></span
                    >
                  </div>
                </div>
               <div class="placeOrBid mt-2">
                <b-skeleton
                  v-if="fetchData"
                  type="button"
                  animation="fade"
                  width="70%"
                ></b-skeleton>
                <b-button
                  v-else

                  @click="$bvModal.show('sellForm')"
                  :disabled="buy.disabled"
                  >{{ buy.text }}</b-button
                >
              </div>
              </div>

              <div class="bidsHistory mt-5">
                <b-nav align="center">
                  <b-nav-item
                    @click="getHistory(history.component, historyIndex)"
                    :active="activeHistory == historyIndex ? true : false"
                    v-for="(history, historyIndex) in history"
                    :key="historyIndex"
                  >
                    {{ history.name }}
                  </b-nav-item>
                </b-nav>

                <div v-if="nftBids == null">
                  <div class="userBid d-flex w-100 mb-2">
                    <div class="userimg">
                      <b-skeleton type="avatar"></b-skeleton>
                    </div>

                    <div class="ml-2 w-100">
                      <div>
                        <b-skeleton animation="throb" width="50%"></b-skeleton>
                        <b-skeleton animation="throb" width="50%"></b-skeleton>
                      </div>
                    </div>
                  </div>
                  <div class="userBid d-flex w-100 mb-2">
                    <div class="userimg">
                      <b-skeleton type="avatar"></b-skeleton>
                    </div>

                    <div class="ml-2 w-100">
                      <div>
                        <b-skeleton animation="throb" width="50%"></b-skeleton>
                        <b-skeleton animation="throb" width="50%"></b-skeleton>
                      </div>
                    </div>
                  </div>
                  <div class="userBid d-flex w-100">
                    <div class="userimg">
                      <b-skeleton type="avatar"></b-skeleton>
                    </div>

                    <div class="ml-2 w-100">
                      <div>
                        <b-skeleton animation="throb" width="50%"></b-skeleton>
                        <b-skeleton animation="throb" width="50%"></b-skeleton>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-else>
                  <component
                    :nftBids="nftBids"
                    :creator="asset.creator"
                    :asset="asset"
                    v-bind:is="currentComponent"
                  ></component>
                </div>
              </div>


            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
// import Bids from "./History/Bids.vue";
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
import History from "../BuyNft/History/History.vue";
import Details from "../BuyNft/History/Details.vue";
import SellPopup from "./SellPopup.vue";
export default {
  async created() {
    this.fetchData = true;
    this.contract = await this.$store.getters.getSmartContractStructure
      .smartContract.contract;

    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;

    await this.getNftInfo();
    this.longText = this.asset.description.length > 45 ? true : false;
    this.fetchData = false;
  },
  components: {
    SellPopup,
    History,
    Details,
  },
  data() {
    return {
      fetchData: false,
      contract: null,
      usdtContract: null,
      account: null,
      asset: {},
      nftBids: null,
      activeHistory: 0,
      currentComponent: "History",
      history: [
        { name: "History", component: "History" },
        { name: "Details", component: "Details" },
      ],
      longText: false,
      buy: {
        text: "Sell",
        disabled: false,
      },
    };
  },
  computed: {
    handleDescription() {
      if (
        this.longText &&
        this.asset.description &&
        this.asset.description.length > 45
      ) {
        return this.asset.description.slice(0, 44);
      }
      return this.asset.description;
    },
  },
  methods: {
    async getNftInfo() {
      const nftId = atob(this.$route.params.nftId);
      await this.$axios
        .get(
          `https://us-central1-everestcoin.cloudfunctions.net/get-nft-by-id?id=${nftId}`
        )
        .then((res) => {
          this.asset = res.data.result[0];
          this.nftBids = res.data.bids;
          res;
        })
        .catch((err) => {
          err;
        });
    },
    convertToNormalPrice(asset) {
     if (Number(asset.price) == 0) {
        return 0;
      } else {
        let UpdatedPrice =
          asset.auction == 0
            ? web3.utils.fromWei(asset.price.toString(), "Ether")
            : (asset.price / 10 ** 18).toString();

        return UpdatedPrice;
      }
    },
    getHistory(component, componentIndex) {
      this.activeHistory = componentIndex;
      this.currentComponent = component;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sellForm .modal-content {
  background-color: #1d2034;
  border-radius: 10px;
  border: 3px solid #d8b871;
}

::v-deep .sellForm header {
  color: #ffffff;
  font-family: "Bold";
  font-size: 14px;
}

.buyOrBidNft .nft {
  padding: 0 80px 0 80px;
  margin-top: 50px;
}
.buyOrBidNft .buyOrBidNftBg {
 background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 200px;
  padding: 10px;
  background-position-y: center;
}

.buyOrBidNft .buyOrBidNftBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.buyOrBidNft .buyOrBidNftBg .nft .container .row .col-md-8 {
  height: 100vh !important;
  display: flex !important;
}

.buyOrBidNft .nft .col-md-4 img,
.buyOrBidNft .nft .col-md-4 .b-skeleton {
  max-width: 400px;
  max-height: 400px;
  border-radius: 20px;
  border: 3px solid #d8b871;
}
.buyOrBidNft .nft .col-md-8 .ownerName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.buyOrBidNft .nft .col-md-8 .ownerName .name,
.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  font-size: 20px;
  font-family: "SemiBold";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .ownerName .type {
  font-size: 18px;
  font-family: "Regular";
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 7px;
  border-radius: 20px;
  background-color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .assetPrice .price {
  font-size: 20px;
  font-family: "Regular";
}

.buyOrBidNft .nft .col-md-8 .assetPrice .priceVal {
  font-size: 28px;
  font-family: "SemiBold";
}

.buyOrBidNft .nft .col-md-8 .likesViews button {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  border: none;
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .likesViews button i {
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-4 .description .heading {
  font-size: 20px;
  font-family: "Bold";
  padding: 0px;
  margin: 0px;
}

.buyOrBidNft .nft .col-md-4 .description p {
  font-size: 18px;
  font-family: "Regular";
  padding: 0px;
  margin: 0px;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
  background-color: #f2f2f2;
  color: #1d2034;
  font-size: 20px;
  font-family: "SemiBold";
  // border-radius: 20px;
  // min-width: 50%;
  max-width: 50%;
  border-radius: 5px;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a {
  color: #1d2034;
}

.buyOrBidNft .nft .col-md-8 .bidsHistory .nav .nav-item a.active {
  color: #d8b871;
}

.buyOrBidNft .nft .col-md-8 .placeOrBid button {
  background-color: #d8b871;
  color: #1d2034;
  min-width: 220px;
  height: 50px;
  font-size: 22px;
  font-family: "Bold";
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .buyOrBidNft .nft {
    padding: 0 0px 0px 0px;
    margin-top: 0px;
    padding: 10px;
  }
  .buyOrBidNft .buyOrBidNftBg {
    background-image: none;
    height: auto;
  }

  .buyOrBidNft .buyOrBidNftBg h4 {
    font-size: 20px;
    color: #1d2034;
  }

  .buyOrBidNft .nft .col-md-4 img,
  .buyOrBidNft .nft .col-md-4 .b-skeleton {
    max-width: 100%;
    max-height: 200px;
  }

  .buyOrBidNft .nft .col-md-8 .ownerName {
    margin-top: 20px;

    align-items: start;
  }

  .buyOrBidNft .nft .col-md-8 .ownerName .name,
  .buyOrBidNft .nft .col-md-8 .ownerName .highestBid {
    font-size: 18px;
    margin-top: 10px;
  }

  .buyOrBidNft .nft .col-md-8 .description .heading {
    font-size: 20px;
  }

  .buyOrBidNft .nft .col-md-8 .description p {
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .bidsHistory .nav {
    max-width: 100%;
    font-size: 18px;
  }

  .buyOrBidNft .nft .col-md-8 .placeOrBid button {
    max-width: 100%;
    height: 50px;
    font-size: 20px;
  }
}
</style>
