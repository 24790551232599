<template>
  <div>
    <div class="homepage">
      <div class="homepageBg">
        <b-container>
          <b-row>
            <b-col md="12" data-aos="fade-down" data-aos-duration="3000">
              <div>
                <h4>Create. Loan. Connect</h4>
                <h4>Buy & Sell your NFT's</h4>
                <h6>Welcome to the world of possibilities</h6>
                <br />
                <div class="homepageBgBtns">
                  <b-button class="learn">Learn How</b-button>
                </div>
              </div>
              <img
                :src="require('@/assets/images/Dragon.png')"
                alt=""
                class="img-fluid"
              />
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="loadData" v-if="fetchData">
        <b-list-group>
          <b-list-group-item
            style="width: 100%; height: auto; box-shadow: none; border: none"
          >
            <div class="loader w-100">
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
              <b-skeleton-img></b-skeleton-img>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="data" v-else>
        <Featured :account="account" />
        <TopSellers />
        <Auctions :account="account" />
        <AllNfts :nfts="nfts" :account="account" :nftsObject="nftsObject" />
        <Collections />
      </div>

      <!-- <div class="search">
        <Search />
      </div>
      <div class="notable-art">
        <NotableArt />
      </div>
      <div class="topSellers">
        <TopSellers />
      </div>
      <div class="topCollections">
        <TopCollections />
      </div>
      <div class="startCollecting">
        <StartCollecting />
      </div>
      <div class="nftInfo">
        <NftInfo />
      </div>
      <div class="exploreNft">
        <Explore />
      </div> -->
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
web3
import Featured from "./Collections/Featured.vue";
import Auctions from "./Collections/Auctions.vue";
import AllNfts from "./Collections/AllNfts.vue";
import Collections from "./Collections/Collections.vue";
// import Search from "../Search/Search.vue";
// import NotableArt from "./NotableArt.vue";
import TopSellers from "./TopSellers.vue";
// import TopCollections from "./TopCollections.vue";
// import StartCollecting from "./StartCollecting.vue";
// import NftInfo from "./NftInfo.vue";
// import Explore from "./Explore.vue";

export default {
  async created() {
    this.account = await this.$store.getters.getMetamaskAccount.metamaskAccount
      .account;
    this.fetchData = true;
    await this.getAllNfts();
    await this.getNftsCopies();
    this.fetchData = false;
  },
  components: {
    // Search,
    // NotableArt,
    TopSellers,
    // TopCollections,
    // StartCollecting,
    // NftInfo,
    // Explore,
    Featured,
    Auctions,
    AllNfts,
    Collections,
  },
  data() {
    return {
      account: null,
      fetchData: false,
      nfts: [],
      nftsObject: {},
    };
  },
  methods: {
    async getAllNfts() {
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-all-nfts`;
      await this.$axios.get(url).then((res) => {
        const nftsData = res.data;

        nftsData.forEach((nft) => {

          this.$set(this.nftsObject, nft.ipfs_link, {

            id: nft.id,
            name: nft.name,
            description: nft.description,
            ipfs_link: nft.ipfs_link,
            address: nft.address,
            price:

              nft.auction == 0
                ? web3.utils.fromWei(nft.price.toString(), "Ether")
                : (nft.price / 10 ** 18).toString(),
            created_at: nft.created_at,
            image: nft.image,
            auction: nft.auction == 1 ? true : false,
            selling: nft.selling == 1 ? true : false,
            end_time: nft.end_time,
            bidder: nft.bidder,
            creator: nft.creator,
            currency: nft.currency,
            photo: nft.photo,
            username: nft.username,
            featured: nft.featured == 1 ? true : false,
          });

          // this.nfts.push({
          //   id: nft.id,
          //   name: nft.name,
          //   description: nft.description,
          //   ipfs_link: nft.ipfs_link,
          //   address: nft.address,
          //   price: nft.auction == 0
          //     ? 0
          //      : (nft.price / 10 ** 18).toString(),
          //     // nft.auction == 0
          //     //   ? web3.utils.fromWei(nft.price.toString(), "Ether")
          //     //   : (nft.price / 10 ** 18).toString(),
          //   created_at: nft.created_at,
          //   image: nft.image,
          //   auction: nft.auction == 1 ? true : false,
          //   selling: nft.selling == 1 ? true : false,
          //   end_time: nft.end_time,
          //   bidder: nft.bidder,
          //   creator: nft.creator,
          //   currency: nft.currency,
          //   photo: nft.photo,
          //   username: nft.username,
          //   featured: nft.featured == 1 ? true : false,
          // });
        });
      });
    },
    async getNftsCopies() {
      const url = `https://us-central1-everestcoin.cloudfunctions.net/get-copies`;
      await this.$axios.get(url).then((res) => {
        const copies = res.data;
        const assetCopies = {};
        copies.forEach((nft) => {
          if (nft.count > 1) {
            this.$set(assetCopies, nft.ipfs_link, {
              ipfs_link: nft.ipfs_link,
              count: nft.count,
            });
          }
        });
        this.$store.state.assetLinks = assetCopies;
        res;
      });
    },
  },
};
</script>

<style lang="scss">
/////////////////////////
.loadData .list-group-item .loader {
  display: flex;
  flex-wrap: wrap;
}
.loadData .list-group-item .b-skeleton-img {
  width: 295px;
  height: 410px;
  margin-left: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
}
///////////////////////////////

.homepageBg {
  background-image: url("~@/assets/images/newBackground.jpg");
  background-size: cover;
  height: 450px;
  padding: 10px;
  overflow: hidden;
}

.homepageBg .col-md-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepageBg .col-md-12 img {
  min-width: 400px;
  max-width: 500px;
  min-height: 400px;
  max-height: 400px;
}

.homepageBg h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.homepageBg h6 {
  font-size: 30px;
  font-family: "Bold";
  color: #d8b871;
}

.homepageBg .homepageBgBtns .start,
.homepageBg .homepageBgBtns .learn {
  width: 216px;
  height: 50px;
  border-radius: 30px;
  font-family: "Bold";
  font-size: 20px;
}

.homepageBg .homepageBgBtns .start {
  color: #1d2034;
  background-color: #d8b871;
}

.homepageBg .homepageBgBtns .learn {
  color: #ffffff;
  background-color: transparent;
  border: 3px solid #ffffff;
  margin-left: 15px;
}

//Featured
.featuredNfts {
  display: flex;
  flex-direction: column;
  margin: 70px 0 100px 0;
  padding: 0 80px 0 80px;
  // overflow: hidden;
}

.featuredNfts .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 45px;
}

.featuredNfts .heading h4 {
  font-size: 30px;
  font-family: "Bold";
}

.featuredNfts .heading button {
  font-size: 18px;
  font-family: "Regular";
  // border: 2px solid #1d2034;
  background-color: transparent;
  color: #1d2034;
  border-radius: 20px;
  border: none;
}

.featuredNfts .list-group-item .noNft {
  font-size: 30px;
  font-family: "Bold";
  text-align: center;
  color: #d8b871;
}

.featuredNfts .list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.featuredNfts .list-group-item {
  min-width: 283px;
  max-width: 283px;
  min-height: 422px;
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
}

.featuredNfts .list-group-item.loader {
  min-width: none;
  max-width: none;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  border: none;
  box-shadow: none;
}

.featuredNfts .list-group-item.loader .b-skeleton-img {
  width: 295px;
  height: 410px;
  margin: 10px;
  border-radius: 10px;
}

.featuredNfts .list-group-item img {
  width: 100%;
  height: 283px;
}

.featuredNfts .list-group-item .info {
  // margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Regular";
  // height: auto;
  min-height: 139px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.featuredNfts .list-group-item .info button {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .homepageBg {
    height: 200px;
    padding: 10px;
  }

  .homepageBg .col-md-12 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .homepageBg .col-md-12 img {
    display: none;
  }

  .homepageBg h4 {
    font-size: 18px;
  }

  .homepageBg h6 {
    font-size: 16px;
  }

  .homepageBg .homepageBgBtns .start,
  .homepageBg .homepageBgBtns .learn {
    width: 100%;
    height: 40px;
    font-size: 16px;
  }

  .homepageBg .homepageBgBtns .learn {
    margin-left: 0px;
    margin-top: 10px;
  }

  //Featured
  .featuredNfts {
    padding: 0 20px 0 20px;
  }

  .featuredNfts .heading {
    padding: 5px;
  }

  .featuredNfts .heading h4 {
    font-size: 18px;
  }

  .featuredNfts .heading button {
    height: 40px;
    font-size: 16px;
  }

  // .featuredNfts .notableNfts {
  //   padding: 10px;
  // }

  .featuredNfts .list-group {
    justify-content: center;
  }

  .featuredNfts .list-group-item {
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
  }

  .featuredNfts .list-group-item img {
    width: 100%;
    height: 250px;
  }

  .featuredNfts .list-group-item .info {
    min-height: auto;
    font-size: 16px;
    justify-content: none;
  }



  .featuredNfts .list-group-item .b-skeleton-img {
    width: 100%;
    height: 300px;
    margin: 0px;
  }
}
</style>
