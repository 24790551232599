const data = {
  nfts: [
    {
      name: "Deadpool 1",
      url: "https://img1.hotstarext.com/image/upload/f_auto,t_vl/sources/r1/cms/prod/old_images/vertical/MOVIE/3257/1770003257/1770003257-v",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: true,
      liked: true,
    },
    {
      name: "Deadpool 2",
      url: "https://images.hindustantimes.com/rf/image_size_640x362/HT/p2/2016/02/12/Pictures/_e7c37f90-d131-11e5-94bd-a06a76346e8f.jpg",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: true,
      liked: true,
    },
    {
      name: "Deadpool 3",
      url: "https://hips.hearstapps.com/digitalspyuk.cdnds.net/17/29/1500370357-1.jpg?crop=1.00xw:0.893xh;0,0&resize=1200:*",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: false,
      liked: false,
    },
    {
      name: "Deadpool 4",
      url: "https://cdn.vox-cdn.com/thumbor/ikFRx2vl6oXMgtb9ZHFbklEocTU=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/10860167/deadpool_2_espolon.jpg",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: false,
      liked: true,
    },
    {
      name: "Deadpool 5",
      url: "https://www.denofgeek.com/wp-content/uploads/2015/09/deadpool-2-domino-cable.jpg?resize=620%2C368",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: true,
      liked: true,
    },
    {
      name: "Deadpool 6",
      url: "https://images.hindustantimes.com/rf/image_size_630x354/HT/p2/2018/05/20/Pictures/_ecae29ce-5c12-11e8-b431-73159b4b09e2.jpg",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: false,
      liked: false,
    },
    {
      name: "Deadpool 5",
      url: "https://www.denofgeek.com/wp-content/uploads/2015/09/deadpool-2-domino-cable.jpg?resize=620%2C368",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: true,
      liked: true,
    },
    {
      name: "Deadpool 2",
      url: "https://images.hindustantimes.com/rf/image_size_640x362/HT/p2/2016/02/12/Pictures/_e7c37f90-d131-11e5-94bd-a06a76346e8f.jpg",
      price: "0.0015 ETH",
      likes: 50,
      owner: "Sid",
      auction: true,
      liked: true,
    },
  ],
  topSellers: [
    {
      image: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      name: "John Doe 1",
      price: "265.77 ETH",
      percent: "+42.2%",
      loss: false,
      items: 140
    },
    {
      image: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      name: "John Doe 2",
      price: "250 ETH",
      percent: "-30.2%",
      loss: true,
      items: 150
    },
    {
      image: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      name: "John Doe 3",
      price: "300 ETH",
      percent: "+42.2%",
      loss: false,
      items: 555
    },
    {
      image: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      name: "John Doe 4",
      price: "200.66 ETH",
      percent: "+65.2%",
      loss: false,
      items: 100
    },
    {
      image: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      name: "John Doe 5",
      price: "100.77 ETH",
      percent: "-33.2%",
      loss: true,
      items: 132
    },
    {
      image: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      name: "John Doe 6",
      price: "350 ETH",
      percent: "+52.2%",
      loss: false,
      items: 155
    },

  ],
  topCollections: [
    {
      img1: "https://ichef.bbci.co.uk/news/976/cpsprodpb/017B/production/_118697300_kimcatdardshian_eth.png",
      img2: "https://www.hollywoodreporter.com/wp-content/uploads/2021/10/Mutant-Demon-Ape-Credit-0xb1-copy-H-2021.jpg",
      img3: "https://miro.medium.com/max/980/1*3iesg_sr8kC6NYN2iiFHRQ.png",
      img4: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202111/ab-nft-sixteen_nine.jpg?size=1200:675",
      owner: "Jon Doe1",
      ownerImg: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      items: 300,
      likes: 23.1
    },
    {
      img1: "https://ichef.bbci.co.uk/news/976/cpsprodpb/017B/production/_118697300_kimcatdardshian_eth.png",
      img2: "https://www.hollywoodreporter.com/wp-content/uploads/2021/10/Mutant-Demon-Ape-Credit-0xb1-copy-H-2021.jpg",
      img3: "https://miro.medium.com/max/980/1*3iesg_sr8kC6NYN2iiFHRQ.png",
      img4: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202111/ab-nft-sixteen_nine.jpg?size=1200:675",
      owner: "Jon Doe1",
      ownerImg: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      items: 300,
      likes: 23.1
    },
    {
      img1: "https://ichef.bbci.co.uk/news/976/cpsprodpb/017B/production/_118697300_kimcatdardshian_eth.png",
      img2: "https://www.hollywoodreporter.com/wp-content/uploads/2021/10/Mutant-Demon-Ape-Credit-0xb1-copy-H-2021.jpg",
      img3: "https://miro.medium.com/max/980/1*3iesg_sr8kC6NYN2iiFHRQ.png",
      img4: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202111/ab-nft-sixteen_nine.jpg?size=1200:675",
      owner: "Jon Doe1",
      ownerImg: "https://cdnb.artstation.com/p/assets/images/images/032/885/867/large/layne-pinky-jawn.jpg?1607756114",
      items: 300,
      likes: 23.1
    }
  ]
};

export default data;
