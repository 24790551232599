<template>
  <div>
    <div class="startCollecting mt-3 mb-2">
      <b-container>
        <b-row align-v="center">
          <b-col md="6">
            <h4>Start Your Own</h4>
            <h4>Collection Today</h4>

            <br />
            <div class="startCollectingBtn">
              <b-button class="start">Start Collecting</b-button>
            </div>
          </b-col>
          <b-col md="6">
            <div class="collection">
              <img
                src="https://lumiere-a.akamaihd.net/v1/images/deadpool2_feature-poster_584x800_69bc155b.jpeg"
                alt=""
              />
              <div class="info">
                <div class="d-flex align-items-center justify-content-between">
                  <span>Deadpool</span>
                  <span>
                    <!-- <i class="far fa-heart mr-1" v-if="!art.liked"></i>
                  <i class="fas fa-heart text-warning mr-1" v-else></i> -->
                    <i class="fas fa-heart text-warning mr-1"></i>
                    30.7K
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span
                    >Owned By:
                    <span class="font-weight-bolder">Ryan Renolds</span></span
                  >
                  <span>0.55 ETH</span>
                </div>
                <b-button block>Place Bid</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.startCollecting {
  background-image: url("~@/assets/images/homepageBg.svg");
  background-size: cover;
  height: 510px;
  overflow: hidden;
}

.startCollecting .col-md-6:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 519px;
}

.startCollecting h4 {
  font-size: 50px;
  font-family: "Bold";
  color: #ffffff;
}

.startCollecting .startCollectingBtn button {
  width: 216px;
  height: 50px;
  border-radius: 30px;
  font-family: "Bold";
  font-size: 20px;
}

.startCollecting .startCollectingBtn button {
  color: #1d2034;
  background-color: #d8b871;
}

.startCollecting .collection {
  width: 366px;
  height: 416px;
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #ffffff;
  cursor: pointer;
}

.startCollecting .collection img {
  width: 100%;
  height: 270px;
}

.startCollecting .collection .info {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-family: "Regular";
}

.startCollecting .collection .info button {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Bold";
  background-color: #d8b871;
  border-radius: 10px;
  color: #1d2034;
  border: none;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .startCollecting {

    height: auto;

  }

  .startCollecting .col-md-6:nth-child(2) {
    height: auto;
  }

  .startCollecting h4 {
    font-size: 18px;
  }

  .startCollecting .startCollectingBtn button {

    height: 40px;

    font-size: 16px;
  }

  .startCollecting .collection {
    margin-top: 10px;
    width: 100%;
    height: 350px;
    overflow: hidden;
  }

  .startCollecting .collection img {
    width: 100%;
    height: 200px;
  }




}
</style>
