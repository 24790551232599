<template>
  <div>
    <div class="sellForm">
      <div class="fixedOrAution d-flex justify-content-center">
        <div class="fixed">
          <b-form-checkbox
            switch
            size="md"
            @change="checkFixedForm"
            v-model="fixed"
            >Fixed</b-form-checkbox
          >
        </div>
        <div class="auction">
          <b-form-checkbox
            switch
            size="md"
            @change="checkAuctionForm"
            class="ml-4"
            v-model="aution"
            >Auction</b-form-checkbox
          >
        </div>
      </div>
    </div>
    <div class="forms mt-2">
      <FixedForm :asset="asset" v-if="fixed" />
      <AuctionForm :asset="asset" v-if="aution" />
    </div>
  </div>
</template>

<script>
import FixedForm from "./FixedForm.vue";
import AuctionForm from "./AuctionForm.vue";
export default {
  props: ["asset"],
  components: {
    FixedForm,
    AuctionForm,
  },
  data() {
    return {
      fixed: true,
      aution: false,
    };
  },
  methods: {
    checkFixedForm(status) {
      if (!status) {
        this.aution = true;
      } else {
        this.aution = false;
      }

    },
    checkAuctionForm(status) {
      if (!status) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },
  },
};
</script>

<style lang="scss">
.sellForm .fixedOrAution label {
  color: #ffffff !important;
  font-size: 16px;
  font-family: "SemiBold";
}

.fixedForm form input,
.fixedForm form .b-form-btn-label-control {
  height: 45px;
  border-radius: 10px;
  font-size: 20px;
  color: #1d2034;
  font-family: "Bold";
}

.fixedForm form input::placeholder {
  font-size: 16px;
  color: #1d2034;
  font-family: "SemiBold";
}

.fixedForm form p {
  font-size: 20px;
  color: #ffffff;
  font-family: "Bold";
  background-color: #00c851;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  margin: 0px;
}

.fixedForm form button:last-child {
  height: 45px;
  border-radius: 10px;
  font-size: 20px;
  color: #ffffff;
  font-family: "Bold";
  background-color: #d8b871;
  border: none;
}

.fixedForm .alert {
  font-size: 16px;
  font-family: 'SemiBold';
  padding: 5px;
  text-align: center;
}

</style>
